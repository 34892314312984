import { processRequestResult, userApi } from "@/services/api";

const demoPatientUsername = "demopatient";

/**
 * Check whether a patient username (site id) exists in the site
 * @param {string} patientSiteId The patient site id
 */
const checkIfPatientSiteIdExists = async (patientSiteId) => {
  return await userApi
    .get(`/site/own/contains/patientsiteid/${patientSiteId}`)
    .then((response) => {
      return response.data.result;
    })
    .catch(() => {
      return false;
    });
};

/**
 * Check whether a username exists
 * @param {string} username The username
 */
const checkIfUsernameExists = async (username) => {
  return await userApi
    .get(`profile/username/exists/${username}`)
    .then((response) => {
      return response.data.result;
    })
    .catch(() => {
      return false;
    });
};

/**
 * Check if an email exists
 * @param {string} email The email
 */
const checkIfEmailExists = async (email) => {
  return await userApi
    .get(`profile/email/exists/${email}`)
    .then((response) => {
      return response.data.result;
    })
    .catch(() => {
      return false;
    });
};

/**
 * Check if an SAMLLoginId exists
 * @param {string} SAMLLoginId The SAMLLoginId
 */
const checkIfSAMLLoginIdExists = async (SAMLLoginId, siteId) => {
  return await userApi
    .get(`profile/site/${siteId}/contains/saml-login-id/${SAMLLoginId}`)
    .then((response) => {
      return response.data.result;
    })
    .catch(() => {
      return false;
    });
};

/**
 * Returns the email delivery activity based on SendGrid information.
 * @param {string} username The username to check the email activity from
 */
const getEmailDeliveryActivity = async (username) => {
  return await userApi
    .get(`account/email-activity/${username}`)
    .then((response) => {
      return response.data.result;
    });
};

export {
  checkIfPatientSiteIdExists,
  checkIfUsernameExists,
  checkIfEmailExists,
  checkIfSAMLLoginIdExists,
  getEmailDeliveryActivity,
  demoPatientUsername,
};
