/**
 * Get icon path from file name
 * with fallback on unknown icon in case file doesn't exist
 * @param name {string} icon name.
 */
function getIconPath(name) {
  let icon = null;
  try {
    icon = require(`@/assets/icons/${name}.svg`);
  } catch {
    icon = require(`@/assets/icons/unknown.svg`);
  }
  if (Object.prototype.hasOwnProperty.call(icon, "default")) {
    icon = icon.default;
  }

  return icon.url;
}

export { getIconPath };
