export default [
  {
    path: "/oauth/redirect",
    name: "oauth_redirect",
    component: () => import("@/pages/CommonPages/OAuthPages/OAuthRedirect.vue"),
    props: (route) => ({
      code: route.query.code,
      state: route.query.state,
    }),
    meta: {
      requiresTherapist: true,
    },
  },
];
