<template>
  <div
    class="flex items-center py-3 justify-between border rounded"
    :class="[warningClasses, lowPadding ? 'px-3' : 'px-6']"
    @click="$emit('click')"
  >
    <div
      class="flex items-center"
      :class="[
        { 'pe-8': showRightSlot },
        { 'flex-grow justify-center': isContentCentered },
      ]"
    >
      <slot name="icon" v-if="showIcon">
        <svg-icon
          v-if="icon"
          :name="icon"
          class="me-4 flex-shrink-0"
          :class="iconClasses"
        />
      </slot>
      <slot />
    </div>
    <div v-if="showRightSlot" class="flex flex-wrap justify-end items-center">
      <slot name="rightPart" />
    </div>
  </div>
</template>

<script>
const BoxType = Object.freeze({
  Info: "info",
  Success: "success",
  Warning: "warning",
  Error: "error",
});

const WarningClassMap = Object.freeze({
  containerClasses: {
    [BoxType.Info]: "border-blue-300 bg-blue-50",
    [BoxType.Success]: "border-blue-300 bg-blue-50",
    [BoxType.Warning]: "border-yellow-500 bg-yellow-200",
    [BoxType.Error]: "border-red-300 bg-red-100",
  },
  iconName: {
    [BoxType.Info]: "info-circle",
    [BoxType.Success]: "tick",
    [BoxType.Warning]: "warning-circle",
    [BoxType.Error]: "warning",
  },
  iconClasses: {
    [BoxType.Info]: "text-blue-700 icon-lg",
    [BoxType.Success]: "text-blue-700 icon-md",
    [BoxType.Warning]: "text-yellow-600 icon-lg",
    [BoxType.Error]: "text-red-500 icon-xl",
  },
});

export default {
  props: {
    type: {
      type: String,
      default: BoxType.Info,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    showRightSlot: {
      type: Boolean,
      default: false,
    },
    isContentCentered: {
      type: Boolean,
      default: false,
    },
    lowPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    icon() {
      return WarningClassMap.iconName[this.type];
    },
    warningClasses() {
      return WarningClassMap.containerClasses[this.type];
    },
    iconClasses() {
      return WarningClassMap.iconClasses[this.type];
    },
  },
};
</script>
