import {
  formatToDDMMMYYYY as formatToDDMMMYYYYFns,
  formatToDDMMMYYYYHmm as formatToDDMMMYYYYHmmFns,
  formatToHmm as formatToHmmFns,
  formatToHHmm as formatToHHmmFns,
  format as formatFns,
} from "@/utils/date";
import { parseISO } from "date-fns";
import i18n from "@/i18n";

export default class DateTimeOffset {
  // Private variables
  isoString;
  fullDate;
  fullTime;
  timeOffset;
  displayedOffset;
  offsetInMinutes;
  rawDateTime;
  localeDateObject;

  /**
   * Constructor for the DateTimeOffset
   * @param value {String | Date | Number} ISO format of the date to create
   */
  constructor(value = null) {
    // When null, use date.now in locale timezone
    if (!value) {
      this.parseNumber(Date.now());
      return;
    }

    // Generate the date from number in locale timezone
    if (
      typeof value == "number" ||
      value instanceof Number ||
      Object.prototype.toString.call(value) === "[object Number]"
    ) {
      this.parseNumber(value);
      return;
    }

    // Generate the date in locale timezone
    if (
      value instanceof Date ||
      Object.prototype.toString.call(value) === "[object Date]"
    ) {
      this.parseDate(value);
      return;
    }

    // Generate the date from the string
    if (
      typeof value == "string" ||
      value instanceof String ||
      Object.prototype.toString.call(value) === "[object String]"
    ) {
      this.parseString(value);
      return;
    }

    throw "Parameter type is not valid";
  }

  /**
   * Method to parse a Number into a DateTimeOffset
   * @param value {Number} offset will correspond to the locale timezone
   * @returns {DateTimeOffset} the object with the date parsed
   */
  parseNumber(value) {
    return this.parseDate(new Date(value));
  }

  /**
   * Method to parse a Date into a DateTimeOffset
   * @param value {Date} offset will correspond to the locale timezone
   * @returns {DateTimeOffset} the object with the date parsed
   */
  parseDate(value) {
    this.fullDate = formatFns(value, "yyyy-MM-dd", {
      locale: "en-US",
    });
    this.fullTime = formatFns(value, "HH:mm:ss.SSS", {
      locale: "en-US",
    });
    this.offsetInMinutes = value.getTimezoneOffset();
    this._formatOffsets(this.offsetInMinutes);
    this.isoString = this.fullDate + "T" + this.fullTime + this.timeOffset;
    this.rawDateTime = parseISO(this.fullDate + "T" + this.fullTime);
    this.localeDateObject = parseISO(this.isoString);
    return this;
  }

  /**
   * Method to parse a string into a DateTimeOffset
   * @param value {String} format must be yyyy-MM-ddTHH:mm:ss.SSSZZZ
   * @returns {DateTimeOffset} the object with the date parsed
   */
  parseString(value) {
    // Create the date object in the locale timezone
    this.localeDateObject = parseISO(value);

    // Extract date, time and offset from initial value
    let [fullDate, time] = value.split("T");
    this.fullDate = fullDate;

    // Force time to be midnight if not given
    if (time === undefined) {
      time = "00:00:00.000";
    }

    // Extract time and offset from the time string part
    [this.fullTime, this.timeOffset] = time.split(/(?=[+-])/);

    // Special case when timezone is UTC, offset is not in hh:mm but ends by Z
    let regexEndByZ = new RegExp("Z$", "gi");
    if (regexEndByZ.test(this.fullTime)) {
      this.timeOffset = "+00:00";
      this.fullTime = this.fullTime.slice(0, -1);
    } else if (this.timeOffset === undefined) {
      this.timeOffset = "+00:00";
    }

    // Define date time without offset
    this.rawDateTime = parseISO(this.fullDate + "T" + this.fullTime);

    // Split offset in hour and minutes
    let [hoursOfOffset, minutesOfOffset] = this.timeOffset.split(":");

    // Compute offset in minutes as Javascript computes it (sign is reversed)
    // Javascript offset for UTC+10:00 is -600 minutes
    if (minutesOfOffset === undefined) {
      minutesOfOffset = "00";
    }
    let signOffset = hoursOfOffset.startsWith("-") ? 1 : -1;
    // Remove the sign at the beginning
    hoursOfOffset = hoursOfOffset.slice(1);
    this.offsetInMinutes =
      signOffset * (parseInt(hoursOfOffset) * 60 + parseInt(minutesOfOffset));

    this._formatOffsets(this.offsetInMinutes);
    this.isoString = this.fullDate + "T" + this.fullTime + this.timeOffset;
    return this;
  }

  /**
   * Private method used by the constructor.
   * Format the given offset to displayedOffset and timeOffset
   * e.g. 5.75 => +5:45 and +05:45
   *     -14.0 => -14   and -14:00
   * @param offsetInMinutes {Number} offset in minute
   */
  _formatOffsets(offsetInMinutes) {
    if (offsetInMinutes === 0) {
      this.timeOffset = "+00:00";
      this.displayedOffset = "";
      return;
    }

    let offsetSign = offsetInMinutes < 0 ? "+" : "-"; // Note: Javascript has negative offset for positive timezones (Europe/bern => -60 min)
    let offsetHours = Math.floor(Math.abs(offsetInMinutes / 60));
    let offsetMinutes = Math.abs(offsetInMinutes % 60);

    this.displayedOffset =
      offsetSign +
      offsetHours +
      (offsetMinutes !== 0
        ? `:${offsetMinutes < 10 ? "0" : ""}${offsetMinutes}`
        : "");

    this.timeOffset =
      offsetSign +
      (offsetHours < 10 ? "0" : "") +
      offsetHours +
      ":" +
      (offsetMinutes < 10 ? "0" : "") +
      offsetMinutes;
  }

  /**
   * Append offset to value when it does not correspond to locale offset
   * Winter time offset is compared to winter time offset in locale timezone
   * As well as for summer offset
   * @param value {String} will be append at the beginning of the result
   * @param forceAddingOffset {Boolean} true to append UTC offset
   * @returns {String} the value appended by the offset
   */
  formatToZ(value, forceAddingOffset = false) {
    if (!value) {
      return "";
    }

    if (!forceAddingOffset) {
      forceAddingOffset = !this.isOffsetSameAsLocale;
    }

    return forceAddingOffset
      ? `${value} (${i18n.t("Common.Timezone.utc", {
          offset: this.displayedOffset,
        })})`
      : value;
  }

  /**
   * Returns a Date object of the DateTime without info ot the offset
   * @returns {Date} date without the offset
   */
  get dateTime() {
    return this.rawDateTime;
  }

  /**
   * Returns a Date object of the DateTime without info ot the offset
   * e.g. For the date 2022-02-01 13:25:12.586 +02:00
   * Where locale timezone is -04:00
   * Returns 2022-02-01 09:25:12.586
   * @returns {Date} date offsetted to locale timezone
   */
  get localeDateTime() {
    return this.localeDateObject;
  }

  /**
   * Returns the offset in minutes as Javascript computes it
   * Javascript offset for UTC+10:00 is -600 minutes
   * @returns {Number} offset in minutes
   */
  getTimezoneOffset() {
    return this.offsetInMinutes;
  }

  /**
   * Returns the date formatted as 01 Jun 2022
   * @returns {String} formatted day, without time
   */
  get formatToDDMMMYYYY() {
    return formatToDDMMMYYYYFns(this.rawDateTime);
  }

  /**
   * Returns the time formatted as 1:05
   * @returns {String} formatted time only - hours may have one number
   */
  get formatToHmm() {
    return formatToHmmFns(this.rawDateTime);
  }

  get formatToDDMMMYYYYHmm() {
    return formatToDDMMMYYYYHmmFns(this.rawDateTime);
  }

  /**
   * Returns the time formatted as 01:05
   * @returns {String} formatted time only - hours has always 2 numbers
   */
  get formatToHHmm() {
    return formatToHHmmFns(this.rawDateTime);
  }

  /**
   * Returns the time formatted as 1:05 (UTC+05:45)
   * Offset is displayed only when it differs from locale timezone
   * @param forceAddingOffset {Boolean} true to always display the offset
   * @returns {String} formatted time only with the offset - hours may have one number
   */
  formatToHmmZ(forceAddingOffset = false) {
    return this.formatToZ(this.formatToHmm, forceAddingOffset);
  }

  /**
   * Returns the time formatted as 01:05 (UTC+05:45)
   * Offset is displayed only when it differs from locale timezone
   * @param forceAddingOffset {Boolean} true to always display the offset
   * @returns {String} formatted time only with the offset - hours has always 2 numbers
   */
  formatToHHmmZ(forceAddingOffset = false) {
    return this.formatToZ(this.formatToHHmm, forceAddingOffset);
  }

  /**
   * Return the offset formatted as +05:45
   * @returns {String} formatted offset - hours has always 2 numbers
   */
  get formatOffsetToHHmm() {
    return this.timeOffset;
  }

  /**
   * Return the offset formatted as +5:45 or an empty string if 0
   * @returns {String} formatted offset - hours may have 2 numbers
   */
  get formatOffsetToHmm() {
    return this.displayedOffset;
  }

  /**
   * Return an ISO string representation with the given offset
   * e.g. For the date 2022-02-01 13:25:12.586 +02:00
   * Whatever is the locale timezone
   * Returns  2022-02-01T13:25:12.586+02:00
   * @returns {String} DateTime formatted in ISO standard
   */
  get localeISOString() {
    return this.isoString;
  }

  /**
   * Compare the offset of the DateTimeOffset to the locale timezone by taken care of the period of the year
   * In winter, it takes into account the winter offset
   * As in summer, if there is a time change, it takes the summer offset otherwise the timezone base offset
   * @returns {Boolean} True if offset are the same as locale
   */
  get isOffsetSameAsLocale() {
    if (this.rawDateTime === undefined) {
      return false;
    }

    return this.rawDateTime.getTimezoneOffset() === this.offsetInMinutes;
  }

  /**
   * Used to serialized in JSON the object
   * Result can be parsed in C#
   * @returns {String} time with offset in ISO format
   */
  toJSON() {
    return this.isoString;
  }

  /**
   * Returns a string representation of an object.
   * @returns {String} time with offset in ISO format
   */
  toString() {
    return this.isoString;
  }

  /**
   * Returns a string representation of an object.
   * @returns {String} time with offset in ISO format
   */
  toLocaleString() {
    return this.isoString;
  }
}
