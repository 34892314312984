import { Model } from "@vuex-orm/core";
import FeedbackReport from "@/models/FeedbackReport.model";
import { isEntityContainsAllTerms, tidySearchTerm } from "@/utils/filters";

export default class FeedbackReportAttachment extends Model {
  static entity = "feedbackReportAttachment";

  static fields() {
    return {
      id: this.attr(null),
      feedbackReportId: this.attr(null),
      name: this.attr(null),
      type: this.attr(null),
      data: this.attr(null),
      feedbackReport: this.belongsTo(FeedbackReport, "feedbackReportId", "id"),
    };
  }

  /**
   *   Fetch all feedback report attachments for a feedback report Id.
   *   @param {string} feedbackReportId id of the related feedback report
   *   @param {AbortSignal} signal Permits request cancellation support
   */
  static async fetchAllFeedbackReportAttachments(feedbackReportId, signal) {
    return await this.api()
      .get("/feedbackreport/" + feedbackReportId + "/attachment", {
        signal,
      })
      .then((response) => {
        return response.response.data.result.map(
          (attachment) => new FeedbackReportAttachment(attachment)
        );
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   *   Returns the feedback report attachments from the store or fetch it from companion.
   *   @param {string} feedbackReportId id of the related feedback report
   *   @param {AbortSignal} signal Permits request cancellation support
   */
  static async getAllFeedbackReportAttachments(feedbackReportId, signal) {
    let attachments = FeedbackReportAttachment.all().find(
      (attachment) => attachment.feedbackReportId === feedbackReportId
    );
    if (attachments && attachments.length > 0) {
      return attachments;
    } else {
      return await this.fetchAllFeedbackReportAttachments(
        feedbackReportId,
        signal
      );
    }
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    let fields = [this.type];
    if (this.name) {
      fields.push(tidySearchTerm(this.name));
    }
    return fields;
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }
}
