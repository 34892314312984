import Store from "@/store";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import {
  getAppInsightCookie,
  removeAppInsightCookie,
  setAppInsightCookie,
} from "@/utils/cookies";
import loadConfigData from "@/services/configJson";

// See options in: https://github.com/microsoft/ApplicationInsights-JS
let appInsights = new ApplicationInsights({
  config: {
    enableAutoRouteTracking: false,
    disableFetchTracking: true,
    disableAjaxTracking: true,
    autoTrackPageVisitTime: true,
    // See: https://github.com/microsoft/ApplicationInsights-JS#ICookieMgrConfig
    cookieCfg: {
      setCookie: (name, value) => {
        setAppInsightCookie(name, value);
      },
      getCookie: (name) => {
        return getAppInsightCookie(name);
      },
      delCookie: (name) => {
        removeAppInsightCookie(name);
      },
    },
  },
});

loadConfigData().then((configData) => {
  appInsights.config.instrumentationKey =
    configData.AppInsightInstrumentationKey;
  appInsights.loadAppInsights();
  anonymizeInsightsData();
});

export function getAppinsightInstance() {
  return appInsights;
}

/**
 * See: node_modules\@microsoft\applicationinsights-core-js\src\JavaScriptSDK.Interfaces\ITelemetryItem.ts
 * for more information on the format.
 * @param name {string} Unique name of the event.
 */
export function trackEvent(name) {
  // Send user site id if available
  const siteId = getAuthUserSiteId();
  const customProperties = siteId ? { siteId: siteId } : undefined;

  getAppinsightInstance().trackEvent(
    {
      name: name,
    },
    customProperties
  );
}

/**
 * See: node_modules\@microsoft\applicationinsights-common\src\Interfaces\IPageViewTelemetry.ts
 * for more information on the format.
 * @param name {string} Unique name of the page view
 */
export function trackPageView(name) {
  let pageViewData = {
    name: name,
  };

  // Send user site id if available
  const siteId = getAuthUserSiteId();
  if (siteId) {
    pageViewData.properties = {
      siteId: siteId,
    };
  }

  getAppinsightInstance().trackPageView(pageViewData);
}

function getAuthUserSiteId() {
  const siteId = Store.getters["Auth/siteId"];

  // Do not return null data or empty guids
  if (!siteId || siteId === "00000000-0000-0000-0000-000000000000") {
    return "";
  }

  return siteId;
}

function anonymizeInsightsData() {
  // Since url contains patient ID, we remove every reference
  appInsights.addTelemetryInitializer((envelope) => {
    if (envelope) {
      if (envelope.baseData) {
        envelope.baseData.refUri = "";
        envelope.baseData.uri = "";
        envelope.baseData.url = "";
        envelope.baseData.PageUrl = "";
      }
      if (envelope.data) {
        envelope.data.PageUrl = "";
      }
      if (envelope.tags) {
        envelope.tags["ai.operation.name"] = "";
      }
      if (envelope.ext && envelope.ext.trace) {
        envelope.ext.trace.name = "";
      }
    }
  });
}
