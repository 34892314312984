import { Model } from "@vuex-orm/core";
import Site from "@/models/Site.model";
import { isEntityContainsAllTerms } from "@/utils/filters";

export default class SiteUnit extends Model {
  static entity = "siteUnits";

  static fields() {
    return {
      id: this.attr(null),
      site: this.belongsTo(Site, "siteId"),
      siteId: this.attr(null),
      name: this.attr(null),
      timeZoneId: this.attr(null),
      hasCurrentUserEditRights: this.attr(false),
      userCount: this.attr(0),
    };
  }

  // Network functions
  static async get() {
    return await this.api().get("/site-unit", { dataKey: "result" });
  }

  static async post(newSiteUnit) {
    return await this.api().post("/site-unit", newSiteUnit, {
      dataKey: "result",
    });
  }

  static async put(updatedUnit) {
    return await this.api().put(`/site-unit/${updatedUnit.id}`, updatedUnit, {
      dataKey: "result",
    });
  }

  static async remove(siteUnit) {
    return await this.api().delete("/site-unit/" + siteUnit.id, {
      delete: siteUnit.id,
      dataKey: "result",
    });
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    let fields = [this.name];
    return fields;
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    if (!this.entityFieldsToSearchInto.length) {
      return false;
    }
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }
}
