import { Model } from "@vuex-orm/core";
import Profile from "@/models/Profile.model";
import { parseISO } from "date-fns";

export default class VideoMeetingRequest extends Model {
  static entity = "videomeetingrequests";

  static fields() {
    return {
      id: this.attr(""),
      patientUsername: this.attr(null),
      addedAtUTC: this.attr(null),
      resolutionState: this.attr(null),
      resolvedAtUTC: this.attr(null),
      message: this.attr(""),
      patient: this.belongsTo(Profile, "patientUsername", "userName"),
    };
  }

  get IsUnresolved() {
    return this.resolutionState == "Unresolved";
  }

  get AddedAtUTC() {
    return this.addedAtUTC ? parseISO(this.addedAtUTC) : null;
  }

  get ResolvedAtUTC() {
    return this.resolvedAtUTC ? parseISO(this.resolvedAtUTC) : null;
  }

  /**
   * Get video meeting requests
   * @param {object} options
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async getAndRefreshAll(options, signal) {
    if (!options) {
      options = {};
    }
    if (options.onlyUnresolved === undefined) {
      options.onlyUnresolved = false;
    }

    return await this.api().get(
      `videomeeting-request?onlyUnresolved=${options.onlyUnresolved}`,
      {
        dataKey: "result",
        persistBy: "create",
        signal,
      }
    );
  }

  static async ignore(videoMeetingRequest) {
    return await this.api().post(
      `/videomeeting-request/${videoMeetingRequest.id}/ignore`,
      {},
      {
        dataKey: "result",
      }
    );
  }
}
