export default [
  {
    path: "customers",
    component: () =>
      import(
        "@/pages/EntityManagementPages/CustomerManagementPages/CustomerListPage"
      ),
  },
  {
    path: "customers/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/CustomerManagementPages/CustomerCreatePage"
      ),
    props: (route) => ({
      entityIdentifier: route.query.entityIdentifier,
      siteId: route.query.siteId,
    }),
  },
  {
    path: "customers/:customerId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/CustomerManagementPages/CustomerDetailsPage"
      ),
    props: (route) => ({ customerId: route.params.customerId }),
  },
];
