module.exports = {
  future: {
    removeDeprecatedGapUtilities: true,
    purgeLayersByDefault: true,
  },
  purge: false,
  prefix: "",
  important: false,
  separator: ":",
  theme: {
    colors: {
      transparent: "transparent",
      focus: "rgba(4, 21, 39, 0.8);",
      black: "#000",
      white: "#fff",
      primary: "#37A4DC",
      disabled: "#BFBFBF",
      translucent: {
        60: "rgba(256, 256, 256, 0.6)",
        90: "rgba(256, 256, 256, 0.9)",
      },
      green: {
        200: "#f4fcea",
        500: "#97E812",
        600: "#4f9112",
        "500a25": "#97E81219",
        700: "#3e7211",
      },
      yellow: {
        200: "#fff9eb",
        300: "#ffe9bd",
        "300a66": "#ffe9bdaa",
        500: "#FFC82C",
        "500a25": "#FFC82C19",
        600: "#F7931E",
        700: "#E1AD28",
      },
      orange: {
        200: "#fdf0ea",
        400: "#f6c66f",
        500: "#F07313",
        "500a25": "#F0731719",
        800: "#db5d0c",
        900: "#b86814",
      },
      red: {
        100: "#FEF5F5",
        150: "#ffe9e8",
        200: "#F8CECC",
        300: "#ff8585",
        400: "#ff3535",
        500: "#DD4746",
        700: "#A23C24",
      },
      gray: {
        50: "#F9F9F9",
        100: "#F1F1F2",
        150: "#e6e6e6",
        200: "#E0E2E4",
        "200a50": "rgba(224, 226, 228, 0.5)",
        300: "#BFBFBF",
        400: "#727272CC",
        425: "rgba(4, 21, 39, 0.8)",
        450: "#959595",
        500: "#727272",
        600: "#5a626b",
        625: "rgba(90,98,107,0.8)",
        700: "#464C59",
        800: "#3c3b3b",
      },
      blue: {
        50: "#f2fbff",
        100: "#EBF6FC",
        150: "#E8EEF1",
        200: "#CCE6F5",
        300: "#73BFE7",
        400: "#4BA4F7",
        500: "#37A4DC",
        "500a25": "rgba(55, 164, 220, 0.2)",
        "500a50": "rgba(55, 164, 220, 0.5)",
        600: "#6AA2D6",
        700: "#456E9A",
        "700a75": "#3B648D",
        "700a30": "#2A5176",
        750: "#5682AA",
        "750a25": "#5682AA19",
        "750a15": "#5682AA0F",
        800: "#1F4566",
        "800a60": "rgba(31, 69, 102, 0.6)",
        900: "#183453",
        950: "#041527",
      },
      trainingColors: {
        goal: "#E0E2E4",
        selfTraining: "#6ACBCD",
        supervised: "#3B648D",
      },
      programEditorTabColors: {
        // basic colors
        1: "#3cacae",
        2: "#8e346a",
        3: "#ff72b7",
        4: "#ce941c",
        5: "#b973d9",
        6: "#ff9082",
        // corresponding hover colors
        "1h": "#d9f2f2",
        "2h": "#f1dae8",
        "3h": "#ffe6f2",
        "4h": "#f9ecd2",
        "5h": "#f5ebfa",
        "6h": "#ffe8e6",
      },
      programEditorPresetTabColors: {
        1: "#798fa3",
        "1h": "#798fa3",
      },
      borgScaleColors: {
        // colors defined in DSR-1099 for patient state
        0: "#c5d1ff",
        1: "#a0b5fc",
        2: "#7492ff",
        3: "#82bbf1",
        4: "#4ba4f7",
        5: "#9cdd57",
        6: "#68af00",
        7: "#f5e380",
        8: "#ffc246",
        9: "#ff782f",
        10: "#f25757",
      },
    },
    spacing: {
      px: "1px",
      0: "0",
      1: "0.25rem",
      2: "0.5rem",
      3: "0.75rem",
      4: "1rem",
      5: "1.25rem",
      6: "1.5rem",
      7: "1.75rem",
      8: "2rem",
      9: "2.25rem",
      10: "2.5rem",
      11: "2.75rem",
      12: "3rem",
      14: "3.5rem",
      15: "3.75rem",
      16: "4rem",
      18: "4.5rem",
      20: "5rem",
      22: "5.5rem",
      23: "5.75rem",
      24: "6rem",
      25: "6.25rem",
      26: "6.5rem",
      28: "7rem",
      30: "7.5rem",
      32: "8rem",
      34: "8.5rem",
      40: "10rem",
      42: "10.5rem",
      44: "11rem",
      48: "12rem",
      52: "13rem",
      54: "13.5rem",
      56: "14rem",
      60: "15rem",
      64: "16rem",
      68: "17rem",
      72: "18rem",
      74: "18.5rem",
      76: "19rem",
      78: "19.5rem",
      80: "20rem",
      82: "20.5rem",
      84: "21rem",
      88: "22rem",
      92: "23rem",
      96: "24rem",
      98: "24.5rem",
      100: "25rem",
      104: "26rem",
      112: "28rem",
      124: "31rem",
      128: "32rem",
      140: "35rem",
      152: "38rem",
      160: "40rem",
      162: "41rem",
      192: "48rem",
      208: "52rem",
      224: "56rem",
      256: "64rem",
    },
    screens: {
      sm: "640px",
      md: "768px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px",
      "3xl": "1920px",
    },
    fontFamily: {
      sans: [
        "Open Sans",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        '"Helvetica Neue"',
        "Arial",
        '"Noto Sans"',
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"',
      ],
      serif: ["Georgia", "Cambria", '"Times New Roman"', "Times", "serif"],
      mono: [
        "Menlo",
        "Monaco",
        "Consolas",
        '"Liberation Mono"',
        '"Courier New"',
        "monospace",
      ],
    },
    fontSize: {
      xs: "0.74rem",
      sm: "0.86rem",
      base: "1rem", // 14px
      lg: "1.15rem",
      xl: "1.42rem",
      "2xl": "1.85rem",
      "3xl": "1.95rem",
      "4xl": "2.25rem",
      "5xl": "3rem",
      "6xl": "4rem",
      "7xl": "5rem",
    },
    fontWeight: {
      light: 100,
      regular: 400,
      semibold: 600,
      bold: 700,
    },
    lineHeight: {
      none: "1",
      tight: "1.2",
      snug: "1.3",
      normal: "1.4",
      relaxed: "1.6",
    },
    letterSpacing: {
      tight: "-0.025em",
      normal: "0",
      wide: "0.01em",
      wider: "0.02em",
      widest: "0.05em",
    },
    textColor: (theme) => theme("colors"),
    backgroundColor: (theme) => theme("colors"),
    backgroundPosition: {
      bottom: "bottom",
      center: "center",
      left: "left",
      "left-bottom": "left bottom",
      "left-top": "left top",
      right: "right",
      "right-bottom": "right bottom",
      "right-top": "right top",
      top: "top",
    },
    backgroundSize: {
      auto: "auto",
      cover: "cover",
      contain: "contain",
    },
    borderWidth: {
      default: "1px",
      0: "0",
      2: "2px",
      3: "3px",
      4: "4px",
      5: "5px",
      6: "6px",
      8: "8px",
    },
    borderColor: (theme) => ({
      ...theme("colors"),
      default: theme("colors.gray.300", "currentColor"),
    }),
    borderRadius: {
      none: "0",
      sm: ".25rem",
      default: ".375rem",
      lg: ".5rem",
      xl: ".75rem",
      full: "9999px",
    },
    cursor: {
      auto: "auto",
      default: "default",
      pointer: "pointer",
      wait: "wait",
      move: "move",
      "not-allowed": "not-allowed",
    },
    width: (theme) => ({
      auto: "auto",
      ...theme("spacing"),
      "1/2": "50%",
      "1/3": "33.33333%",
      "2/3": "66.66667%",
      "1/4": "25%",
      "3/4": "75%",
      "1/5": "20%",
      "2/5": "40%",
      "3/5": "60%",
      "4/5": "80%",
      "1/6": "16.66667%",
      "5/6": "83.33333%",
      "1/7": "14.285714285%",
      "2/7": "28.571428571%",
      "3/7": "42.85714285%",
      "4/7": "57.14285714%",
      "1/9": "11.1111%",
      "9/10": "90%",
      full: "100%",
      screen: "100vw",
      max: "max-content",
    }),
    height: (theme) => ({
      auto: "auto",
      ...theme("spacing"),
      full: "100%",
      screen: "100vh",
    }),
    minWidth: (theme) => ({
      0: "0",
      ...theme("spacing"),
      full: "100%",
    }),
    minHeight: (theme) => ({
      0: "0",
      full: "100%",
      ...theme("spacing"),
      screen: "100vh",
    }),
    maxWidth: (theme) => ({
      none: "none",
      xs: "20rem",
      sm: "24rem",
      md: "28rem",
      lg: "32rem",
      xl: "36rem",
      "2xl": "42rem",
      "3xl": "48rem",
      "4xl": "56rem",
      "5xl": "64rem",
      "6xl": "76rem",
      "7xl": "92rem",
      "8xl": "112rem",
      "9xl": "130rem",
      full: "100%",
      ...theme("spacing"),
    }),
    maxHeight: (theme) => ({
      0: "0",
      ...theme("spacing"),
      full: "100%",
      screen: "100vh",
    }),
    padding: (theme) => theme("spacing"),
    margin: (theme, { negative }) => ({
      auto: "auto",
      ...theme("spacing"),
      ...negative(theme("spacing")),
    }),
    objectPosition: {
      bottom: "bottom",
      center: "center",
      left: "left",
      "left-bottom": "left bottom",
      "left-top": "left top",
      right: "right",
      "right-bottom": "right bottom",
      "right-top": "right top",
      top: "top",
    },
    boxShadow: {
      default:
        "0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 3px rgba(0, 0, 0, 0.06)",
      overlaptop: "rgba(0, 0, 0, 0.1) 0px -7px 3px -2px",
      overlapbottom: "rgba(0, 0, 0, 0.1) 0px 7px 3px -2px",
      xs: "4px -2px 1px rgba(0, 0, 0, 0.02), -1px 1px 1px 2px rgba(0, 0, 0, 0.01)",
      sm: "0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 1px rgba(0, 0, 0, 0.06)",
      md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
      lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      xl: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)",
      "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
      none: "none",
    },
    zIndex: {
      auto: "auto",
      0: 0,
      10: 10,
      20: 20,
      30: 30,
      40: 40,
      50: 50,
    },
    opacity: {
      0: "0",
      25: ".25",
      50: ".5",
      75: ".75",
      100: "1",
    },
    fill: (theme) => ({
      ...theme("colors"),
      current: "currentColor",
    }),
    stroke: {
      current: "currentColor",
    },
    flex: {
      1: "1 1 0%",
      auto: "1 1 auto",
      initial: "0 1 auto",
      none: "none",
    },
    flexGrow: {
      0: 0,
      default: 1,
    },
    flexShrink: {
      0: 0,
      default: 1,
    },
    listStyleType: {
      none: "none",
      disc: "disc",
      decimal: "decimal",
    },
    inset: {
      0: 0,
      auto: "auto",
    },
    customForms: (theme) => ({
      default: {
        "input, textarea": {
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
        },
        "input, textarea, multiselect, select": {
          borderColor: theme("colors.gray.300"),
          borderRadius: theme("borderRadius.default"),
          "&:focus": {
            boxShadow: undefined,
            borderColor: undefined,
          },
          "&::placeholder": {
            color: undefined,
          },
        },
      },
    }),
    container: {},
    extend: {
      gridTemplateColumns: {
        14: "repeat(14, minmax(0, 1fr))",
      },
      gridColumnStart: {
        14: "14",
        15: "15",
      },
      gridColumnEnd: {
        14: "14",
        15: "15",
      },
      typography: (theme) => ({
        // prose classes
        default: {
          css: {
            "ol > li::before": {
              content: "",
            },
          },
        },
      }),
    },
  },
  variants: {
    appearance: ["responsive"],
    backgroundAttachment: ["responsive"],
    backgroundColor: ["responsive", "hover", "focus"],
    backgroundPosition: ["responsive"],
    backgroundRepeat: ["responsive"],
    backgroundSize: ["responsive"],
    border: ["first"],
    borderCollapse: [],
    borderColor: ["responsive", "hover", "focus"],
    borderRadius: ["responsive"],
    borderStyle: ["responsive"],
    borderWidth: ["responsive", "first"],
    cursor: ["responsive"],
    display: ["responsive"],
    flexDirection: ["responsive"],
    flexWrap: ["responsive"],
    alignItems: ["responsive"],
    alignSelf: ["responsive"],
    justifyContent: ["responsive"],
    alignContent: ["responsive"],
    flex: ["responsive"],
    flexGrow: ["responsive"],
    flexShrink: ["responsive"],
    float: ["responsive"],
    fontFamily: ["responsive"],
    fontWeight: ["responsive", "hover", "focus"],
    height: ["responsive"],
    lineHeight: ["responsive"],
    listStylePosition: ["responsive"],
    listStyleType: ["responsive"],
    margin: ["responsive", "first"],
    maxHeight: ["responsive"],
    maxWidth: ["responsive"],
    minHeight: ["responsive"],
    minWidth: ["responsive"],
    negativeMargin: ["responsive"],
    objectFit: ["responsive"],
    objectPosition: ["responsive"],
    opacity: ["responsive"],
    outline: ["focus"],
    overflow: ["responsive"],
    padding: ["responsive", "odd", "even"],
    pointerEvents: ["responsive"],
    position: ["responsive"],
    inset: ["responsive"],
    resize: ["responsive"],
    boxShadow: ["responsive", "hover", "focus"],
    fill: ["hover", "focus"],
    stroke: [],
    tableLayout: ["responsive"],
    textAlign: ["responsive"],
    textColor: ["responsive", "hover", "focus"],
    fontSize: ["responsive"],
    fontStyle: ["responsive"],
    textTransform: ["responsive"],
    textDecoration: ["responsive", "hover", "focus"],
    fontSmoothing: ["responsive"],
    letterSpacing: ["responsive"],
    userSelect: ["responsive"],
    verticalAlign: ["responsive"],
    visibility: ["responsive"],
    whitespace: ["responsive"],
    wordBreak: ["responsive"],
    width: ["responsive"],
    zIndex: ["responsive"],
  },
  corePlugins: {
    container: false,
  },
  plugins: [
    require("@tailwindcss/custom-forms"),
    require("@tailwindcss/typography"),
    require("tailwindcss-rtl"),
  ],
};
