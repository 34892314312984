import SoapData from "@/models/SoapData.model";
import ActivityResult from "@/models/activity-result/ActivityResult.model";
import DateTimeOffset from "@/models/DateTimeOffset.model";
import Profile from "@/models/Profile.model";
import {
  getMinutesAndSecondsFromSeconds,
  getMinutesFromSeconds,
  getAdaptiveTranslatedTimeFromSeconds,
} from "@/utils/duration";
import {
  formatToDDMMMYYYY,
  formatToDDMMMYYYYHmm,
  formatToHmm,
} from "@/utils/date";
import { tr } from "@/utils/translation";
import TextBuilder from "@/utils/textBuilder";
import Store from "@/store/";
import { groupBy, lowerFirst } from "lodash";
import cloneDeep from "lodash/cloneDeep";
import { activityApi } from "@/services/api";
import { DosageKind } from "@/services/enums";

export const SessionSupervisionMode = Object.freeze({
  Supervised: "Supervised",
  RemotelySupervised: "RemotelySupervised",
  Unsupervised: "Unsupervised",
});

export default class SessionReport {
  constructor(sessionReport) {
    this.id = sessionReport.id;
    this.addedAtUtc = sessionReport.addedAtUtc;
    this.addedAt = formatToDDMMMYYYYHmm(sessionReport.addedAtUtc);
    this.startDate = sessionReport.startDate;
    this.startDateOffset = new DateTimeOffset(sessionReport.startDateOffset);
    this.endDate = sessionReport.endDate;
    this.endDateOffset = new DateTimeOffset(sessionReport.endDateOffset);
    this.totalDuration = getMinutesFromSeconds(sessionReport.totalDurationSec);
    this.userName = sessionReport.userName;
    this.completed = sessionReport.completed;
    this.completionCount = sessionReport.completionCount;
    this.deviceMode = sessionReport.deviceMode || "";
    this.supervisionMode = sessionReport.supervisionMode;

    this.soapData = new SoapData(sessionReport.soapData);

    this.results = sessionReport.results.map(
      (result) => new ActivityResult(result)
    );

    this.therapistUsername = sessionReport.therapistUsername;
  }

  /**
   * Loads the therapist profile if there is a therapist username
   */
  get therapistProfile() {
    if (this.therapistUsername) {
      return Profile.query().find(this.therapistUsername);
    }

    // Use the current loaded profile when no profile is provided
    let currentUsername = Store.getters["Auth/username"];
    if (currentUsername) {
      return Profile.query().find(currentUsername);
    }

    return null;
  }

  /**
   * Loads the patient profile if there is a patient username
   */
  get patientProfile() {
    if (this.userName) {
      return Profile.query().find(this.userName);
    }
    return null;
  }

  async save() {
    return await SessionReport.saveSessionReport(this.serialize());
  }

  /**
   * Serialize the session report for the backend
   */
  serialize() {
    const copy = cloneDeep(this);
    // Convert minutes to seconds
    copy.totalDurationSec = copy.totalDuration / 60;
    // Deleting properties that are not for the backend
    delete copy.therapistProfile;
    delete copy.totalDuration;
    delete copy.addedAt;

    return copy;
  }

  /**
   * Returns the session duration.
   */
  duration() {
    let startDate = new Date(this.startDate);
    let endDate = new Date(this.endDate);
    // drop milliseconds to make consistent with backend
    let duration = Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
    const [minutes, seconds] = getMinutesAndSecondsFromSeconds(duration);
    return tr("Common.DurationWithValues.minutesSecondsDisplayWithLegends", {
      minutes: minutes,
      seconds: seconds,
    });
  }

  /**
   * Returns the activity results grouped by the activityname
   */
  getGroupedActivityResults() {
    const groupedResults = groupBy(this.results, "activityId");
    // Filter and map the object (We want to have an array)
    // Reduce is used to map and filter the object
    return Object.values(groupedResults).reduce(function (
      result,
      activityResults
    ) {
      // Get the first result
      const firstResult = activityResults[Object.keys(activityResults)[0]];

      // Remove all skipped activities from the results array
      activityResults = activityResults.filter(
        (activityResult) => !activityResult.skipped
      );

      // Only add this object if there is at least one activity (We dont want to show activities with skipped sessions)
      if (activityResults.length > 0) {
        result.push({
          activityId: firstResult.activityId,
          activityName: firstResult.activityName,
          movementName: firstResult.movementName,
          activityResults: activityResults,
        });
      }

      return result;
    },
    []);
  }

  /**
   * Returns the activities count
   */
  getActivitiesCount() {
    return this.results.length;
  }

  /**
   * Returns the not skipped sessions count
   */
  getNotSkippedSessionsCount() {
    return this.results.filter((result) => !result.skipped).length;
  }

  /**
   * Returns the total active training time (Sum up of all active training times)
   */
  getTotalActiveTrainingTime() {
    const totalActiveTrainingTimeSeconds = this.results.reduce(
      (prev, current) => prev + current.activeTrainingTimeSeconds,
      0
    );
    return getAdaptiveTranslatedTimeFromSeconds(totalActiveTrainingTimeSeconds);
  }

  /**
   * Returns the total duration (Sum up of all durations)
   */
  getTotalDuration() {
    const totalDurationSeconds = this.results.reduce((prev, current) => {
      let currentValue =
        current.dosageKind === DosageKind.LOGIC // For logic dosage kind activities (Mind4 and Stardust), the duration is set to the active training time
          ? current.activeTrainingTimeSeconds
          : current.durationSeconds;
      return prev + currentValue;
    }, 0);
    return getAdaptiveTranslatedTimeFromSeconds(totalDurationSeconds);
  }

  /**
   * Returns the completed activities percentage (adherence)
   */
  getCompletedActivities() {
    const totalCount = this.results.length;

    const completedCount = this.completionCount;

    if (!completedCount) {
      return 0;
    }

    return Math.floor((completedCount / totalCount) * 100);
  }

  /**
   * Returns the translated supervised session type
   */
  translatedSupervisedSessionType() {
    let sessionType = lowerFirst(this.supervisionMode);
    return tr(`Common.Session.SessionTypes.${sessionType}`);
  }

  isSessionUnsupervised() {
    return this.supervisionMode === SessionSupervisionMode.Unsupervised;
  }

  isPrivateDeviceMode() {
    return this.deviceMode.toLowerCase() === "private";
  }

  showPatientStateInfo(sessionReview) {
    return (
      sessionReview.PreMoodAnswer ||
      sessionReview.PostMoodAnswer ||
      sessionReview.BorgAnswer
    );
  }

  /**
   * Provides soap report data as formatted text.
   */
  getSoapReportAsText(sessionReview) {
    let tb = new TextBuilder();

    // General
    // Create title
    let reportTitle = `${tr(
      "TherapistPatientManagementPage.ReviewPage.sessionReport"
    )}\t${this.startDateOffset.formatToDDMMMYYYY} - ${
      this.startDateOffset.formatToHmm
    }-${this.endDateOffset.formatToHmmZ(true)}`;
    if (!this.endDateOffset.isOffsetSameAsLocale) {
      reportTitle = `${reportTitle}\n\t\t\t${formatToDDMMMYYYY(
        this.startDateOffset.localeDateTime
      )} - ${formatToHmm(this.startDateOffset.localeDateTime)}-${formatToHmm(
        this.endDateOffset.localeDateTime
      )} (${tr("Common.Timezone.Profile.yourTime")})`;
    }
    tb.addTitle(reportTitle);

    if (this.patientProfile != null) {
      tb.addResult(
        tr("Common.Profile.fullName"),
        this.patientProfile.displayedName
      );
    }
    tb.addResult(
      tr("TherapistPatientManagementPage.ReviewPage.duration"),
      this.duration()
    );
    if (this.therapistProfile != null) {
      tb.addResult(
        tr("TherapistPatientManagementPage.ReviewPage.SessionReport.therapist"),
        this.therapistProfile.displayedName
      );
    }
    tb.addResult(
      tr("TherapistPatientManagementPage.ReviewPage.sessionType"),
      this.translatedSupervisedSessionType()
    );

    // Subjective
    if (
      this.soapData.subjective.text ||
      this.showPatientStateInfo(sessionReview)
    ) {
      tb.addTitle(tr("TherapistPatientManagementPage.ReviewPage.subjective"));
      if (this.showPatientStateInfo(sessionReview)) {
        tb.addSubTitle(
          tr(
            "TherapistPatientManagementPage.ReviewPage.selfReportedPatientState"
          ) + "*"
        );
        tb.addResult(
          tr("Common.PatientState.preProgramMood"),
          sessionReview.PreMoodAnswer == null
            ? "--"
            : sessionReview.PreMoodAnswer.skipped
            ? tr(`Common.PatientState.skipped`)
            : sessionReview.PreMoodAnswer.value.answer +
              " / 5 (" +
              tr(
                `Common.PatientState.Mood.${sessionReview.PreMoodAnswer.value.MoodValue}`
              ) +
              ")"
        );
        tb.addResult(
          tr("Common.PatientState.postProgramMood"),
          sessionReview.PostMoodAnswer == null
            ? "--"
            : sessionReview.PostMoodAnswer.skipped
            ? tr(`Common.PatientState.skipped`)
            : sessionReview.PostMoodAnswer.value.answer +
              " / 5 (" +
              tr(
                `Common.PatientState.Mood.${sessionReview.PostMoodAnswer.value.MoodValue}`
              ) +
              ")"
        );
        tb.addResult(
          tr("Common.PatientState.borgRpe"),
          sessionReview.BorgAnswer == null
            ? "--"
            : sessionReview.BorgAnswer.skipped
            ? tr(`Common.PatientState.skipped`)
            : sessionReview.BorgAnswer.value.answer +
              " / 10 (" +
              tr(
                `Common.PatientState.Borg.${sessionReview.BorgAnswer.value.BorgValue}`
              ) +
              ")"
        );
      }

      if (this.soapData.subjective.text) {
        tb.addSubTitle(tr("Common.therapistNote"));
        tb.addLine(this.soapData.subjective.text);
        tb.addLine(this.soapData.subjective.translatedLatestEdit());
      }
    }

    // Objective
    tb.addTitle(tr("TherapistPatientManagementPage.ReviewPage.objective"));
    if (this.isSessionUnsupervised()) {
      // Unsupervised session
      const activitiesPerformed =
        this.getActivitiesCount() > 0 ? this.getNotSkippedSessionsCount() : 0;
      tb.addResult(
        tr("TherapistPatientManagementPage.ReviewPage.activitiesOverPlanned"),
        `${activitiesPerformed} / ${this.getActivitiesCount()}`
      );
      tb.addResult(
        tr(
          "TherapistPatientManagementPage.ReviewPage.totalActiveTrainingTimeOverPlanned"
        ),
        `${this.getTotalActiveTrainingTime()} / ${this.getTotalDuration()}`
      );
    } else {
      // (Remotely) supervised session
      const activitiesPerformed =
        this.getActivitiesCount() > 0 ? this.getNotSkippedSessionsCount() : 0;
      tb.addResult(
        tr("TherapistPatientManagementPage.ReviewPage.activities"),
        activitiesPerformed
      );
      tb.addResult(
        tr("TherapistPatientManagementPage.ReviewPage.totalActiveTrainingTime"),
        this.getTotalActiveTrainingTime()
      );
    }

    tb.addLine("");
    for (let activity of this.getGroupedActivityResults()) {
      tb.addResult(activity.activityName, activity.movementName);
      for (let result of activity.activityResults) {
        tb.addResult(
          result.startOffset.formatToHHmmZ(),
          tr("TherapistPatientManagementPage.ReviewPage.ActivityReport.level", {
            level: result.level,
          })
        );
        tb.addResult(
          tr("TherapistPatientManagementPage.ReviewPage.activeTrainingTime"),
          result.getActiveTrainingTime()
        );
        tb.addResult(
          tr("TherapistPatientManagementPage.ReviewPage.scheduledTrainingTime"),
          result.getDuration()
        );
        tb.addResult(
          tr("TherapistPatientManagementPage.ReviewPage.activeSide"),
          result.translatedActiveSide()
        );
        if (result.showScore) {
          tb.addResult(
            tr("TherapistPatientManagementPage.ReviewPage.score"),
            tr("Common.Units.percentageDisplay", { percentage: result.score })
          );
        }

        // Add compensation percentage and duration if provided
        let compensationResult = result.compensationPercentage();
        if (
          result.compensationMovements.length > 0 ||
          result.compensationDuration > 0 ||
          result.hasCompensationDetails()
        ) {
          tb.addResult(tr("Common.Activity.compensation"), compensationResult);
        }
        if (result.hasCompensationDetails()) {
          for (let compResult of result.compensationFeedbackResults) {
            for (let detail of compResult.details) {
              if (detail.duration > 0) {
                tb.addResult(
                  tr(
                    `TherapistPatientManagementPage.ReviewPage.ActivityReportResult.Compensators.${compResult.id}.${detail.name}`
                  ),
                  detail.translatedDetailDuration()
                );
              }
            }
          }
        }

        if (result.assists && result.assists !== "none") {
          tb.addResult(
            tr(
              "TherapistPatientManagementPage.TherapyPlanPage.TherapyPlan.Activity.assists"
            ),
            result.translatedAssists()
          );
        }
        if (result.challenges && result.challenges !== "none") {
          tb.addResult(
            tr(
              "TherapistPatientManagementPage.TherapyPlanPage.TherapyPlan.Activity.challenges"
            ),
            result.translatedChallenges()
          );
        }

        tb.addLine("");
      }
    }

    // Assessment
    if (this.soapData.assessment.text) {
      tb.addTitle(tr("TherapistPatientManagementPage.ReviewPage.assessments"));
      tb.addLine(this.soapData.assessment.text);
      tb.addLine(this.soapData.assessment.translatedLatestEdit());
    }

    // Plan
    if (this.soapData.plan.text) {
      tb.addTitle(tr("TherapistPatientManagementPage.ReviewPage.plan"));
      tb.addLine(this.soapData.plan.text);
      tb.addLine(this.soapData.plan.translatedLatestEdit());
    }

    // N.B. for patient state
    if (this.showPatientStateInfo(sessionReview)) {
      tb.addLine(
        "\n\n* " +
          tr(
            "TherapistPatientManagementPage.ReviewPage.selfReportedPatientStateExplanation1"
          )
      );
      tb.addLine(
        tr(
          "TherapistPatientManagementPage.ReviewPage.selfReportedPatientStateExplanation2"
        )
      );
      tb.addLine(
        tr(
          "TherapistPatientManagementPage.ReviewPage.selfReportedPatientStateExplanation3"
        )
      );
      tb.addLine(
        tr(
          "TherapistPatientManagementPage.ReviewPage.selfReportedPatientStateExplanation4"
        )
      );
      tb.addLine(
        tr(
          "TherapistPatientManagementPage.ReviewPage.selfReportedPatientStateExplanation5"
        )
      );
    }

    return tb.text;
  }

  // Network functions

  /**
   * Get session report for a specific session id
   * @param {string} sessionId The session id
   */
  static async getSessionReport(sessionId) {
    return await activityApi.get(`session/id/${sessionId}`).then((response) => {
      return new SessionReport(response.data.result);
    });
  }

  static async saveSessionReport(sessionReport) {
    return await activityApi
      .put(`session/id/${sessionReport.id}`, sessionReport)
      .then((response) => {
        return new SessionReport(response.data.result);
      });
  }
}
