<template>
  <div
    class="border-t-6 opacity-100 rounded p-8"
    :class="[containerAccentClasses]"
  >
    <template v-if="messageHasTitle">
      <h2 v-if="options.html" class="dg-title" v-html="messageTitle"></h2>
      <h2 v-else class="dg-title">{{ messageTitle }}</h2>
    </template>
    <div class="mb-4 mt-4" :class="{ 'mt-8': messageHasTitle }">
      <div v-if="options.html" class="dg-content" v-html="messageBody"></div>
      <div v-else class="dg-content">{{ messageBody }}</div>
    </div>
    <br />

    <div class="flex flex-row justify-between">
      <base-button
        @click="handleDismiss"
        v-if="options.showCancelText"
        class="btn-secondary min-w-42"
      >
        {{ options.cancelText }}
      </base-button>
      <!-- Make the 2nd button placed on the right in all cases -->
      <div v-else></div>
      <base-button
        @click="handleProceed"
        class="ms-4 px-8 min-w-42"
        :class="[buttonAccentClasses]"
      >
        {{ options.okText }}
      </base-button>
    </div>
  </div>
</template>

<script>
import DialogMixin from "vuejs-dialog/dist/vuejs-dialog-mixin.min.js"; // Include mixin

const DialogType = Object.freeze({
  Info: "info",
  Danger: "danger",
});

const AccentClassMap = Object.freeze({
  containerAccentClasses: {
    [DialogType.Info]: "border-blue-500",
    [DialogType.Warning]: "border-yellow-600",
    [DialogType.Danger]: "border-red-700",
  },
  buttonAccentClasses: {
    [DialogType.Info]: "btn-primary",
    [DialogType.Danger]: "btn-danger",
  },
});

export default {
  mixins: [DialogMixin],
  computed: {
    containerAccentClasses() {
      return AccentClassMap.containerAccentClasses[
        this.options.accent || DialogType.Info
      ];
    },
    buttonAccentClasses() {
      return AccentClassMap.buttonAccentClasses[
        this.options.accent || DialogType.Info
      ];
    },
  },
  methods: {
    handleProceed() {
      this.proceed(); // included in DialogMixin
    },
    handleDismiss() {
      this.cancel(); // included in DialogMixin
    },
  },
};
</script>

<style>
/* Resets to use tailwind for controlling the styles we need */
.dg-backdrop {
  display: none;
}
.dg-main-content {
  padding: 0;
  max-width: 550px; /* modal-sm */
}
</style>
