export default [
  {
    path: "archived-users",
    component: () =>
      import("@/pages/EntityManagementPages/ArchivedUsersManagementPages"),
  },
  {
    path: "archived-users/patient/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientManagementPages/PatientDetailsPage"
      ),
    props: true,
  },
  {
    path: "archived-users/therapist/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/TherapistManagementPages/TherapistDetailsPage"
      ),
    props: true,
  },
  {
    path: "archived-users/site-manager/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteManagerManagementPages/SiteManagerDetailsPage"
      ),
    props: true,
  },
  {
    path: "archived-users/dpoadmin/:username",
    component: () =>
      import("@/pages/EntityManagementPages/DPOManagementPages/DPODetailsPage"),
    props: true,
  },
  {
    path: "archived-users/administrator/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/AdministratorManagementPages/AdministratorDetailsPage"
      ),
    props: true,
  },
];
