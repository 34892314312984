import { getSupportRoutes } from "@/router/supportRoutes";

const routePrefix = "/patient";

export default [
  {
    path: routePrefix,
    component: () => import("@/pages/PatientPages/PatientHomePage"),
    meta: {
      requiresPatient: true,
    },
    children: [
      ...getSupportRoutes(routePrefix),
      {
        path: "",
        name: "Patient/Home",
        redirect: "/patient/profile",
        component: () => import("@/pages/PatientPages/PatientHomePage/index"),
      },
      {
        path: "/patient/profile",
        name: "patient-profile",
        component: () =>
          import("@/pages/PatientPages/PatientHomePage/PatientProfile"),
      },
    ],
  },
  // Teleconsultation part
  {
    path: "/my-meetings/:meetingId",
    name: "Patient/Meeting",
    component: () => import("@/pages/PatientPages/PatientConferencePage"),
    props: (route) => ({ meetingId: route.params.meetingId }),
    meta: {
      requiresPatient: true,
    },
  },
];
