import { Model } from "@vuex-orm/core";
import i18n from "@/i18n";
import store from "@/store";
import { isEntityContainsAllTerms, tidySearchTerm } from "@/utils/filters";

export const defaultTimezoneId = "UTC";

export default class Timezone extends Model {
  static entity = "timezones";
  // Uses one single fetching instance for timezones
  static fetchingInstance = null;

  static fields() {
    return {
      id: this.attr(null),
      displayName: this.attr(null),
      baseUtcOffset: this.attr(null),
      supportsDaylightSavingTime: this.attr(null),
    };
  }

  /**
   * @return The displayName or the id if display name is empty.
   */
  get name() {
    return this.displayName || this.id;
  }

  /**
   * @return Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    return [tidySearchTerm(this.id), tidySearchTerm(this.displayName)];
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }

  /**
   * Fetches timezones from the server.
   * @param {AbortSignal} signal
   * @returns axios response schema with data: { result: [timezones] }
   */
  static async fetchTimezonesAsync(signal) {
    const currentLanguage = store.getters["Language/language"];
    if (this.fetchingInstance !== null) {
      return await this.fetchingInstance;
    }
    this.fetchingInstance = this.api()
      .get("/timezone", { dataKey: "result", signal })
      .finally(() => {
        this.fetchingInstance = null;
      });
    return await this.fetchingInstance;
  }

  /**
   * Returns timezones from the store and fetches them if they're not there.
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async getAllTimezones(signal) {
    let timezones = Timezone.all();

    // Verify if the last time we fetched data, it was in the same language
    // Also sometimes it has 1 or 2 time zones only, then force to fetch them all.
    if (timezones.length < 10) {
      return await this.fetchTimezonesAsync(signal).then(() => {
        return Timezone.all();
      });
    }

    return timezones;
  }

  /**
   *  Returns timezones as objects from the store or fetches them if they're not there.
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async getTimezonesList(signal) {
    return await Timezone.getAllTimezones(signal).then((timezones) => {
      let entries = {};
      // Adds not set value
      entries["undefined"] = new Timezone({
        id: "",
        displayName: i18n.t("Common.notSet"),
      });

      // Appends all the timezones
      timezones.forEach((tz) => {
        entries[tz.id] = tz;
      });

      return entries;
    });
  }

  /**
   * Returns a valid timezone info for a profiles.
   * @param {string} username The username
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async getTimeZoneInfoFromUsername(username, signal) {
    return await this.api()
      .get(`profile/${username}/timezone`, {
        dataKey: "result",
        signal,
      })
      .then((response) => {
        return response.response.data.result;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
