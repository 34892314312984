<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg">
    <title v-if="title">{{ title }}</title>
    <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
  </svg>
</template>

<script>
// @vuese
// component to show an icon
// @group 3. UI/Base
import { getIconPath } from "@/utils/icon";

export default {
  name: "svg-icon",

  props: {
    name: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: null,
    },
  },

  computed: {
    iconPath() {
      return getIconPath(this.name);
    },
    className() {
      return "svg-icon svg-icon--" + this.name;
    },
  },
};
</script>

<style lang="postcss">
.svg-icon {
  height: 24px;
  width: 24px;

  fill: currentColor;
  transition: color 0.3s;
  outline: none;

  &.icon-xs {
    height: 10px;
    width: 10px;
  }

  &.icon-sm {
    height: 12px;
    width: 12px;
  }

  &.icon-md {
    height: 16px;
    width: 16px;
  }

  &.icon-lg {
    height: 20px;
    width: 20px;
  }

  &.icon-xl {
    height: 24px;
    width: 24px;
  }

  &.icon-2xl {
    height: 28px;
    width: 28px;
  }

  &.icon-3xl {
    height: 32px;
    width: 32px;
  }

  &.icon-4xl {
    height: 36px;
    width: 36px;
  }

  &.icon-5xl {
    height: 40px;
    width: 40px;
  }

  &.icon-6xl {
    height: 50px;
    width: 50px;
  }
  &.icon-7xl {
    height: 64px;
    width: 64px;
  }
  &.icon-7-5xl {
    height: 82px;
    width: 82px;
  }
  &.icon-8xl {
    height: 128px;
    width: 128px;
  }
  &.icon-9xl {
    height: 256px;
    width: 256px;
  }
  &.icon-10xl {
    height: 320px;
    width: 320px;
  }
}
</style>
