<template>
  <svg-icon name="loading" class="loading-icon" :class="iconClass" />
</template>

<script>
export default {
  props: {
    iconClass: {
      type: String,
      default: "",
    },
  },
};
</script>

<style type="postcss" scoped>
@keyframes rotate {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.loading-icon {
  animation: rotate 2s infinite linear;
}
</style>
