export default [
  {
    path: "optional-feature-definition",
    component: () =>
      import(
        "@/pages/EntityManagementPages/OptionalFeatureManagementPages/FeatureDefinitionListPage"
      ),
  },
  {
    path: "optional-feature-definition/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/OptionalFeatureManagementPages/FeatureDefinitionCreatePage"
      ),
  },
  {
    path: "optional-feature-definition/:featureId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/OptionalFeatureManagementPages/FeatureDefinitionDetailsPage"
      ),
    props: (route) => ({ featureId: route.params.featureId }),
  },
];
