<template>
  <notifications
    class="min-w-128"
    :group="toastOptions.group"
    :position="toastOptions.position"
    :max="toastOptions.max"
    :duration="toastOptions.duration"
    :closeOnClick="toastOptions.closeOnClick"
    :ignoreDuplicates="toastOptions.ignoreDuplicates"
  >
    <template #body="{ item, close }">
      <warning-box
        :type="item.type"
        class="mt-3"
        :class="{ 'cursor-pointer': item.data?.showCloseButton || false }"
        show-right-slot
        @click="close"
      >
        <div class="flex flex-col">
          <div v-if="item.title" class="font-bold">
            {{ item.title }}
          </div>
          <div class="w-full">
            <div v-if="Array.isArray(item.text)" class="flex flex-col">
              <div v-for="(text, key) in item.text" :key="key">
                <li class="list-inside">{{ text }}</li>
              </div>
            </div>
            <span v-else>{{ item.text }}</span>
          </div>
        </div>
        <template v-if="item.data?.showCloseButton" #rightPart>
          <svg-icon name="close" class="icon-xs" @click="close" />
        </template>
      </warning-box>
    </template>
  </notifications>
</template>

<script>
import WarningBox from "@/components/ui/WarningBox.vue";

// See documentation: https://github.com/euvl/vue-notification#readme
// You can use $notify({
//   text: "text",
//   duration: -1,
//   data: { showCloseButton: true },
// });
// to make a notification stay on screen until clicked.
const ToastOptions = Object.freeze({
  group: "", // Default group
  position: "top center",
  max: 5, // Avoid overwhelming the screen
  duration: 3000, // ms
  ignoreDuplicates: true, // Avoids displaying the same message twice, doesn't work with arrays
  closeOnClick: false, // Disappears automatically
});

export default {
  components: { WarningBox },
  computed: {
    toastOptions() {
      return ToastOptions;
    },
  },
};
</script>
