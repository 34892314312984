/**
 * Return the profile routes with the provided route prefix.
 * E.g: /superadmin -> /superadmin/profile.
 * This permits to have common components displayed in different layouts
 * without breaking route paths.
 * @param routePrefix {string}
 * @return {[*]}
 */
export function getProfileRoutes(routePrefix) {
  return [
    {
      path: `${routePrefix}/profile`,
      component: () => import("@/pages/CommonPages/ProfilePage/index.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ];
}
