<template>
  <label class="inline-flex items-start" :class="{ 'text-disabled': disabled }">
    <input
      type="checkbox"
      :checked="value"
      @change="change"
      :disabled="disabled || isReadOnly"
      ref="indeterminate"
      class="form-checkbox me-3"
      :class="checkBoxClasses"
    />
    <slot></slot>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
    },
    checkBoxClasses: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    indeterminate: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change() {
      this.$emit("change");
      this.$emit("input", !this.value);
    },
  },
  /**
   * Make the checkbox indeterminate
   */
  mounted() {
    if (this.indeterminate) {
      this.$refs.indeterminate.indeterminate = true;
    }
  },
};
</script>
