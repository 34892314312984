/**
 * A simple text builder class helper to create slightly formatted text easily.
 */
export default class TextBuilder {
  constructor() {
    this.text = "";
    this.eol = this.getEol();
  }

  /**
   * Adds a line (with a new line)
   * @param text
   */
  addLine(text) {
    this.text += text + this.eol;
  }

  /**
   * Appends text to the current state
   * @param text
   */
  append(text) {
    this.text += text;
  }

  /**
   * Adds a result formatted line
   * e.g.: resultName<tab>resultValue
   * @param resultName
   * @param resultValue
   */
  addResult(resultName, resultValue) {
    this.text += "\t" + resultName + "\t\t\t" + resultValue + this.eol;
  }

  /**
   * Adds a title formatted line
   * @param title
   */
  addTitle(title) {
    this.text += this.eol + title + this.eol;
  }

  /**
   * Adds a subtitle formatted line
   * @param subtitle
   */
  addSubTitle(subtitle) {
    this.text += subtitle + this.eol;
  }

  /**
   * Returns the right end of line character depending on the platform
   * @return {string}
   */
  getEol() {
    let myPlatform = navigator.platform.toLowerCase();
    if (myPlatform.indexOf("win") != -1) {
      // Windows
      return "\r\n";
    } else if (myPlatform.indexOf("mac") != -1) {
      // OSX
      return "\r";
    }
    return "\n"; // The rest
  }
}
