import { Model } from "@vuex-orm/core";

export const RECENT_PATIENTS = "recentPatients"; // key for personal settings
export const MAX_RECENT_PATIENTS = 6; // Max number of recent patients, add 1 to the desired number

export default class PersonalSettings extends Model {
  static entity = "personalSettings";
  static primaryKey = "key";

  static fields() {
    return {
      key: this.attr(null),
      settings: this.attr({}),
    };
  }

  /**
   * Get personal settings by key
   * @param {string} key personal settings key (ex: PatientList)
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async getPersonalSettings(key, signal) {
    return await this.api().get(`personal-settings/${key}`, {
      dataKey: "result",
      signal,
    });
  }

  /**
   * Update the given personal setting
   * @param {string} key personal settings key (ex: PatientList)
   * @param {object} settings the settings
   */
  static async updatePersonalSettings(key, settings) {
    let personalSettings = {
      key: key,
      settings: settings,
    };
    return await this.api().put(`personal-settings`, personalSettings, {
      dataKey: "result",
    });
  }
}
