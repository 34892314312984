export default [
  {
    path: "patient",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientManagementPages/PatientListPage"
      ),
  },
  {
    path: "patient/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientManagementPages/PatientDetailsPage"
      ),
    props: true,
  },
];
