import customerRoutes from "@/router/entities/customer";

export default [
  {
    path: "site-management",
    component: () =>
      import("@/pages/EntityManagementPages/SiteManagementPages/SiteListPage"),
  },
  {
    path: "site-management/:siteId/:tab",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteManagementPages/SiteDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      tab: route.params.tab,
    }),
    children: [...customerRoutes],
  },
];
