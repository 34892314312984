/**
 *  Root script of the plugin, in charge of being loaded inside vue and handling the interface between the functionalities and the components
 *  Later on this might contain references to the components as well
 *
 */

import VidyoStore from "./vidyo-store";

import { injectVidyoLib, unloadVidyoLib } from "@/plugins/vidyo/injector";
import {
  StartVidyoConnector,
  join,
  leave,
  connectorDisconnected,
  vidyoConnector,
  setSpeaker,
  setMicrophone,
  setCamera,
  selectSharing,
} from "@/plugins/vidyo/conference";
import {
  storeName,
  connectionStatus,
  libraryStates,
} from "@/plugins/vidyo/constants";

const Vidyo = {
  storeName: storeName,
  install: function (Vue, options) {
    if (!options.store) {
      console.log("Vidyo plugin requires a reference to the store");
      return;
    }

    const Store = options.store;
    Store.registerModule(storeName, VidyoStore);
    let Vidyo = {
      connected: false,
      /*
       * Conference call control functions
       *  */
      async setMicrophoneState(state) {
        if (!vidyoConnector) {
          return;
        }
        return vidyoConnector
          .SetMicrophonePrivacy({
            privacy: !state,
          })
          .then(() => {
            Store.dispatch(`${storeName}/setMicrophoneState`, state);
          });
      },
      async setSpeakerState(state) {
        if (!vidyoConnector) {
          return;
        }
        return vidyoConnector
          .SetSpeakerPrivacy({
            privacy: !state,
          })
          .then(() => {
            Store.dispatch(`${storeName}/setSpeakerState`, state);
          });
      },
      async setCameraState(state) {
        if (!vidyoConnector) {
          return;
        }
        return vidyoConnector
          .SetCameraPrivacy({
            privacy: !state,
          })
          .then(() => {
            Store.dispatch(`${storeName}/setCameraState`, state);
          });
      },
      async setCamera(camera) {
        if (camera == null) {
          return this.setCameraState(false);
        }
        this.setCameraState(true);
        return setCamera(camera);
      },
      async setMicrophone(microphone) {
        if (microphone == null) {
          return this.setMicrophoneState(false);
        }
        this.setMicrophoneState(true);

        return setMicrophone(microphone);
      },
      async setSpeaker(speaker) {
        if (speaker == null) {
          return this.setSpeakerState(false);
        }
        this.setSpeakerState(true);
        return setSpeaker(speaker);
      },
      async selectSharing() {
        return selectSharing();
      },
      async enableDebug() {
        vidyoConnector
          .EnableDebug({
            port: 7776,
            logFilter: "warning info@VidyoClient info@VidyoConnector",
          })
          .then(function () {
            console.log("EnableDebug success");
          })
          .catch(function () {
            console.error("EnableDebug failed");
          });
      },
      async join(host, roomKey, roomPin, displayName, resourceId, onFailure) {
        Store.dispatch(
          `${storeName}/setConnectionState`,
          connectionStatus.connecting,
          ""
        );
        Store.dispatch(`${storeName}/clearParticipants`);
        return join(
          vidyoConnector,
          host,
          roomKey,
          roomPin,
          displayName,
          resourceId,
          () => {
            Store.dispatch(
              `${storeName}/setConnectionState`,
              connectionStatus.connected,
              ""
            );
          },
          (reason) => {
            Store.dispatch(
              `${storeName}/setConnectionState`,
              connectionStatus.failed,
              reason
            );
            onFailure();
          },
          (reason) => {
            Store.dispatch(
              `${storeName}/setConnectionState`,
              connectionStatus.disconnected,
              reason
            );
          }
        );
      },
      async leave() {
        Store.dispatch(
          `${storeName}/setConnectionState`,
          connectionStatus.disconnecting,
          ""
        );
        return leave();
      },
    };

    let platformInfo = {};
    let webrtcInitializeAttempts = 0;
    let participantsChangeCb = null;
    let vidyoReadyCb = null;
    Vidyo.initVidyo = async function (
      vidyoReadyCallback,
      participantsChangeCallback
    ) {
      Store.dispatch(`${storeName}/setLoading`, true);
      platformInfo = loadPlatformInfo();
      vidyoReadyCb = vidyoReadyCallback;
      participantsChangeCb = participantsChangeCallback;
      return injectVidyoLib().then(() => {
        return window.onVidyoClientLoaded({
          state: "READY",
          description: "Native SCIP + WebRTC",
        });
        return;
      });
    };

    Vidyo.unload = () => {
      connectorDisconnected(vidyoConnector);
      unloadVidyoLib().then(() => {
        Store.dispatch(`${storeName}/clearStore`);
      });
    };

    Vue.Vidyo = Vue.prototype.$Vidyo = Vidyo;

    // Injects the callback function to handle vidyo loading
    window.onVidyoClientLoaded = (status) => {
      console.log(
        "Status: " + status.state + " / Description: " + status.description
      );
      Store.dispatch(`${storeName}/setLibStatus`, status);
      switch (status.state) {
        case libraryStates.ready: // The library is operating normally
          Store.dispatch(`${storeName}/setLoading`, false);
          window.VC = new window.VidyoClientLib.VidyoClient("", () => {
            // After the VidyoClient is successfully initialized a global VC object will become available
            // All of the VidyoConnector gui and logic is implemented in VidyoConnector.js
            StartVidyoConnector(VC, Store, platformInfo, {
              vidyoReadyCb: vidyoReadyCb,
              participantsChangeCb: participantsChangeCb,
            });
          });
          break;
        case libraryStates.retrying: // The library operating is temporarily paused
          break;
        case libraryStates.failed: // The library operating has stopped
          // If WebRTC initialization failed, try again up to 3 times.
          if (
            status.description.indexOf(
              "Could not initialize WebRTC transport"
            ) > -1 &&
            webrtcInitializeAttempts < 3
          ) {
            ++webrtcInitializeAttempts;
          } else {
            Store.dispatch(`${storeName}/setLoading`, false);
            console.log("Failed to load the library");
          }
          break;
        case libraryStates.failedVersion: // The library operating has stopped
          console.log("We should show a link to download a new version");
          console.log("Failed because of version");
          Store.dispatch(`${storeName}/setLoading`, false);
          break;
        case libraryStates.notAvailable: // The library is not available
          console.log("Show that the library is not available");
          Store.dispatch(`${storeName}/setLoading`, false);
          break;
        case libraryStates.timedout: // Transcoding Inactivity Timeout
          console.log("Show that there was a timeout");
          Store.dispatch(`${storeName}/setLoading`, false);
          break;
      }
      return true; // Return true to reload the plugins if not available
    };
  },
};

function loadPlatformInfo() {
  let platformInfo = {};
  let userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Opera 8.0+
  platformInfo.isOpera =
    userAgent.indexOf("Opera") != -1 || userAgent.indexOf("OPR") != -1;
  // Firefox
  platformInfo.isFirefox =
    userAgent.indexOf("Firefox") != -1 || userAgent.indexOf("FxiOS") != -1;
  // Chrome 1+
  platformInfo.isChrome =
    userAgent.indexOf("Chrome") != -1 || userAgent.indexOf("CriOS") != -1;
  // Safari
  platformInfo.isSafari =
    !platformInfo.isFirefox &&
    !platformInfo.isChrome &&
    userAgent.indexOf("Safari") != -1;
  // AppleWebKit
  platformInfo.isAppleWebKit =
    !platformInfo.isSafari &&
    !platformInfo.isFirefox &&
    !platformInfo.isChrome &&
    userAgent.indexOf("AppleWebKit") != -1;
  // Internet Explorer 6-11
  platformInfo.isIE =
    userAgent.indexOf("MSIE") != -1 || !!document.documentMode == true;
  // Edge 20+
  platformInfo.isEdge = !platformInfo.isIE && !!window.StyleMedia;
  // Check if Mac
  platformInfo.isMac = navigator.platform.indexOf("Mac") > -1;
  // Check if Windows
  platformInfo.isWin = navigator.platform.indexOf("Win") > -1;
  // Check if Linux
  platformInfo.isLinux = navigator.platform.indexOf("Linux") > -1;
  // Check if iOS
  platformInfo.isiOS =
    userAgent.indexOf("iPad") != -1 || userAgent.indexOf("iPhone") != -1;
  // Check if Android
  platformInfo.isAndroid = userAgent.indexOf("android") > -1;
  // Check if Electron
  platformInfo.isElectron =
    typeof process === "object" &&
    process.versions &&
    process.versions.electron !== undefined;
  // Check if WebRTC is available
  platformInfo.isWebRTCAvailable =
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    (navigator.mediaDevices ? navigator.mediaDevices.getUserMedia : undefined)
      ? true
      : false;
  // Check if 64bit
  platformInfo.is64bit =
    navigator.userAgent.indexOf("WOW64") > -1 ||
    navigator.userAgent.indexOf("Win64") > -1 ||
    window.navigator.platform == "Win64";
  return platformInfo;
}

export default Vidyo;
