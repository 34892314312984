/**
 * Return the support routes with the provided route prefix.
 * E.g: /superadmin -> /superadmin/support/contact.
 * This permits to have common components displayed in different layouts
 * without breaking route paths.
 * @param routePrefix {string}
 * @return {[*]}
 */
export function getSupportRoutes(routePrefix) {
  return [
    {
      path: `${routePrefix}/support/contact-us`,
      component: () => import("@/pages/CommonPages/SupportPage/ContactUs.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: `${routePrefix}/support/faq`,
      component: () =>
        import("@/pages/CommonPages/SupportPage/FrequentlyAskedQuestions.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: `${routePrefix}/support/ifu`,
      component: () =>
        import("@/pages/CommonPages/SupportPage/InstructionsForUse.vue"),
      meta: {
        requiresAuth: true,
      },
    },
  ];
}
