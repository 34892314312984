/**
 * This file contains all the supported locales and languages
 * Locales are the specific language and country combination
 * Full migration in Locales from 2 letter language code to 2 letter language code + 2 letter country code
 * is not done due to the lack of support in MMGO
 * Languages are the language codes only
 */

/**
 * Supported Languages
 * Used for translations
 */
export const defaultLanguage = "en";
export const supportedLanguages = ["en", "de", "fr", "es", "it", "ro", "ar"];

/**
 * Supported Locales
 * Used in profiles
 */
export const supportedLocales = [
  "en-US",
  "en-GB",
  "de",
  "fr",
  "es",
  "it",
  "ro",
  "ar",
];
export const defaultLocale = "en-US";

const localesInfo = Object.freeze({
  en: {
    default: {
      root: "en",
      short: "en",
      long: "English",
      flagIso3166: "us",
    },
    "en-US": {
      root: "en",
      short: "en-US",
      long: "American English",
      flagIso3166: "us",
    },
    "en-GB": {
      root: "en",
      short: "en-GB",
      long: "British English",
      flagIso3166: "gb",
    },
  },
  de: {
    default: {
      root: "de",
      short: "de",
      long: "Deutsch",
      flagIso3166: "de",
    },
  },
  fr: {
    default: {
      root: "fr",
      short: "fr",
      long: "Français",
      flagIso3166: "fr",
    },
  },
  es: {
    default: {
      root: "es",
      short: "es",
      long: "Español",
      flagIso3166: "es",
    },
  },
  it: {
    default: {
      root: "it",
      short: "it",
      long: "Italiano",
      flagIso3166: "it",
    },
  },
  ro: {
    default: {
      root: "ro",
      short: "ro",
      long: "Română",
      flagIso3166: "ro",
    },
  },
  ar: {
    default: {
      root: "ar",
      short: "ar",
      long: "العربية",
      flagIso3166: "ar-SA",
    },
  },
});

/**
 * Returns Array of supported Locales without default entries
 */
const getLocalesSkipDefaults = (group) => {
  return Object.entries(group).reduce((locales, [localeKey, localeInfo]) => {
    if (localeKey !== "default") {
      const entry = {
        short: localeInfo.short,
        root: localeInfo.root,
        long: localeInfo.long,
        flagIso3166: localeInfo.flagIso3166,
      };
      locales.push(entry);
    }
    return locales;
  }, []);
};

/**
 * Returns Array of supported Locale entries of the format
 * [{root:"en", short: "en-US", long: "American English", flagIso3166: "us"}, ...]
 */
export const supportedLocaleEntries = Object.values(localesInfo).reduce(
  (locales, group) => {
    if (!group.default || Object.keys(group).length > 1) {
      const localesWithoutDefaults = getLocalesSkipDefaults(group);
      locales.push(...localesWithoutDefaults);
    } else {
      const defaultGroup = Object.values(group)[0];
      if (defaultGroup) {
        const entry = {
          short: defaultGroup.short,
          root: defaultGroup.root,
          long: defaultGroup.long,
          flagIso3166: defaultGroup.flagIso3166,
        };
        locales.push(entry);
      }
    }
    return locales;
  },
  []
);

/**
 * Returns Array of supported Language entries of the format
 * [{root:"en", short: "en", long: "English", flagIso3166: "us"}, ...]
 */
export const supportedLanguageEntries = Object.entries(localesInfo).reduce(
  (supportedLocales, [key, localeInfo]) => {
    if (localeInfo.default) {
      supportedLocales.push({
        ...localeInfo.default,
        key: key,
      });
    }
    return supportedLocales;
  },
  []
);

/**
 * Return supported locale from given string
 * "en" -> "en-US"
 */
export const getLocaleFrom = (input) => {
  if (!input) {
    return defaultLocale;
  }

  for (const info of supportedLocaleEntries) {
    if (info.short.toLowerCase() === input.toLowerCase()) {
      return info.short;
    }
  }

  for (const info of supportedLocaleEntries) {
    if (info.root === input.substring(0, 2).toLowerCase()) {
      return info.short;
    }
  }

  return defaultLocale;
};

/**
 * Returns 2 letter language code with format xx corresponding to provided language
 * "en-US" -> "en"
 */
export const getLanguageCodeFrom = (input) => {
  if (!input) {
    return defaultLanguage;
  }

  for (const info of supportedLocaleEntries) {
    if (info.root === input.toLowerCase()) {
      return info.root;
    }

    for (const info of supportedLocaleEntries) {
      if (info.root === input.substring(0, 2).toLowerCase()) {
        return info.root;
      }
    }
  }
  return defaultLanguage;
};

/**
 * Returns supported locale entry from given string
 */
export const getLocaleEntryFrom = (input) => {
  const locale = getLocaleFrom(input);
  return supportedLocaleEntries.find((entry) => entry.short === locale);
};
