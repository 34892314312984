/**
 * Clamps a number between two values
 * @param {number} value to clamp
 * @param {number} min value
 * @param {number} max value
 */
function clamp(value, min, max) {
  if (value < min) return min;
  if (value > max) return max;
  return value;
}

/**
 * Implements the Bankers Rounding : https://en.wikipedia.org/wiki/Rounding#Round_half_to_even
 * Implementation from https://stackoverflow.com/questions/46470724/how-to-apply-c-sharp-equivalent-rounding-method-in-javascript
 *
 * @param {number} num
 * @param {number} decimalPlaces
 * @returns {number} the bankers rounding value of 'num' taking 'decimals' into account
 */
function evenRound(num, decimals) {
  const d = decimals || 0;
  const m = Math.pow(10, d);
  const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n);
  const f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  const r =
    f > 0.5 - e && f < 0.5 + e ? (i % 2 === 0 ? i : i + 1) : Math.round(n);
  return d ? r / m : r;
}

export { clamp, evenRound };
