<template>
  <div
    v-if="!isButtonType"
    v-wave
    @click="onClick"
    :disabled="disabled"
    :class="{ 'cursor-not-allowed': disabled }"
  >
    <slot></slot>
  </div>
  <button v-else v-wave :disabled="disabled" @click="onClick">
    <slot></slot>
  </button>
</template>

<script>
export default {
  props: {
    isButtonType: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClick(event) {
      this.$emit("click", event);
    },
  },
};
</script>
