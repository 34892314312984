<template>
  <transition appear name="slideFromBottom">
    <div
      v-if="isOpen"
      class="grid grid-cols-2 gap-4 lg:grid-cols-7 items-center cookie-bar-position z-cookie-bar w-9/10 p-8 shadow-lg bg-gray-100 rounded"
    >
      <div class="col-span-5">
        {{ $t("App.CookieBar.cookieMessage") }}
      </div>

      <button
        class="text-white bg-blue-500 hover:text-white hover:bg-blue-750"
        @click="accept"
      >
        {{ $t("App.CookieBar.acceptText") }}
      </button>
      <button
        class="text-white bg-blue-500 hover:text-white hover:bg-blue-750"
        @click="decline"
      >
        {{ $t("App.CookieBar.declineText") }}
      </button>
    </div>
  </transition>
</template>

<script>
import {
  acceptCookies,
  declineCookies,
  areCookiesAccepted,
} from "@/utils/cookies";
import { getLocalStorageStayLoggedIn } from "@/store/localStorage";
import { mapActions, mapState } from "vuex";

export default {
  mounted() {
    this.init();
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapState("Auth", ["token", "refreshToken", "deviceId"]),
  },
  methods: {
    ...mapActions("Auth", [
      "setAccessToken",
      "setCookiesAccepted",
      "setRefreshToken",
      "setDeviceId",
      "setStayLoggedIn",
    ]),
    init() {
      this.isOpen = !areCookiesAccepted();
      this.refreshStoreCookiesAcceptedState();
    },
    refreshStoreCookiesAcceptedState() {
      const cookiesAccepted = areCookiesAccepted();
      // Refresh cookies acceptance state
      this.setCookiesAccepted(cookiesAccepted);
      if (cookiesAccepted) {
        // Restore the stay logged in checkbox state if the cookies are accepted
        const stayLoggedIn = getLocalStorageStayLoggedIn();
        this.setStayLoggedIn(stayLoggedIn);
      }
    },
    saveAuthDataInCookiesIfAvailable() {
      // Only store a usable set of data
      if (this.token && this.refreshToken && this.deviceId) {
        this.setAccessToken(this.token);
        this.setRefreshToken(this.refreshToken);
        this.setDeviceId(this.deviceId);
      }
    },
    accept() {
      acceptCookies();
      this.refreshStoreCookiesAcceptedState();
      this.saveAuthDataInCookiesIfAvailable();
      this.isOpen = false;
    },
    decline() {
      declineCookies();
      this.refreshStoreCookiesAcceptedState();
      this.isOpen = false;
    },
  },
};
</script>

<style scoped lang="postcss">
.cookie-bar-position {
  position: absolute;
  left: 50%;
  bottom: 0px;
  transform: translate(-50%, -50%);
}

.slideFromBottom-enter {
  transform: translate(-50%, 100%);
}

.slideFromBottom-leave-to {
  transform: translate(-50%, 100%);
}

.slideFromBottom-enter-active {
  transition: transform 0.2s ease-out;
}
.slideFromBottom-leave-active {
  transition: transform 0.2s ease-in;
}
</style>
