export default [
  {
    path: "optional-feature-activation",
    component: () =>
      import(
        "@/pages/EntityManagementPages/OptionalFeatureManagementPages/FeatureActivationListPage"
      ),
  },
  {
    path: "optional-feature-activation/:featureKeyName",
    component: () =>
      import(
        "@/pages/EntityManagementPages/OptionalFeatureManagementPages/FeatureActivationDetailsPage"
      ),
    props: (route) => ({ featureKeyName: route.params.featureKeyName }),
  },
];
