// Wrapper used to easily set entity rules using casl management rules.

// The right types existing in Casl
const RightTypes = Object.freeze({
  Manage: "manage",
  Create: "create",
  Read: "read",
  Edit: "edit",
  Delete: "delete",
});
// Casl based ruleset usable as a bitmask
const Rights = Object.freeze({
  CanDoNothing: 0x00000,
  CanManage: 0x00001,
  CanCreate: 0x00010,
  CanRead: 0x00100,
  CanEdit: 0x01000,
  CanDelete: 0x10000,
  CanDoEverything: 0x11111,
});
let permissions = {};

/**
 * @param abilityBuilder {AbilityBuilder} // Casl AbilityBuilder()
 * @param entity {string} // Database or Vuex-orm entity
 * @param access {Number} // Rights enum bitmask value
 */
const addEntityRules = (abilityBuilder, entity, access) => {
  // Initialize with Rights.CanDoNothing if the entity has no access yet
  permissions[entity] = permissions[entity] || Rights.CanDoNothing;
  // Add access for the entity
  permissions[entity] |= access;

  const entityRuleSet = {
    // ruleName: true or false,
    [RightTypes.Manage]: !!(permissions[entity] & Rights.CanManage),
    [RightTypes.Create]: !!(permissions[entity] & Rights.CanCreate),
    [RightTypes.Read]: !!(permissions[entity] & Rights.CanRead),
    [RightTypes.Edit]: !!(permissions[entity] & Rights.CanEdit),
    [RightTypes.Delete]: !!(permissions[entity] & Rights.CanDelete),
  };

  for (const accessType in entityRuleSet) {
    let isAccessTypeGranted = entityRuleSet[accessType];

    if (isAccessTypeGranted) {
      abilityBuilder.can(accessType, entity);
    } else {
      abilityBuilder.cannot(accessType, entity);
    }
  }
};
const resetEntitiesAccess = () => {
  permissions = {};
};

export { addEntityRules, resetEntitiesAccess, RightTypes, Rights };
