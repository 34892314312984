import { Model } from "@vuex-orm/core";
import Site from "@/models/Site.model";
import OptionalFeatureDefinition from "@/models/OptionalFeatureDefinition.model";
import { parseISO } from "date-fns";
import { cloneDeep } from "lodash";
import { tr } from "@/utils/translation";

export default class OptionalFeatureActivation extends Model {
  static entity = "optionalFeatureActivations";

  static fields() {
    return {
      id: this.attr(null),
      addedAtUtc: this.attr(null),
      featureKeyName: this.attr(null),
      featureVersion: this.attr(null),
      siteId: this.attr(null),
      site: this.hasOne(Site, "id", "siteId"),
      // One to many relation to easily catch potential matching definitions
      definitions: this.hasMany(
        OptionalFeatureDefinition,
        "featureKeyName",
        "featureKeyName"
      ),
    };
  }

  get AddedAtUtc() {
    return this.addedAtUtc ? parseISO(this.addedAtUtc) : null;
  }

  // Network methods

  // Wrapper to easily get the feature version for a given feature key name as a promise
  // for regular users: (therapist, patient, site manager)
  static getOwnSiteFeatureVersionFor(featureKeyName) {
    function returnVersion() {
      let featureActivationForKeyName = OptionalFeatureActivation.query()
        .where((f) => f.featureKeyName === featureKeyName)
        .first();
      return featureActivationForKeyName
        ? featureActivationForKeyName.featureVersion
        : null;
    }

    return new Promise((resolve, reject) => {
      let featureActivationList = OptionalFeatureActivation.query().all();
      // Refreshes the list if it wasn't fetched yet
      if (!featureActivationList || !featureActivationList.length) {
        OptionalFeatureActivation.getOwnSiteFeatureActivationList()
          .then(() => {
            resolve(returnVersion());
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        resolve(returnVersion());
      }
    });
  }

  // As a regular user (therapist, patient, site manager)
  static getOwnSiteFeatureActivationList() {
    return this.api().get("/optional-feature-activation/site/own", {
      dataKey: "result",
    });
  }

  // Returns the list of all optional feature definition
  static get() {
    return this.api().get("/optional-feature-activation", {
      dataKey: "result",
    });
  }

  static async post(newFeatureActivation) {
    return this.api().post(
      "/optional-feature-activation",
      newFeatureActivation,
      { dataKey: "result" }
    );
  }

  static async put(updatedFeature) {
    let featureClone = cloneDeep(updatedFeature);
    return OptionalFeatureActivation.remove(updatedFeature).then(() => {
      return OptionalFeatureActivation.post(featureClone);
    });
  }

  static async remove(feature) {
    return this.api().delete(`/optional-feature-activation/${feature.id}`, {
      delete: feature.id,
      dataKey: "result",
    });
  }

  static getFeatureAvailableVersions(featureDefinitionsForKeyName) {
    // Add default entry
    let availableVersions = {
      unset: {
        id: "unset",
        name: tr("AdminPages.OptionalFeatureActivation.noVersionActivated"),
      },
    };
    for (let definition of featureDefinitionsForKeyName) {
      availableVersions[definition.featureVersion] = {
        id: definition.featureVersion,
        name: definition.featureVersion,
      };
    }
    return availableVersions;
  }
}
