import i18n from "@/i18n";
import { Model } from "@vuex-orm/core";
import Site from "@/models/Site.model";
import Profile from "@/models/Profile.model";
import { formatISO9075, parseISO, isSameDay } from "date-fns";
import {
  formatToDDMMM,
  formatToDDMMMYYYYHmm,
  formatToHHmm,
} from "@/utils/date";
import { getMinutesAndSecondsFromSeconds } from "@/utils/duration";
import { notifyError, userApi } from "@/services/api";

export const VideoProvider = Object.freeze({
  Zoom: "Zoom",
  Vidyo: "Vidyo",
});

export default class VideoMeeting extends Model {
  static entity = "videomeetings";

  static fields() {
    return {
      id: this.attr(""), // Primary key
      therapistUsername: this.attr(null),
      therapistDisplayName: this.attr(null),
      patientUsername: this.attr(null),
      siteId: this.attr(null),
      site: this.belongsTo(Site, "siteId"),
      videoProvider: this.attr(VideoProvider.Zoom),
      videoConferencingLink: this.attr(null),
      startDateTimeUTC: this.attr(null),
      endDateTimeUTC: this.attr(null),
      hasBeenAcceptedByPatient: this.attr(false),
      hasBeenDeclinedByPatient: this.attr(false),
      lastPatientUpdateUTC: this.attr(null),
      hasBeenCancelledByTherapist: this.attr(false),
      hasBeenClosedByTherapist: this.attr(false),
      cancellationDateUTC: this.attr(null),
      fromMeetingRequestId: this.attr(null),
      mutualParticipationSeconds: this.attr(null),
      therapist: this.belongsTo(Profile, "therapistUsername", "userName"),
      patient: this.belongsTo(Profile, "patientUsername", "userName"),
    };
  }

  get IsJoinable() {
    return !this.hasBeenDeclinedByPatient && !this.hasBeenCancelledByTherapist;
  }

  get StartDateTimeUTC() {
    return this.startDateTimeUTC ? parseISO(this.startDateTimeUTC) : null;
  }
  set StartDateTimeUTC(value) {
    this.startDateTimeUTC = value.toISOString();
  }

  get EndDateTimeUTC() {
    return this.endDateTimeUTC ? parseISO(this.endDateTimeUTC) : null;
  }
  set EndDateTimeUTC(value) {
    this.endDateTimeUTC = value.toISOString();
  }

  get LastPatientUpdateUTC() {
    return this.lastPatientUpdateUTC
      ? parseISO(this.lastPatientUpdateUTC)
      : null;
  }
  set LastPatientUpdateUTC(value) {
    this.lastPatientUpdateUTC = formatISO9075(value);
  }

  get CancellationDateUTC() {
    return this.cancellationDateUTC ? parseISO(this.cancellationDateUTC) : null;
  }
  set CancellationDateUTC(value) {
    this.cancellationDateUTC = formatISO9075(value);
  }

  get MeetingAttendanceStatus() {
    if (
      this.mutualParticipationSeconds == null ||
      this.mutualParticipationSeconds < 0.1
    ) {
      return i18n.t("TherapistTeleRehabPage.Meeting.didNotOccur");
    }

    const [minutes, seconds] = getMinutesAndSecondsFromSeconds(
      this.mutualParticipationSeconds
    );

    return i18n.t("TherapistTeleRehabPage.Meeting.didOccur", {
      minutes: minutes,
      seconds: seconds,
    });
  }

  get MeetingStatus() {
    // We first check whether the meeting is canceled.
    if (this.hasBeenCancelledByTherapist) {
      if (this.CancellationDateUTC) {
        return i18n.t("ConferencePage.Video.Meeting.therapistCancelledAt", {
          date: formatToDDMMMYYYYHmm(this.CancellationDateUTC),
        });
      }
      return i18n.t("ConferencePage.Video.Meeting.therapistCancelled");
    }

    if (this.hasBeenDeclinedByPatient) {
      if (this.LastPatientUpdateUTC) {
        return i18n.t("ConferencePage.Video.Meeting.patientDeclinedAt", {
          date: formatToDDMMMYYYYHmm(this.LastPatientUpdateUTC),
        });
      }
      return i18n.t("ConferencePage.Video.Meeting.patientDeclined");
    }

    return "";
  }

  get DidOccur() {
    return (
      !this.hasBeenDeclinedByPatient &&
      !this.hasBeenCancelledByTherapist &&
      this.mutualParticipationSeconds >= 0.1
    );
  }

  get DidNotOccur() {
    return (
      this.hasBeenDeclinedByPatient ||
      this.hasBeenCancelledByTherapist ||
      this.mutualParticipationSeconds < 0.1
    );
  }

  /**
   * Returns the formatted meeting duration in the user locale.
   * E.g.: 12:00 - 13:00
   * Indicates the end date if not on the same day (can happen when the meeting was created in another timezone)
   * E.g. of a meeting on the 13 oct: 23:00 - 2:00 (14 oct.)
   * @return {string}
   */
  get durationString() {
    // Indicates the end day if the date is not the same than the beginning date's
    let endDate = isSameDay(this.StartDateTimeUTC, this.EndDateTimeUTC)
      ? ""
      : ` (${formatToDDMMM(this.EndDateTimeUTC)})`;
    return `${formatToHHmm(this.StartDateTimeUTC)} - ${formatToHHmm(
      this.EndDateTimeUTC
    )}${endDate}`;
  }

  /*
   * Methods
   */
  AreParticipantsPresent = (participants) => {
    return (
      participants.includes(this.therapistUsername) &&
      participants.includes(this.patientUsername)
    );
  };

  /*
   * Basic CRUD ORM methods
   */

  /**
   * Get video meetings for current user site
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async getAndRefreshForCurrentUserSite(signal) {
    return await this.api().get(`/videomeeting/site`, {
      dataKey: "result",
      persistBy: "create",
      signal,
    });
  }

  static async getForCurrentUser() {
    return await this.api().get(`/videomeeting/`, {
      dataKey: "result",
    });
  }

  static async getForTherapist(therapistUsername) {
    return await this.api().get(
      `/videomeeting/therapist/${therapistUsername}`,
      {
        dataKey: "result",
      }
    );
  }

  static async getForPatient(patientUsername) {
    return await this.api().get(`/videomeeting/patient/${patientUsername}`, {
      dataKey: "result",
    });
  }

  static async post(newVideoMeeting) {
    return await this.api().post("/videomeeting", newVideoMeeting, {
      dataKey: "result",
    });
  }

  static async put(updatedVideoMeeting) {
    return await this.api().put(
      `/videomeeting/${updatedVideoMeeting.id}`,
      updatedVideoMeeting,
      {
        dataKey: "result",
      }
    );
  }

  static async remove(videoMeeting) {
    return await this.api().delete(`/videomeeting/${videoMeeting.id}`, {
      delete: videoMeeting.id,
      dataKey: "result",
    });
  }

  // Specific operations
  static async cancelVideoMeeting(videoMeeting) {
    return await this.api().post(
      `/videomeeting/${videoMeeting.id}/cancel`,
      null,
      {
        dataKey: "result",
      }
    );
  }

  static async rescheduleVideoMeeting(videoMeeting) {
    return await this.api().post(
      `/videomeeting/${videoMeeting.id}/reschedule`,
      videoMeeting,
      { dataKey: "result" }
    );
  }

  static async getMeetingJoiningInfo(meetingId) {
    return userApi
      .get(`videomeeting/${meetingId}/join-info`)
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        notifyError(error);
        return Promise.reject(error);
      });
  }

  static async logParticipantsChange(meetingId, usernamesList) {
    return userApi
      .post(`videomeeting/${meetingId}/participants`, usernamesList)
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        notifyError(error);
        return Promise.reject(error);
      });
  }

  static async closeMeeting(meetingId) {
    return userApi
      .post(`videomeeting/${meetingId}/close`)
      .then((response) => {
        return response.data.result;
      })
      .catch((error) => {
        notifyError(error);
        return Promise.reject(error);
      });
  }
}
