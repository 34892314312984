export default [
  {
    path: "/zoom/activate",
    name: "ZoomActivate",
    component: () => import("@/pages/CommonPages/ZoomPages/ZoomActivate.vue"),
  },
  {
    path: "/zoom/documentation",
    name: "ZoomDocumentation",
    component: () =>
      import("@/pages/CommonPages/ZoomPages/ZoomDocumentation.vue"),
  },
  // Zoom external URL previously handled by another service
  {
    path: "/zoomverify/documentation",
    name: "ZoomVerifyDocumentation",
    redirect: "/zoom/documentation",
  },
  {
    path: "/zoomverify/privacy-policy-eu",
    name: "ZoomVerifyPrivacyPolicy",
    redirect: "/privacy-policy",
  },
  {
    path: "/zoomverify/support",
    name: "ZoomVerifySupport",
    beforeEnter(to, from, next) {
      window.location.replace("https://mindmaze.com/contact/");
    },
  },
  {
    path: "/zoomverify/terms-of-use",
    name: "ZoomVerifyTermsOfUse",
    beforeEnter(to, from, next) {
      window.location.replace(
        "https://mindmaze.com/wp-content/uploads/2023/10/EULA-General-20230517_EN.pdf"
      );
    },
  },
];
