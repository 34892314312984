import { Model } from "@vuex-orm/core";

export default class AuthOptions extends Model {
  static entity = "authOptions";

  static fields() {
    return {
      lockout: this.attr({}, (value) => new LockoutOptions(value)),
    };
  }

  static get() {
    return this.api().get("/account/auth-options");
  }
}

export class LockoutOptions {
  constructor(options) {
    this.enabled = options.enabled || false;
    this.maxFailedAccessAttempts = options.maxFailedAccessAttempts || 5;
    this.defaultLockoutTimeSpan = options.defaultLockoutTimeSpan || 5;
  }

  timeStringToMinutes(timeString) {
    return timeString.split(":").reduce((acc, time) => 60 * acc + +time) / 60;
  }
}
