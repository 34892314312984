<template>
  <stack
    class="w-full min-h-32 justify-center items-center align-middle text-center bg-white"
  >
    <slot name="text">
      <span class="font-semibold mb-2">
        {{ $t("Common.System.loading") }}
      </span>
    </slot>
    <slot name="icon">
      <loading-icon icon-class="icon-6xl" />
    </slot>
  </stack>
</template>

<script>
import Stack from "@/components/layout/common/Stack.vue";
import LoadingIcon from "@/components/ui/LoadingIcon.vue";

export default {
  components: { LoadingIcon, Stack },
};
</script>
