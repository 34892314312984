import { resetState } from "@/store/utils";

const initialState = () => {
  return {
    /**
     * The user profile of the logged-in user
     */
    user: null,
    /**
     * Whether the current user Zoom access granted to manage their Zoom meetings
     */
    accessGranted: null,
    /**
     * The current user Zoom account type
     */
    accountType: null,
    /**
     * Whenever the zoom feature is activated for user
     */
    zoomFeatureActivated: null,
  };
};

export default {
  namespaced: true,

  state: initialState,

  mutations: {
    setZoomAccess(state, isAccessGranted) {
      state.accessGranted = isAccessGranted;
    },
    setAccountType(state, accountType) {
      state.accountType = accountType;
    },
    setZoomFeatureActivation(state, status) {
      state.zoomFeatureActivated = status;
    },
    clearStore(state) {
      resetState(initialState, state);
    },
  },
  actions: {
    setZoomAccess({ commit }, accessGranted) {
      commit("setZoomAccess", accessGranted);
    },
    setAccountType({ commit }, accountType) {
      commit("setAccountType", accountType);
    },
    setZoomFeatureActivation({ commit }, status) {
      commit("setZoomFeatureActivation", status);
    },
    clearStore({ commit }) {
      commit("clearStore");
    },
  },
  getters: {
    accessGranted(state) {
      return state.accessGranted;
    },
    accountType(state) {
      return state.accountType;
    },
    zoomFeatureActivated(state) {
      return state.zoomFeatureActivated;
    },
  },
};
