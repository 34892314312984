import {
  userLoginApi,
  userRefreshTokenApi,
  notifyWarning,
} from "@/services/api";
import Profile from "@/models/Profile.model";
import UserSession from "@/models/UserSession.model";
import Store from "@/store";
import { updateUserAccessRights } from "@/config/ability";
import { defaultLocale } from "@/utils/locales";
import router from "@/router";
import { generateUUID } from "@/utils/profile";
import { getLocalStorageDeviceId } from "@/store/localStorage";
import { ability } from "@/config/ability";

export const PATIENT_ROLE = "patient";
export const THERAPIST_ROLE = "therapist";
export const SITEMANAGER_ROLE = "site";
export const ADMIN_ROLE = "admin";
const SUPERADMIN_ROLE = "superadmin";
export const DPO_ROLE = "dpoadmin";
const SALESPERSON_ROLE = "salesforcedc";

const DATA_SCIENTIST = "data_scientist";
const ACCOUNT_EXECUTIVE = "account_executive";
const PUBLISHER = "publisher";
const ADMIN_MANAGER = "admin_manager";
const DATA_EXPORT_MANAGER = "data_export_manager";
const OPTIONAL_FEATURE_MANAGER = "optional_feature_manager";

const roles = [
  PATIENT_ROLE,
  THERAPIST_ROLE,
  ADMIN_ROLE,
  SUPERADMIN_ROLE,
  DPO_ROLE,
  SALESPERSON_ROLE,
  SITEMANAGER_ROLE,
];

/**
 * Competences of administrators
 */
const competences = [
  DATA_SCIENTIST,
  ACCOUNT_EXECUTIVE,
  PUBLISHER,
  ADMIN_MANAGER,
  DATA_EXPORT_MANAGER,
  OPTIONAL_FEATURE_MANAGER,
];

/**
 * Perform a login using username and password
 */
const login = async (username, password) =>
  authenticate(username, password, null, "auth/token");

/**
 * Perform a login using username, password and second factor verification code
 */
const loginTwoFactor = async (username, password, secondFactor) =>
  authenticate(username, password, secondFactor, "auth/token-2fa");

const authenticate = async (
  username,
  password,
  secondFactor,
  loginEndpoint
) => {
  const deviceId = await getDeviceId();

  return await userLoginApi
    .post(loginEndpoint, {
      userName: username,
      password: password,
      device: deviceId,
      secondFactor: secondFactor,
    })
    .then((response) => {
      notifyWarning(response);
      return response;
    })
    .then((response) => {
      const result = response.data.result;
      // Forces auth data to be ready before taking any other requests
      return Promise.allSettled([
        Store.dispatch("Auth/setAccessToken", result.token),
        Store.dispatch("Auth/setRefreshToken", result.refreshToken),
      ]).then(() => {
        return setUserInformationAndRights(result.userProfile).then(() => {
          return startCurrentUserSession();
        });
      });
    })
    .catch((error) => {
      // Clear access rights
      updateUserAccessRights();

      return handleLoginRedirection(error);
    });
};

/**
 * Get the application unique device Id if existing and generate it if not
 * This is later used to match with the refresh token
 */
const getDeviceId = async () => {
  let deviceId = Store.state.Auth.deviceId || getLocalStorageDeviceId();
  if (!deviceId) {
    deviceId = `webapp-${generateUUID()}`;
    // Ensure the data is stored for later calls
    await Store.dispatch("Auth/setDeviceId", deviceId);
  }
  return deviceId;
};

const startCurrentUserSession = async () => {
  if (ability.can("create", "UserSession")) {
    const deviceId = await getDeviceId();
    const newUserSession = new UserSession({
      startDate: new Date().toISOString(),
      endDate: null,
      hasBeenPerformedOffline: false,
      deviceId: deviceId,
      deviceSoftwareVersion: navigator.userAgent,
      productKind: "WebApp",
    });
    return await UserSession.post(newUserSession).catch((error) => {
      if (process.env.NODE_ENV === "development") {
        console.error(error);
      }
      return Promise.reject(error);
    });
  } else {
    return Promise.resolve();
  }
};

const attemptAutoLogin = async (autoLoginToken) => {
  return await userLoginApi
    .post(`auth/autologin?token=${autoLoginToken}`)
    .then((response) => {
      const { token, refreshToken, userProfile } = response.data.result;
      return Promise.allSettled([
        Store.dispatch("Auth/setAccessToken", token),
        Store.dispatch("Auth/setRefreshToken", refreshToken),
      ]).then(() => {
        return setUserInformationAndRights(userProfile).then(() => {
          return startCurrentUserSession();
        });
      });
    })
    .catch(() => {
      // Clears stores and token data
      Store.commit("Auth/clearAll");
      // Clear access rights
      updateUserAccessRights();

      return false;
    });
};

const handleLoginRedirection = (error) => {
  if (!error?.response?.data?.errors.length > 0) {
    return Promise.reject(error);
  }
  let postBody = JSON.parse(error.config.data);
  let username = postBody["userName"] || null;
  let password = postBody["password"] || null;

  // redirect if use saml error
  const samlErrorKey = "errors.login.mustUseSAML";

  if (error.response.data.errors.includes(samlErrorKey)) {
    return router
      .push({
        name: "loginsaml",
        params: { samlSiteName: error.response.data.samlSitePortal[0] },
        query: { errorKey: samlErrorKey },
      })
      .catch(() => {});
  }

  // redirect if password expired error
  const passExpiredErrorKey = "errors.login.passwordExpired";
  const passChangeRequiredErrorKey = "errors.login.passwordResetRequired";
  if (
    error.response.data.errors.includes(passExpiredErrorKey) ||
    error.response.data.errors.includes(passChangeRequiredErrorKey)
  ) {
    return router.push({
      name: `resetPassword`,
      query: {
        id: error.response.data.userId,
        token: error.response.data.passwordResetToken,
      },
      params: { title: passChangeRequiredErrorKey, email: username },
    });
  }

  // redirect if two-factor required
  const twoFactorRequiredError = "errors.login.twoFactorRequired";
  if (error.response.data.errors.includes(twoFactorRequiredError)) {
    return router
      .push({
        name: "login-two-factor",
        params: { email: username, password: password },
      })
      .catch(() => {});
  }

  return Promise.reject(error);
};

/***
 * Set current user system information in store
 * and reload access rights
 * @param userProfile
 * @returns {Promise<void>}
 */
const setUserInformationAndRights = async (userProfile) => {
  let promises = [
    Store.dispatch("Auth/setRoles", userProfile.roles),
    Store.dispatch("Auth/setCompetences", userProfile.competences),
    Store.dispatch("Auth/setUserObject", new Profile(userProfile)),
    Store.dispatch("Auth/saveConsent", userProfile),
  ];

  // some users don't have one
  if (userProfile.language) {
    promises.push(Store.dispatch("Language/setLanguage", userProfile.language));
  } else {
    promises.push(Store.dispatch("Language/setLanguage", defaultLocale));
  }

  await Promise.all(promises);

  updateUserAccessRights();
};

const requestPasswordReset = async (email) => {
  return await userLoginApi.post("/account/reset-password/request", {
    email: email,
  });

  // we don't return anything here, because we don't want to communicate to the
  // user if an email really exists
};

const requestNewAuthToken = async (token, refreshToken, deviceId) => {
  return await userRefreshTokenApi.post("/auth/refresh", {
    accessToken: token,
    refreshToken: refreshToken,
    device: deviceId,
  });
};

/**
 * Whether the user is a patient
 * @param {array} roles User roles
 */
const isPatient = (roles) => {
  return roles.indexOf(PATIENT_ROLE) !== -1;
};

/**
 * Whether the user is a therapist
 * @param {array} roles User roles
 */
const isTherapist = (roles) => {
  return roles.indexOf(THERAPIST_ROLE) !== -1;
};

/**
 * Whether the user is an admin
 */
const isAdmin = (roles) => {
  return roles.indexOf(ADMIN_ROLE) !== -1;
};

/**
 * Whether the user is a super admin
 */
const isSuperAdmin = (roles) => {
  return roles.indexOf(SUPERADMIN_ROLE) !== -1;
};

/**
 * Whether the user is a dpo
 */
const isDPO = (roles) => {
  return roles.indexOf(DPO_ROLE) !== -1;
};

/**
 * Whether the user is a site manager
 */
const isSiteManager = (roles) => {
  return roles.indexOf(SITEMANAGER_ROLE) !== -1;
};

/**
 * Check the competences of admins
 */
const isDataScientist = (competences) => {
  return competences.indexOf(DATA_SCIENTIST) !== -1;
};
const isPublisher = (competences) => {
  return competences.indexOf(PUBLISHER) !== -1;
};
const isAccountExecutive = (competences) => {
  return competences.indexOf(ACCOUNT_EXECUTIVE) !== -1;
};
const isAdminManager = (competences) => {
  return competences.indexOf(ADMIN_MANAGER) !== -1;
};
const isDataExportManager = (competences) => {
  return competences.indexOf(DATA_EXPORT_MANAGER) !== -1;
};
const isOptionalFeatureManager = (competences) => {
  return competences.indexOf(OPTIONAL_FEATURE_MANAGER) !== -1;
};

/**
 * Get the ability profile based on the role
 * @param {string} role The role
 * @return {string} The ability profile
 */
const abilityProfile = (role) => {
  switch (role) {
    case THERAPIST_ROLE:
      return "TherapistProfile";
    case PATIENT_ROLE:
      return "PatientProfile";
    case SITEMANAGER_ROLE:
      return "SiteManagerProfile";
    case DPO_ROLE:
      return "DpoProfile";
    case ADMIN_ROLE:
      return "AdminProfile";
    default:
      return "";
  }
};

export {
  login,
  loginTwoFactor,
  attemptAutoLogin,
  requestPasswordReset,
  requestNewAuthToken,
  isPatient,
  isTherapist,
  isAdmin,
  isSuperAdmin,
  isDPO,
  isSiteManager,
  setUserInformationAndRights,
  roles,
  isDataScientist,
  isPublisher,
  isAccountExecutive,
  isAdminManager,
  isDataExportManager,
  isOptionalFeatureManager,
  competences,
  abilityProfile,
};
