import { Model } from "@vuex-orm/core";
import { notifyError } from "@/services/api";

export default class LevelRecommendation extends Model {
  static entity = "levelRecommendation";

  static fields() {
    return {
      activityId: this.attr(null),
      activeSide: this.attr(null),
      recommendedLevel: this.attr(null),
      addedAtUtc: this.attr(null),
    };
  }

  static async getLatestLevelRecommendationsForPatient(
    patientUsername,
    signal
  ) {
    return this.api()
      .get(`/level-recommendation/patient/${patientUsername}`, {
        dataKey: "result",
        signal,
      })
      .catch((error) => {
        notifyError(error);
      });
  }
}
