export default [
  {
    path: "customer-notification",
    component: () =>
      import(
        "@/pages/EntityManagementPages/CustomerNotificationManagementPages/CustomerNotificationListPage"
      ),
  },
  {
    path: "customer-notification/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/CustomerNotificationManagementPages/CustomerNotificationCreatePage"
      ),
  },
  {
    path: "customer-notification/:customerNotificationId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/CustomerNotificationManagementPages/CustomerNotificationDetailsPage"
      ),
    props: (route) => ({
      customerNotificationId: route.params.customerNotificationId,
    }),
  },
];
