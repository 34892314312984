import Vue from "vue";
import VueMq from "vue-mq";
import tailwindConfig from "@/../tailwind.config.js";

/**
 * Use the tailwind breakpoints in vueMq
 * Need to remove the "px" from the value and cast it as a Number
 */
Vue.use(VueMq, {
  breakpoints: {
    sm: Number(tailwindConfig.theme.screens.sm.replace("px", "", 10)),
    md: Number(tailwindConfig.theme.screens.md.replace("px", "", 10)),
    lg: Number(tailwindConfig.theme.screens.lg.replace("px", "", 10)),
    xl: Number(tailwindConfig.theme.screens.xl.replace("px", "", 10)),
    "2xl": Number(tailwindConfig.theme.screens["2xl"].replace("px", "", 10)),
    "3xl": Number(tailwindConfig.theme.screens["3xl"].replace("px", "", 10)),
  },
  defaultBreakpoint: "sm", // customize this for SSR
});
