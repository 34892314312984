import { Model } from "@vuex-orm/core";
import License from "@/models/License.model";
import PatientLicense from "@/models/PatientLicense.model";
import Site from "@/models/Site.model";
import Asset from "@/models/Asset.model";
import { defaultLocale, getLanguageCodeFrom } from "@/utils/locales";
import { getDropdownEntries } from "@/utils/dropdown";
import { isEntityContainsAllTerms, tidySearchTerm } from "@/utils/filters";

export const customerKinds = Object.freeze({
  siteAdminCustomer: "SiteAdminCustomer",
  therapistCustomer: "TherapistCustomer",
  patientCustomer: "PatientCustomer",
});

export default class Customer extends Model {
  static entity = "customers";

  static fields() {
    return {
      id: this.attr(null),
      salesForceAccountId: this.attr(""),
      salesForceAccountName: this.attr(""),
      salesForceContactID: this.attr(""),
      userName: this.attr(null),
      firstname: this.attr(null),
      name: this.attr(null),
      email: this.attr(null),
      phone: this.attr(null),
      kind: this.attr(customerKinds[0]),
      siteId: this.attr(null),
      language: this.attr(defaultLocale),
      license: this.hasOne(License, "customerId"),
      patientLicense: this.hasMany(PatientLicense, "customerId"),
      site: this.belongsTo(Site, "siteId"),
      assets: this.hasMany(Asset, "customerId"),
    };
  }

  static mutators() {
    return {
      // handle deprecated language values
      language(value) {
        return getLanguageCodeFrom(value);
      },
    };
  }

  get fullName() {
    if (this.firstname && this.name) {
      return this.firstname + " " + this.name;
    }
    if (this.firstname) {
      return this.firstname;
    }
    return this.name;
  }

  get fullNameHtml() {
    if (this.fullName) {
      return this.firstname + " <b>" + this.name + "</b>";
    } else if (this.email) {
      return this.email;
    } else {
      return "--";
    }
  }

  // use in entity-search
  get displayName() {
    if (this.fullName) {
      return this.fullName;
    }
    return this.email;
  }

  static get() {
    return this.api().get("/customer", { dataKey: "result" });
  }

  static async post(newCustomer, entity) {
    const url = entity === "Customer" ? "/customer/mindmotion" : "/customer";
    return this.api()
      .post(url, newCustomer, { dataKey: "result" })
      .then((response) => {
        response.entities.customers.forEach(async (entity) => {
          await Site.getById(entity.siteId);
        });
      });
  }

  static async remove(customer) {
    return this.api().delete("/customer/" + customer.id, {
      delete: customer.id,
      dataKey: "result",
    });
  }

  static async put(updatedCustomer) {
    return this.api().put("/customer/" + updatedCustomer.id, updatedCustomer, {
      dataKey: "result",
    });
  }

  static getCustomerKindList() {
    return getDropdownEntries(customerKinds, "AdminPages.Customer.Kind");
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    let fields = [this.email, this.id];
    if (this.fullName) {
      fields.push(tidySearchTerm(this.fullName));
    }
    if (this.userName) {
      fields.push(tidySearchTerm(this.userName));
    }
    if (this.site?.name) {
      fields.push(tidySearchTerm(this.site.name));
    }
    return fields;
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }
}
