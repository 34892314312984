import cloneDeep from "lodash/cloneDeep";
import { resetState } from "@/store/utils";

const initialState = () => {
  return {
    user: null,
  };
};

/**
 * Store used to set patient profile data at the end of account creation
 * or for profile modification purposes.
 * See Onboarding store for the data used during the patient account creation process.
 */
export default {
  namespaced: true,

  state: initialState,

  mutations: {
    setUser(state, user) {
      state.user = cloneDeep(user);
      // Set user gender if not set by the back-end
      if (state.user != null && !state.user.gender) {
        state.user.gender = "Undefined";
      }
    },
    clearStore(state) {
      resetState(initialState, state);
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit("setUser", user);
    },
    clearStore({ commit }) {
      commit("clearStore");
    },
  },
};
