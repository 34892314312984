import Vue from "vue";
import I18NextVue from "i18next-vue";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { defaultLanguage } from "@/utils/locales";
// translation files
import de from "@/locales/de.json";
import en from "@/locales/en.json";
import es from "@/locales/es.json";
import fr from "@/locales/fr.json";
import it from "@/locales/it.json";
import ro from "@/locales/ro.json";
import ar from "@/locales/ar.json";

export const translationResources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  es: {
    translation: es,
  },
  ro: {
    translation: ro,
  },
  it: {
    translation: it,
  },
  de: {
    translation: de,
  },
  ar: {
    translation: ar,
  },
};

const isProduction = process.env.NODE_ENV === "production";

let i18nextPromise = i18next
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: defaultLanguage,
    resources: translationResources,
  });

export function useI18Next(app) {
  i18nextPromise;
  Vue.use(I18NextVue, { i18next });
  return app;
}

export default i18next;
