<template>
  <div v-if="isNewBadgeVisible">
    <span class="new-badge">
      {{ $t("Common.System.newLabel") }}
    </span>
  </div>
</template>
<script>
import loadConfigData from "@/services/configJson";

export default {
  async mounted() {
    await this.loadBadgeExpirationDate();
  },
  data() {
    return {
      newBadgeExpirationDate: new Date(0),
    };
  },
  methods: {
    async loadBadgeExpirationDate() {
      const expirationDate = (await loadConfigData()).NewBadgeExpirationDate;
      if (expirationDate) {
        this.newBadgeExpirationDate = new Date(expirationDate);
      }
    },
  },
  computed: {
    isNewBadgeVisible() {
      const currentDate = new Date();
      return currentDate < this.newBadgeExpirationDate;
    },
  },
};
</script>
