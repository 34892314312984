/**
 * Function used to ensure the variable of the state are changed and not the reference
 * This is required because vuex will watch a reference and changing the reference breaks it
 * @param initialState
 * @param state
 */
export const resetState = (initialState, state) => {
  const newState = initialState();
  Object.keys(newState).forEach((key) => {
    state[key] = newState[key];
  });
};
