import { parseISO, isAfter, isBefore } from "date-fns";
import { sum, toPairs } from "lodash";

/** Document version date for Privacy Policy */
export const PrivacyPolicyDocumentVersion = "2023-08-31Z";
/** Document version date for Consent Forms */
export const ConsentDocumentVersion = "2019-11-15Z";
/** Consent number used */
export const MaxConsents = 4;

export const DocumentType = Object.freeze({
  PrivacyPolicy: "PrivacyPolicy",
  ConsentForm: "ConsentForm",
});

// Invalid document version default date
const minDate = new Date(1970, 1, 1);

/**
 * Returns the latest document or undefined
 * @param {Array} documents
 * @param {string} documentKind Type of document to look for
 */
const latestSignedDocumentForKind = (documents, documentKind) => {
  const signedDocuments = documents || [];
  const documentsOfType = signedDocuments.filter(
    (d) => d?.kind === documentKind || false
  );
  return (
    documentsOfType?.reduce(function (prev, current) {
      return prev &&
        isAfter(parseISO(prev.signedDate), parseISO(current.signedDate))
        ? prev
        : current;
    }, null) || null
  );
};

const addZ = (documentVersion) => {
  return documentVersion.includes("Z")
    ? documentVersion
    : `${documentVersion}Z`;
};

/**
 * Returns the most current signed consent form usable by the webapp
 * @param {Array} signedDocuments Signed documents array
 */
const getLatestUsableConsent = (signedDocuments) => {
  const latestConsent = latestSignedDocumentForKind(
    signedDocuments,
    DocumentType.ConsentForm
  );

  return latestConsent &&
    !isBefore(
      parseISO(addZ(latestConsent?.documentVersion) || minDate),
      parseISO(ConsentDocumentVersion)
    )
    ? latestConsent
    : null;
};

/**
 * Returns whether the latest possible Privacy policy is signed.
 * By latest possible, it means the latest the webapp can offer.
 * We accept signature of "future" versions to avoid making the user stuck
 * in a signature loop.
 * @param signedDocuments
 * @return {boolean} Whether the document is signed.
 */
export const isLatestPossiblePrivacyPolicySigned = (signedDocuments) => {
  const pp = latestSignedDocumentForKind(
    signedDocuments,
    DocumentType.PrivacyPolicy
  );
  return (
    pp &&
    !isBefore(
      parseISO(addZ(pp.documentVersion) || minDate),
      parseISO(PrivacyPolicyDocumentVersion)
    )
  );
};

/**
 * Deserializes consent to use it in the consent form modal.
 * @param signedDocuments
 */
export const deserializeConsent = (signedDocuments) => {
  if (signedDocuments) {
    const currentConsent = getLatestUsableConsent(signedDocuments);

    const consents = extractConsents(currentConsent);
    return consents;
  } else {
    let obj = {};
    for (let i = 0; i < MaxConsents; i++) {
      obj[i] = false;
    }
    return obj;
  }
};

/**
 * Serializes consent to send it back to the backend.
 * @param consentObject Consent object in the format [consent id: true/false, ...]
 * @return {number}
 */
export const serializeConsent = (consentObject) => {
  const powers = toPowers(consentObject);

  return sum(powers) << 1;
};

/**
 * Return an object containing from 0 to maxConsents whether a particular
 * consent is given or not.
 * For instance:
 * { 0: false, 1: true, ...}
 *
 * @param {Object} consent Consent form signed document
 */
export const extractConsents = (consent) => {
  if (!consent) {
    return Object.assign({}, Array(MaxConsents).fill(false));
  }
  const consentOptions = consent.consentOptions >> 1;

  let consents = {};

  // the consentOptions value is a bitmask
  for (let i = 0; i < MaxConsents; i++) {
    consents[i] = !!(consentOptions & (2 ** i));
  }

  return consents;
};

/**
 * Maps pairs of key and values back into a bitmask
 * Used for consents
 * @param consentObject Consent object in the format [consent id: true/false, ...]
 * @return {unknown[]} sumable powers for each value
 */
const toPowers = (consentObject) => {
  const entries = toPairs(consentObject);

  const powers = entries.map(([key, value]) => {
    if (value) {
      return 2 ** key;
    }

    return 0;
  });

  return powers;
};
