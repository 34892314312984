/**
 * Active side keys.
 * Used in conjunction with trained functions to create categories
 * and for translations
 */
const ActiveSidesKeys = {
  NotApplicable: "Bilateral",
  undefined: "Bilateral",
  Left: "Left",
  Right: "Right",
};

/**
 * Returns the formatted active side based on raw active side data.
 * @param activeSide {string}
 * @return {string}
 */
const getFormattedActiveSide = (activeSide) => {
  return ActiveSidesKeys[activeSide] == undefined
    ? ActiveSidesKeys.undefined
    : ActiveSidesKeys[activeSide];
};

export { getFormattedActiveSide };
