/**
 *  Script allowing to start conference and managing its events. This is the core script for conferencing though vidyo
 *
 */

import { tr } from "@/utils/translation";

export {
  StartVidyoConnector,
  connectorDisconnected,
  join,
  leave,
  setCamera,
  setMicrophone,
  setSpeaker,
  selectSharing,
};
export { vidyoConnector };
import { storeName, videoMaxes } from "@/plugins/vidyo/constants";

var Store = null;
var vidyoConnector = null;

// Variable for screen sharing
var windowShare;
var isSharingWindow = false;

// Run StartVidyoConnector when the VidyoClient is successfully loaded
function StartVidyoConnector(VC, store, platformInfo, callbacks) {
  Store = store;
  window.onresize = function () {
    if (vidyoConnector) {
      HideLabel(vidyoConnector);
    }
  };

  window.onbeforeunload = function () {
    vidyoConnector.Destruct();
  };

  VC.CreateVidyoConnector({
    viewId: "vidyo-renderer", // Div ID where the composited video will be rendered
    viewStyle: "VIDYO_CONNECTORVIEWSTYLE_Default", // Visual style of the composited renderer
    remoteParticipants: 2, // Maximum number of participants to render
    logFileFilter: "warning info@VidyoClient info@VidyoConnector",
    logFileName: "",
    userData: "",
  })
    .then(function (vc) {
      vidyoConnector = vc;
      HideLabel(vidyoConnector);
      registerDeviceListeners(vidyoConnector);
      handleParticipantChange(vidyoConnector, callbacks.participantsChangeCb);
      handleSharing(vidyoConnector);
      //handleChat(vidyoConnector); //THIS IS DISABLED BUT STILL PRESENT UNTIL FURTHER NOTICE

      // Populate the connectionStatus with the client version
      vidyoConnector
        .GetVersion()
        .then(function (version) {
          console.log("version", version);
          Store.dispatch(`${storeName}/setVersion`, version);
        })
        .catch(function () {
          Store.dispatch(`${storeName}/setVersion`, "--");
        });

      // If running on Internet Explorer, set the default certificate authority list.
      // This is necessary when IE's Protected Mode is enabled.
      if (platformInfo.isIE) {
        vidyoConnector
          .SetCertificateAuthorityList({ certificateAuthorityList: "default" })
          .then(function () {
            console.log("SetCertificateAuthorityList success");
          })
          .catch(function () {
            console.error("SetCertificateAuthorityList failed");
          });
      }
      if (callbacks.vidyoReadyCb) {
        callbacks.vidyoReadyCb();
      }
    })
    .catch(function (err) {
      console.error("CreateVidyoConnector Failed " + err);
    });
}

async function leave() {
  return vidyoConnector.Disconnect();
}

/**
 * HANDLERS
 *
 */

/// CALLED BY INIT
function HideLabel(vidyoConnector) {
  var rndr = document.getElementById("vidyo-renderer");
  if (rndr != null) {
    vidyoConnector.ShowViewLabel({
      viewId: "vidyo-renderer",
      showLabel: false, // Hide 'Preview' label
    });
  }
}

// Permits to count devices on addition
let cameraNumber = 0;
let microphoneNumber = 0;

/// CALLED BY INIT
function registerDeviceListeners(vidyoConnector) {
  // Handle appearance and disappearance of camera devices in the system
  vidyoConnector
    .RegisterLocalCameraEventListener({
      onAdded: function (localCamera) {
        localCamera.SetMaxConstraint({
          width: videoMaxes.width,
          height: videoMaxes.height,
        });

        // Creates a getter so that the displayed value makes sense
        // when the camera name is not available, as seen on Firefox
        // or when blocking camera access on Chrome.
        // To be noted that the camera name is not available
        // at the time this method is run, hence the usage of a getter.
        const deviceNumber = ++cameraNumber;
        localCamera.displayName = () => {
          // Checking for falsy here doesn't work because it is a simple string object
          if (localCamera.name !== "") {
            return localCamera.name;
          }

          return tr("ConferencePage.Video.Settings.cameraNumber", {
            deviceNumber: deviceNumber,
          });
        };

        Store.dispatch(`${storeName}/addCamera`, localCamera);

        if (localCamera.name.includes("Default")) {
          setCamera(localCamera);
        }

        if (localCamera.name.includes("Kinect")) {
          setCamera(localCamera);
        }
      },
      onRemoved: function (localCamera) {
        // Existing camera became unavailable
        Store.dispatch(`${storeName}/removeCamera`, localCamera);
      },
      onSelected: function (localCamera) {
        // Camera was selected/unselected by you or automatically
        if (localCamera) {
          Store.dispatch(`${storeName}/setSelectedCamera`, localCamera);
        }
      },
      onStateUpdated: function (localCamera, state) {
        // Camera state was updated
      },
    })
    .then(function () {
      console.log("RegisterLocalCameraEventListener Success");
    })
    .catch(function () {
      console.error("RegisterLocalCameraEventListener Failed");
    });

  // Handle appearance and disappearance of microphone devices in the system
  vidyoConnector
    .RegisterLocalMicrophoneEventListener({
      onAdded: function (localMicrophone) {
        // Creates a getter so that the displayed value makes sense
        // when the microphone name is not available, as seen on Firefox
        // or when blocking microphone access on Chrome.
        // To be noted that this name is not available
        // at the time this method is run, hence the usage of a getter.
        const deviceNumber = ++microphoneNumber;
        localMicrophone.displayName = () => {
          // Checking for falsy here doesn't work because it is a simple string object
          if (localMicrophone.name !== "") {
            return localMicrophone.name;
          }

          return tr("ConferencePage.Video.Settings.microphoneNumber", {
            deviceNumber: deviceNumber,
          });
        };

        Store.dispatch(`${storeName}/addMicrophone`, localMicrophone);
        if (localMicrophone.name.includes("Default")) {
          setMicrophone(localMicrophone);
        }
      },
      onRemoved: function (localMicrophone) {
        Store.dispatch(`${storeName}/removeMicrophone`, localMicrophone);
      },
      onSelected: function (localMicrophone) {
        Store.dispatch(`${storeName}/setSelectedMicrophone`, localMicrophone);
      },
      onStateUpdated: function (localMicrophone, state) {
        // Microphone state was updated
      },
    })
    .then(function () {
      console.log("RegisterLocalMicrophoneEventListener Success");
    })
    .catch(function () {
      console.error("RegisterLocalMicrophoneEventListener Failed");
    });

  // Handle appearance and disappearance of speaker devices in the system
  vidyoConnector
    .RegisterLocalSpeakerEventListener({
      onAdded: function (localSpeaker) {
        Store.dispatch(`${storeName}/addSpeaker`, localSpeaker);
        if (localSpeaker.name.includes("Default")) {
          setSpeaker(localSpeaker);
        }
      },
      onRemoved: function (localSpeaker) {
        Store.dispatch(`${storeName}/removeSpeaker`, localSpeaker);
      },
      onSelected: function (localSpeaker) {
        Store.dispatch(`${storeName}/setSelectedSpeaker`, localSpeaker);
      },
      onStateUpdated: function (localSpeaker, state) {
        // Speaker state was updated
      },
    })
    .then(function () {
      console.log("RegisterLocalSpeakerEventListener Success");
    })
    .catch(function () {
      console.error("RegisterLocalSpeakerEventListener Failed");
    });
}

let setCamera = async (camera) => {
  return vidyoConnector
    .SelectLocalCamera({
      localCamera: camera,
    })
    .then(function () {});
};

let setMicrophone = async (microphone) => {
  return vidyoConnector
    .SelectLocalMicrophone({
      localMicrophone: microphone,
    })
    .then(function () {});
};

let setSpeaker = async (speaker) => {
  return vidyoConnector
    .SelectLocalSpeaker({
      localSpeaker: speaker,
    })
    .then(function () {});
};

/// CALLED BY INIT
function handleSharing(vidyoConnector) {
  // Register for window share status updates, which operates differently in plugin vs webrtc:
  //    plugin: onAdded and onRemoved callbacks are received for each available window
  //    webrtc: onAdded callback received for a single item, which when clicked will yield a popup to select a share.
  vidyoConnector
    .RegisterLocalWindowShareEventListener({
      onAdded: function (localWindowShare) {
        windowShare = localWindowShare;
      },
      onRemoved: function (localWindowShare) {
        /* Existing window is no longer available for sharing */
      },
      onSelected: function (localWindowShare) {
        if (localWindowShare) {
          isSharingWindow = true;
          console.log("Window share selected : " + localWindowShare.name);
        } else {
          isSharingWindow = false;
          console.log("Stop sharing window");
        }
        Store.dispatch(`${storeName}/shareWindow`, isSharingWindow);
        Store.dispatch(`${storeName}/loadShareWindow`, false);
      },
      onStateUpdated: function (localWindowShare, state) {
        /* Window share state has been updated */
      },
    })
    .then(function () {
      console.log("RegisterLocalWindowShareEventListener Success");
    })
    .catch(function () {
      console.error("RegisterLocalWindowShareEventListener Failed");
    });
}

let selectSharing = async () => {
  if (!isSharingWindow) {
    return vidyoConnector.SelectLocalWindowShare({
      localWindowShare: windowShare,
    });
  } else {
    return vidyoConnector.SelectLocalWindowShare({
      localWindowShare: null,
    });
  }
};

/// CALLED BY INIT
function handleParticipantChange(vidyoConnector, userChangeCb) {
  // Report local participant in RegisterParticipantEventListener.
  vidyoConnector.ReportLocalParticipantOnJoined({
    reportLocalParticipant: true,
  });

  vidyoConnector
    .RegisterParticipantEventListener({
      onJoined: function (participant) {
        if (participant.name.length > 64) {
          participant.name = participant.name.substring(1); // remove apostrophe in front of username introduced by vidyo when Companion Username starts with special character
        }
        Store.dispatch(`${storeName}/addRemoteParticipant`, participant).then(
          () => {
            userChangeCb();
          }
        );
      },
      onLeft: function (participant) {
        Store.dispatch(
          `${storeName}/removeRemoteParticipant`,
          participant
        ).then(() => {
          userChangeCb();
        });
      },
      onDynamicChanged: function (participants) {
        // Order of participants changed
      },
      onLoudestChanged: function (participant, audioOnly) {},
    })
    .then(function () {
      console.log("RegisterParticipantEventListener Success");
    })
    .catch(function () {
      console.err("RegisterParticipantEventListener Failed");
    });
}

// Attempt to connect to the conference
// We will also handle connection failures
// and network or server-initiated disconnects.
async function join(
  vidyoConnector,
  host,
  roomKey,
  roomPin,
  displayName,
  resourceId,
  onSuccess,
  onFailure,
  onDisconnect
) {
  return vidyoConnector
    .ConnectToRoomAsGuest({
      host: host,
      displayName: displayName,
      roomKey: roomKey,
      roomPin: `${roomPin}`,

      onSuccess: function () {
        onSuccess();
        HideLabel(vidyoConnector);
      },
      onFailure: function (reason) {
        onFailure(reason);
        console.error(
          "VidyoConnector.ConnectToRoomAsGuest : onFailure callback received"
        );
        HideLabel(vidyoConnector);
      },
      onDisconnected: function (reason) {
        onDisconnect(reason);
        HideLabel(vidyoConnector);
      },
    })
    .then(function (status) {
      if (!status) {
        throw Error(status);
      }
    })
    .catch(function () {
      console.error("ConnectToRoomAsGuest Failed");
    });
}

// Connector either fails to connect or a disconnect completed, update UI elements
function connectorDisconnected(vidyoConnector) {
  // Release devices from the vidyo connector and then destruct it.
  vidyoConnector.SelectLocalCamera({ localCamera: null });
  vidyoConnector.SelectLocalMicrophone({ localMicrophone: null });
  vidyoConnector.SelectLocalSpeaker({ localSpeaker: null });
  vidyoConnector.Destruct();
}
