import i18n from "@/i18n";

/**
 * Translates a given message using the i18n current Vue dictionary
 * @param {string} messageKey The message key to use
 * @returns {string} translated string
 */
export const tr = (messageKey, params = null) => {
  return i18n.t(messageKey, params);
};
