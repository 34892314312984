export default [
  {
    path: "feedbackReport",
    component: () =>
      import(
        "@/pages/EntityManagementPages/FeedbackReportManagementPages/FeedbackReportListPage"
      ),
  },
  {
    path: "feedbackReport/:feedbackReportId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/FeedbackReportManagementPages/FeedbackReportDetailsPage"
      ),
    props: true,
  },
];
