import Vue from "vue";

const vueInterface = new Vue();

/**
 * Schedule a notification on the Vue UI
 * @param {string} type Either a 'success' or 'error'
 * @param {string} message The message to notify
 * @param {string} title (Optional) the notification title
 */
export const notify = (type, message, title = null) => {
  vueInterface.$notify({
    type: type,
    title: title,
    text: message,
    position: "top center",
  });
};

/**
 * Display a notification that will stay until closed by the user.
 * @param {string} type Either a 'success' or 'error'
 * @param {string} message The message to notify
 * @param {string} title (Optional) the notification title
 */
export const notifyUntilClosed = (type, message, title = null) => {
  vueInterface.$notify({
    type: type,
    title: title,
    text: message,
    position: "top center",
    duration: -1,
    data: { showCloseButton: true },
  });
};
