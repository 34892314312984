export default [
  {
    path: "/reference",
    name: "reference",
    component: () => import("@/pages/ReferencePages/index.vue"),
    meta: {
      requiresMindMazeAuth: true,
    },
    children: [
      {
        path: "activity-definition",
        component: () =>
          import(
            "@/pages/ReferencePages/ActivityDefinitionPage/ActivityDefinitionPage.vue"
          ),
      },
      {
        path: "theme/colors",
        component: () => import("@/pages/ReferencePages/theme/ThemeColors"),
      },
      {
        path: "theme/icons",
        component: () => import("@/pages/ReferencePages/theme/ThemeIcons"),
      },
      {
        path: "theme/typography",
        component: () => import("@/pages/ReferencePages/theme/ThemeTypography"),
      },
      {
        path: "components/elements-summary",
        component: () =>
          import("@/pages/ReferencePages/components/ElementsSummary"),
      },
      {
        path: "components/components-summary",
        component: () =>
          import("@/pages/ReferencePages/components/ComponentsSummary"),
      },
      {
        path: "development/scrollable/simple",
        component: () =>
          import(
            "@/pages/ReferencePages/development/InnerScrollablePageTestSimple"
          ),
      },
      {
        path: "development/scrollable/better",
        component: () =>
          import("@/pages/ReferencePages/development/InnerScrollablePageTest"),
      },
      {
        path: "development/component-view",
        component: () =>
          import("@/pages/ReferencePages/development/ComponentTestView"),
      },
    ],
  },
];
