<template>
  <label v-if="label.length" class="flex">
    <span
      class="form-input-label"
      :class="disabled ? 'disabled' : labelClasses"
    >
      {{ label }}
    </span>
    <span class="form-required ps-1 text-base" v-if="required">*</span>
    <svg-icon
      v-if="tooltip"
      class="form-input-label inline icon-sm ms-2 mt-1"
      :class="disabled ? 'disabled' : ''"
      name="info-circle"
      v-tooltip.bottom="tooltip"
    />
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    labelClasses: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
