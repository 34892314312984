import { Model } from "@vuex-orm/core";
import Profile from "@/models/Profile.model";
import { isEntityContainsAllTerms } from "@/utils/filters";
import { formatToMMIDWithDashes } from "@/utils/profile";
import { parseISO, isWithinInterval, startOfDay, endOfDay } from "date-fns";

class WeeklyATTimeGoal {
  constructor(other) {
    this.validFromDate = other?.validFromDate || new Date();
    this.validToDate = other?.validToDate || new Date();
    this.patientUsername = other?.patientUsername || "";
    this.totalActiveTrainingTime = other?.totalActiveTrainingTime || 0;
    this.supervisedActiveTrainingTime =
      other?.supervisedActiveTrainingTime || 0;
    this.unsupervisedActiveTrainingTime =
      other?.unsupervisedActiveTrainingTime || 0;
    this.weeklyActiveTrainingTimeGoal =
      other?.weeklyActiveTrainingTimeGoal || 0;
    this.isGoalSet = other?.isGoalSet || false;
    this.daysTrained = other?.daysTrained || 0;
  }
}

export default class PatientSessionSummary extends Model {
  static entity = "patientsessionsummaries";
  static primaryKey = "userName";

  static fields() {
    return {
      id: this.attr(null),
      userName: this.attr(null),
      firstName: this.attr(null),
      lastName: this.attr(null),
      therapistId: this.attr(null),
      isArchived: this.attr(false),
      isAccountDeactivated: this.attr(false),
      lastSessionDate: this.attr(null),
      email: this.attr("email"),
      MMID: this.attr(null),
      patientSiteId: this.attr(null),
      referenceId: this.attr(null),
      lastWeekActiveTrainingTime: this.attr(null),
      adherence: this.attr(null),
      quality: this.attr(null),
      quantity: this.attr(null),
      weeklyActiveTrainingTimeGoals: this.attr([]),
      therapist: this.belongsTo(Profile, "therapistId"),
      patient: this.belongsTo(Profile, "userName", "userName"),
    };
  }

  // Return the weekly ATT goal to display among the weeklyActiveTrainingTimeGoals,
  // according to the current "browser" date
  get currentGoal() {
    return (
      this.weeklyActiveTrainingTimeGoals?.find((g) =>
        isWithinInterval(new Date(), {
          start: startOfDay(parseISO(g.validFromDate)),
          end: endOfDay(parseISO(g.validToDate)),
        })
      ) || new WeeklyATTimeGoal()
    );
  }

  get fullName() {
    if (!this.firstName && !this.lastName) {
      return null;
    }
    if (!this.firstName) {
      return this.lastName;
    }
    if (!this.lastName) {
      return this.firstName;
    }
    return this.firstName + " " + this.lastName;
  }

  /**
   * Obtains patient summary data
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static get(signal) {
    return this.api().get("/session/concise", {
      dataKey: "result",
      signal,
    });
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    const entityFieldsToSearchInto = [
      this.firstName,
      this.lastName,
      this.patientSiteId,
      this.MMID,
      formatToMMIDWithDashes(this.MMID),
      this.email,
    ];

    return isEntityContainsAllTerms(entityFieldsToSearchInto, searchTerms);
  }
}
