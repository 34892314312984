import { getMinutesAndSecondsFromSeconds } from "@/utils/duration";
import { tr } from "@/utils/translation";

export class CompensationFeedbackResult {
  constructor(result) {
    this.id = result.id;
    this.compensatedDuration = result.compensatedDuration;
    this.details =
      result.details != null
        ? result.details.map((detail) => new CompensationDetails(detail))
        : [];
  }

  /**
   * Returns the formatted duration.
   * @return {string}
   */
  translatedDuration() {
    const [minutes, seconds] = getMinutesAndSecondsFromSeconds(
      this.compensatedDuration
    );
    return tr("Common.DurationWithValues.minutesSecondsDisplayWithLegends", {
      minutes: minutes,
      seconds: seconds,
    });
  }

  /**
   * Returns whether the compensation details are valid:
   * - at least one details name has a duration > 0
   * @return {boolean}
   */
  hasDetails() {
    if (this.details === null || this.details.length === 0) {
      return false;
    }

    return this.details.some(
      (detail) => detail.duration !== null && detail.duration > 0
    );
  }
}

export class CompensationDetails {
  constructor(details) {
    this.name = details.name || null;
    this.duration = details.duration || null;
    this.average = details.average || null;
    this.min = details.min || null; // double value, can be "-Infinity".
    this.max = details.max || null; // double value, can be "Infinity".
  }

  /**
   * Returns the formatted duration.
   * @return {string}
   */
  translatedDetailDuration() {
    const clampedDuration = Math.max(1.0, this.duration); // E.g.: Consider 0.3 seconds as 1.0 to display even small values;
    const [minutes, seconds] = getMinutesAndSecondsFromSeconds(
      Math.round(clampedDuration)
    );
    return tr("Common.DurationWithValues.minutesSecondsDisplayWithLegends", {
      minutes: minutes,
      seconds: seconds,
    });
  }
}
