export default [
  {
    path: "asset",
    component: () =>
      import(
        "@/pages/EntityManagementPages/AssetManagementPages/AssetListPage"
      ),
  },
  {
    path: "asset/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/AssetManagementPages/AssetCreatePage"
      ),
  },
  {
    path: "asset/:assetId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/AssetManagementPages/AssetDetailsPage"
      ),
    props: (route) => ({ assetId: route.params.assetId }),
  },
];
