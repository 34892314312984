/**
 * Training material attachment metadata
 */
export default class TrainingMaterialAttachment {
  constructor(attachment) {
    this.url = attachment?.url || "";
    this.type = attachment?.type;
    this.size = attachment?.size || 0.0;
    this.checksum = attachment?.checksum;
  }
}
