export class SiteSAMLParameters {
  constructor(other) {
    if (other) {
      this.isEnabled = other.isEnabled || false;
      this.customPortalURLSuffix = other.customPortalURLSuffix || "";
      this.additionalCustomPortalURLSuffix =
        other.additionalCustomPortalURLSuffix || [];
      this.customServiceProviderName = other.customServiceProviderName || "";
      this.idpRedirectionURL = other.idpRedirectionURL || "";
      this.logoutURL = other.logoutURL || "";
      this.identityProviderCertificate =
        other.identityProviderCertificate || "";
      this.canSupertherapistAdministerSAML =
        other.canSupertherapistAdministerSAML || false;
      this.enforceSAMLByDefault = other.enforceSAMLByDefault || false;
    } else {
      this.isEnabled = false;
      this.customServiceProviderName = "";
      this.customPortalURLSuffix = "";
      this.additionalCustomPortalURLSuffix = [];
      this.idpRedirectionURL = "";
      this.logoutURL = "";
      this.identityProviderCertificate = "";
      this.canSupertherapistAdministerSAML = false;
      this.enforceSAMLByDefault = false;
    }
  }
}

export class SitePasswordParameters {
  constructor(params) {
    if (params) {
      this.isExpirationEnabled = params.isExpirationEnabled || false;
      this.expirationInDays = params.expirationInDays || 365;
      this.notificationBeforeExpirationInDays =
        params.notificationBeforeExpirationInDays || 30;
    } else {
      this.isExpirationEnabled = false;
      this.expirationInDays = 365;
      this.notificationBeforeExpirationInDays = 30;
    }
  }
}
