import Vue from "vue";
import VuejsDialog from "vuejs-dialog";
import i18n from "./../i18n";

// include the default style
import "vuejs-dialog/dist/vuejs-dialog.min.css";

import ConfirmDialog from "@/components/ui/ConfirmDialog.vue";

// Tell Vue to install the plugin.
Vue.use(VuejsDialog, {
  view: "confirm-dialog",
  okText: i18n.t("Common.Button.yes"),
  cancelText: i18n.t("Common.Button.no"),
  showCancelText: true,
  customClass: "confirm-modal",
  html: false,
});

Vue.dialog.registerComponent("confirm-dialog", ConfirmDialog);
