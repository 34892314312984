import { Model } from "@vuex-orm/core";
import { notifyError } from "@/services/api";

export default class InstructionsForUse extends Model {
  static entity = "allInstructionsForUse";

  static fields() {
    return {
      id: this.attr(null),
      addedAtUtc: this.attr(null),
      name: this.attr(null),
      productName: this.attr(null),
      productVersion: this.attr(null),
      kind: this.attr(null),
      language: this.attr(null),
      documentVersion: this.attr(null),
      checksum: this.attr(null),
      size: this.attr(-1),
      uri: this.attr(null),
    };
  }

  static async getLatest() {
    return this.api()
      .get("/ifu/search?latestOnly=true", { dataKey: "result" })
      .catch((error) => {
        notifyError(error);
      });
  }
}
