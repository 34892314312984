import customerRoutes from "@/router/entities/customer";
import siteRoutes from "@/router/entities/site";
import siteManagementRoutes from "@/router/entities/siteManagement";
import licenseRoutes from "@/router/entities/license";
import patientLicenseRoutes from "@/router/entities/patientLicense";
import assetRoutes from "@/router/entities/asset";
import siteManagerRoutes from "@/router/entities/siteManager";
import therapistRoutes from "@/router/entities/therapist";
import administratorRoutes from "@/router/entities/administrator";
import dataExportRoutes from "@/router/dataExportRoutes";
import optionalFeatureActivationRoutes from "@/router/entities/optionalFeatureActivation";
import feedbackReportRoutes from "@/router/entities/feedbackReport";
import archivedUsersRoutes from "@/router/entities/archivedUsers";
import { getSupportRoutes } from "@/router/supportRoutes";
import { getProfileRoutes } from "@/router/profileRoutes";

const routePrefix = "/admin";

export default [
  {
    path: routePrefix,
    component: () => import("@/pages/AdminPages/AdminHomePage"),
    meta: {
      requiresAdmin: true,
    },
    children: [
      ...getSupportRoutes(routePrefix),
      ...getProfileRoutes(routePrefix),
      ...customerRoutes,
      ...siteRoutes,
      ...licenseRoutes,
      ...patientLicenseRoutes,
      ...assetRoutes,
      ...siteManagerRoutes,
      ...siteManagementRoutes,
      ...therapistRoutes,
      ...administratorRoutes,
      ...dataExportRoutes,
      ...optionalFeatureActivationRoutes,
      ...feedbackReportRoutes,
      ...archivedUsersRoutes,
      {
        path: "",
        name: "Admin/Home",
        component: () => import("@/pages/AdminPages/AdminHomePage/Dashboard"),
      },
    ],
  },
];
