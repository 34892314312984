import { Model } from "@vuex-orm/core";
import { parseISO } from "date-fns";
import { getFormattedActiveSide } from "@/utils/activeSide";

export default class PerformanceActivityPlanningAndTraining extends Model {
  static entity = "performanceactivityplanningandtrainings";

  static fields() {
    return {
      appId: this.attr(null),
      date: this.attr(null),
      level: this.attr(null),
      activeSide: this.attr(null),
      trainedFunctions: this.attr([]),
      plannedSeconds: this.attr(null),
      activeTrainingTime: this.attr(null),
      extraTrainingTime: this.attr(null),
      trainingTimeAccordingToPlan: this.attr(null),
    };
  }

  get Date() {
    return this.date ? parseISO(this.date) : null;
  }

  get ActiveSide() {
    return getFormattedActiveSide(this.activeSide);
  }
}
