<!--
 Global application layout
 Only elements available in every context should be placed here.
 This layout should be used only once on top of the components.
-->
<template>
  <div id="app" class="h-full min-h-screen overflow-y-auto">
    <!-- General notifications -->
    <toast />

    <!-- App wide modals -->
    <login-modal />

    <stack>
      <slot />
    </stack>

    <!-- Cookie bottom banner -->
    <cookie-bar />
    <!-- Permits modal to be placed globally here -->
    <portal-target name="modal" tag="div" />
  </div>
</template>

<script>
// Components
import Toast from "@/components/ui/Toast.vue";
import LoginModal from "@/components/login/LoginModal.vue";
import Stack from "@/components/layout/common/Stack.vue";
import CookieBar from "@/components/application/CookieBar.vue";

export default {
  components: { Stack, LoginModal, Toast, CookieBar },
};
</script>
