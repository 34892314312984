<template>
  <div class="relative">
    <div>
      <base-label
        :label="label"
        :labelClasses="labelClasses"
        :disabled="disabled"
        :required="required"
      />
      <input
        :type="passwordType"
        :class="[
          'form-input pe-12 ' + inputClasses,
          anyError ? 'form-input-error' : '',
        ]"
        :placeholder="disabled ? '' : placeholder"
        :value="localValue"
        :autocomplete="newPassword ? 'new-password' : 'password'"
        :disabled="disabled"
        :readonly="isReadOnly"
        @input="onInput"
        @change="onChange"
        @focus="onFocus"
        @blur="onBlur"
        @animationstart="animationStart"
        @animationend="animationEnd"
      />
    </div>
    <!-- N.B.: type=button here permit to let the login button to work
      as default entry in the login form -->
    <base-button
      type="button"
      class="absolute bottom-0 border-0 end-0"
      tabindex="-1"
      :disabled="disabled"
      @click.prevent="toggleShowPassword"
    >
      <svg-icon v-if="passwordShown" name="visibility-show" />
      <svg-icon v-else name="visibility-hide" />
    </base-button>
  </div>
</template>

<script>
import BaseLabel from "@/components/ui/BaseLabel.vue";
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";

const PASSWORDTYPE = "password";
const TEXTTYPE = "text";

// @vuese
// component to display a password input
// @group 3. UI/Base
export default {
  components: { BaseLabel },
  data() {
    return {
      localValue: cloneDeep(this.value),
      passwordType: PASSWORDTYPE,
    };
  },
  props: {
    value: {
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    tooltip: {
      required: false,
      type: String,
    },
    placeholder: {
      type: String,
      required: false,
      default: "",
    },
    inputClasses: {
      type: String,
      required: false,
      default: "",
    },
    labelClasses: {
      type: String,
      default: "",
    },
    newPassword: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    anyError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("Language", ["isRTLLanguage"]),
    passwordShown() {
      return this.passwordType === TEXTTYPE;
    },
  },
  methods: {
    onInput(event) {
      this.localValue = event.target.value;
      this.$emit("input", this.localValue);
    },
    onChange(event) {
      this.localValue = event.target.value;
      this.$emit("change", this.localValue);
    },
    onFocus() {
      this.$emit("focus");
    },
    onBlur() {
      this.$emit("blur");
    },
    toggleShowPassword() {
      if (this.passwordType === PASSWORDTYPE) {
        this.passwordType = TEXTTYPE;
      } else {
        this.passwordType = PASSWORDTYPE;
      }
    },
    animationStart(event) {
      this.$emit("animationstart", event);
    },
    animationEnd(event) {
      this.$emit("animationend", event);
    },
  },
  watch: {
    value() {
      this.localValue = cloneDeep(this.value);
    },
  },
};
</script>
