/**
 * Remove accents and diacritics
 * https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript/37511463#37511463
 */
function removeAccentAndDiacritics(str) {
  return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

/**
 * Normalize one term for search or filtering
 * Return a trimmed string in lower case without diacritics
 * @param {string} term to tidy
 */
function tidySearchTerm(term) {
  return removeAccentAndDiacritics(term).toLowerCase().trim();
}

/**
 * Normalize space separated terms for search or filtering
 * Return an array of trimmed string in lower case without diacritics
 * @param {string} terms to tidy are space separated
 */
function tidySearchTerms(terms) {
  return tidySearchTerm(terms).split(/\s+/);
}

/**
 * Return true if fields contain searched term
 * @param {string[]} fields entity fields to look into
 * @param {string} term to find in the entity
 */
function isEntityCorrespondingTo(fields, term) {
  return tidySearchTerm(fields.join("")).includes(term);
}

/**
 * Return true iff all terms correspond to the entity
 * @param {string} searchTerms terms are space separated
 */
function isEntityContainsAllTerms(entityFieldsToSearchInto, searchTerms) {
  let terms = tidySearchTerms(searchTerms);
  return terms.every((term) =>
    isEntityCorrespondingTo(entityFieldsToSearchInto, term)
  );
}

/**
 * Return true if at least one term corresponds to the entity
 * @param {string} searchTerms terms are space separated
 */
function isEntityContainsOneTerm(entityFieldsToSearchInto, searchTerms) {
  let terms = tidySearchTerms(searchTerms);
  return terms.some((term) =>
    isEntityCorrespondingTo(entityFieldsToSearchInto, term)
  );
}

/**
 * Filter by site name
 */
function siteNameFilter(element, searchTerm) {
  searchTerm = tidySearchTerm(searchTerm);
  return (
    element.name &&
    removeAccentAndDiacritics(element.name).toLowerCase().includes(searchTerm)
  );
}

/**
 * Filter by username (Patient site id)
 */
function patientSiteIdFilter(element, searchTerm) {
  searchTerm = tidySearchTerm(searchTerm);
  return (
    element.patientSiteId &&
    removeAccentAndDiacritics(element.patientSiteId)
      .toLowerCase()
      .includes(searchTerm)
  );
}

/**
 * Filter by name
 */
function nameFilter(element, searchTerm) {
  searchTerm = tidySearchTerm(searchTerm);
  let terms = searchTerm.split(" ");
  if (element.firstName && element.lastName) {
    return terms.every(
      (item) =>
        removeAccentAndDiacritics(element.firstName)
          .toLowerCase()
          .includes(item) ||
        removeAccentAndDiacritics(element.lastName).toLowerCase().includes(item)
    );
  }

  if (element.firstName) {
    return terms.every((item) =>
      removeAccentAndDiacritics(element.firstName).toLowerCase().includes(item)
    );
  }

  if (element.lastName) {
    return terms.every((item) =>
      removeAccentAndDiacritics(element.lastName).toLowerCase().includes(item)
    );
  }
}

/**
 * Filter by email
 */
function emailFilter(element, searchTerm) {
  searchTerm = tidySearchTerm(searchTerm);
  return element?.email?.toLowerCase().includes(searchTerm);
}

/**
 * Filter by actual username
 */
function usernameFilter(element, searchTerm) {
  searchTerm = tidySearchTerm(searchTerm);
  return element?.userName?.toLowerCase().includes(searchTerm);
}

/**
 * Filter for the MMID
 *
 * Remove the dashes and spaces because the MMID in the patient is
 * without them. Also remove the MMID prefix from the search
 */
function MMIDFilter(element, searchTerm) {
  const term = searchTerm
    .replace(/^MMID-/i, "")
    .replace(/[- ]/g, "")
    .toLowerCase();
  return element.MMID !== null && element.MMID.toLowerCase().includes(term);
}

/**
 * Indicates whether a list of space separated terms are found within the given text.
 * @param text
 * @param search
 * @return {boolean}
 */
function termsFilter(text, searchTerm) {
  searchTerm = tidySearchTerm(searchTerm);
  let terms = searchTerm.trim().toLowerCase().split(" ");
  for (let term of terms) {
    if (text.toLowerCase().includes(term)) {
      return true;
    }
  }
  return false;
}

/**
 * Filter by translation object (key with translation in all languages), i.e "name"
 * "name": {
 *    "en": "Magic Carpet",
 *    "fr": "Tapis Magique",
 *    "de": "Zauberteppich",
 *    "it": "Tappeto Magico",
 *    "es": "Alfombra mágica",
 *    "ro": "Covorul fermecat"
 *  },
 */
function objectWithTranslationsFilter(obj, search) {
  let terms = search.trim().toLowerCase().split(" ");
  for (let term of terms) {
    return Object.values(obj).find((translatedValue) =>
      translatedValue.toLowerCase().includes(term.toLowerCase())
    );
  }
  return false;
}

export {
  isEntityContainsAllTerms,
  isEntityContainsOneTerm,
  isEntityCorrespondingTo,
  siteNameFilter,
  patientSiteIdFilter,
  nameFilter,
  emailFilter,
  usernameFilter,
  MMIDFilter,
  removeAccentAndDiacritics,
  tidySearchTerm,
  tidySearchTerms,
  termsFilter,
  objectWithTranslationsFilter,
};
