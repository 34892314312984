import { Model } from "@vuex-orm/core";

export default class ActivityPatientUsage extends Model {
  static entity = "activityPatientUsage";

  static fields() {
    return {
      id: this.attr(null), // Corresponds to the app id
      username: this.attr(null),
      icon: this.attr(null),
      gestureProviders: this.attr([]),
      isSideChoiceEnabled: this.attr(false), // used for calibration custom computation
      calibrationDatePerSide: this.attr(null),
      lastPlayedDate: this.attr(null),
      playedCount: this.attr(null),
    };
  }

  //
  // Metadata getters

  // @summary Returns the most recent calibration date for the given activity data.
  get CalibrationDate() {
    const datePerSide = this.calibrationDatePerSide || [];
    let mostRecentDate = new Date(1970, 1, 1);

    for (let sideDate in datePerSide) {
      // Skip loop if the property is from prototype
      if (!datePerSide.hasOwnProperty(sideDate)) {
        continue;
      }
      let date = new Date(datePerSide[sideDate]);
      if (date > mostRecentDate) {
        mostRecentDate = date;
      }
    }
    // Check whether the date is not the default one.
    if (mostRecentDate.getFullYear() > 1970) {
      return mostRecentDate;
    }
    return null;
  }

  get CanCalibrate() {
    return (
      this.calibrationDatePerSide &&
      !(this.calibrationDatePerSide instanceof Array)
    );
  }

  /**
   * Returns whether the activity has valid calibration data.
   * @param side
   * @param isSideChoiceEnabled is activity side choice enabled
   * @return {boolean}
   */
  hasValidCalibration = (side = "") => {
    // First, let's discard the activities that do not require a calibration
    // - GardenRage_WorldFootsteps_1p
    // - Mind4_HorizReach_1p
    if (!this.CanCalibrate) {
      return true;
    }

    // Then, we check the activities that have a Side Choice
    // - Airplane_ForearmProSup_1p
    // - CarRacer_ShoulderAbdAdd_1p
    // - CrossTheRoad_ForwardBackwardSteps_1p
    // - CrossTheRoad_WristFlexionExtension_1p
    // - FlyingCar_ShouldersFlexExt_1p
    // - FlyingCar_WristFlexExt_1p
    // - Hovercraft_UnilateralShoulderAbdAdd_1p
    // - LineRoller_ForearmProSup_1p
    // - LineRoller_UniLateralFootStep_1p
    // - SkiLiner_WristRadialUlnarDeviation_1p
    // - TowerBuilder_FingersPinch_1p
    // - TowerBuilder_HandGraspRelease_1p // Note: movement has changed to HandOpenCloseMcpa
    // - WitcherDefense_ForearmProSup_1p
    // - Cauldron Chronicles
    if (this.isSideChoiceEnabled) {
      return this.calibrationDatePerSide.hasOwnProperty(
        this.convertSideAbbreviation(side)
      );
    }

    // Remains only the activities that are not Sided. They can be split in two groups:
    //  - the ones that have only non-sided gesture calibrator (1 or 2 of them)
    //    * Airplane_TrunkLatFlex_1p (1)
    //    * CarRacer_FakeSteeringWheel_1p (2)
    //    * CarRacer_TrunkAxialRotation_1p (1)
    //    * MineCart_FwdPumpTrunkLatFlex_1p (2)
    //    * Octopulse_Squat_1p (1)
    //    * SkiLiner_LateralBodyWeightTransfer_1p (1)
    //    * a667e5e3-5328-4844-b0b9-028eb8e680c6 -> japanese garden (2)
    if (
      this.id === "Airplane_TrunkLatFlex_1p" ||
      this.id === "CarRacer_FakeSteeringWheel_1p" ||
      this.id === "CarRacer_TrunkAxialRotation_1p" ||
      this.id === "MineCart_FwdPumpTrunkLatFlex_1p" ||
      this.id === "Octopulse_Squat_1p" ||
      this.id === "SkiLiner_LateralBodyWeightTransfer_1p" ||
      this.id === "a667e5e3-5328-4844-b0b9-028eb8e680c6"
    ) {
      return (
        this.calibrationDatePerSide.hasOwnProperty("Right") ||
        this.calibrationDatePerSide.hasOwnProperty("NotApplicable")
      );
    } else {
      // The activities that use Sided Gesture Provider and that require 2 of them
      // - Climbing_VerticalHandsReaching_1p
      // - Hovercraft_BilateralShouldersAbdAdd_1p
      // - LineRoller_BiLateralFootStep_1p
      // - CrossTheRoad_FreeSteps_1p
      return (
        this.calibrationDatePerSide.hasOwnProperty("Left") &&
        this.calibrationDatePerSide.hasOwnProperty("Right")
      );
    }
  };

  convertSideAbbreviation(sideLetter) {
    switch (sideLetter) {
      case "L":
        return "Left";
      case "R":
        return "Right";
      default:
        return "";
    }
  }

  // Network methods

  /**
   * Fetch patient activity usage
   * @param {string} username username of the patient
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static fetchPatientActivityUsageData(username, signal) {
    return this.api().get(`activity/therapist/patient/${username}`, {
      dataKey: "result",
      signal,
    });
  }
}
