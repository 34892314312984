import { Model } from "@vuex-orm/core";
import { parseISO } from "date-fns";
import Asset from "@/models/Asset.model";
export default class UserSession extends Model {
  static entity = "usersessions";

  static fields() {
    return {
      id: this.attr(null),
      mmid: this.attr(null),
      startDate: this.attr(null),
      endDate: this.attr(null),
      hasBeenPerformedOffline: this.attr(null),
      deviceId: this.attr(null),
      deviceSoftwareVersion: this.attr(null),
      productKind: this.attr(null),
      asset: this.hasOne(Asset, "deviceId", "deviceId"),
    };
  }

  get StartDate() {
    return this.startDate ? parseISO(this.startDate) : null;
  }
  get EndDate() {
    return this.endDate ? parseISO(this.endDate) : null;
  }

  static get(fromDate, toDate) {
    return this.api().get("/usersession", {
      dataKey: "result",
      params: {
        fromDate: fromDate,
        toDate: toDate,
      },
    });
  }

  static post(newUserSession) {
    return this.api().post("/usersession", newUserSession, {
      dataKey: "result",
    });
  }
}
