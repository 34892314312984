import { connectionStatus, libraryStates } from "@/plugins/vidyo/constants";

const resetState = (initialState, state) => {
  const newState = initialState();
  Object.keys(newState).forEach((key) => {
    state[key] = newState[key];
  });
};

const initialState = () => {
  return {
    libLoading: true,
    libStatus: null,
    connectionMessage: "",
    connectionState: connectionStatus.disconnected,
    version: "",
    cameraState: true,
    speakerState: true,
    microphoneState: true,
    cameras: [],
    microphones: [],
    speakers: [],
    selectedCamera: null,
    selectedMicrophone: null,
    selectedSpeaker: null,
    remoteParticipants: [],
    isSharingWindow: false,
    isLoadingShareWindow: false,
  };
};

function areEqualRemoteParticipants(p1, p2) {
  return p1.id == p2.id;
}

export default {
  namespaced: true,

  state: initialState,
  getters: {
    IsCameraOn(state) {
      return state.cameraState;
    },
    IsSpeakerOn(state) {
      return state.speakerState;
    },
    IsMicrophoneOn(state) {
      return state.microphoneState;
    },
    IsConnected(state) {
      return state.connectionState == connectionStatus.connected;
    },
    IsSharingWindow(state) {
      return state.isSharingWindow;
    },
    libraryState(state) {
      if (state.libStatus == null) {
        return libraryStates.notLoaded;
      }

      return state.libStatus.state;
    },
    Participants(state) {
      let names = state.remoteParticipants.map((p) => p.name);
      return [...new Set(names)];
    },
  },
  mutations: {
    setLoading(state, loading) {
      state.libLoading = loading;
    },
    setLibStatus(state, libStatus) {
      state.libStatus = libStatus;
    },
    setVersion(state, version) {
      state.version = version;
    },
    setCameraState(state, cameraState) {
      state.cameraState = cameraState;
    },
    setSpeakerState(state, speakerState) {
      state.speakerState = speakerState;
    },
    setMicrophoneState(state, microphoneState) {
      state.microphoneState = microphoneState;
    },
    setConnectionState(state, connectionState, connectionMessage) {
      state.connectionState = connectionState;
      state.connectionMessage = connectionMessage;
    },
    addRemoteParticipant(state, participant) {
      if (
        state.remoteParticipants.find((p) =>
          areEqualRemoteParticipants(p, participant)
        )
      ) {
        // Found the same connection id, so we remove the old participant first.
        let index = state.remoteParticipants.findIndex((p) =>
          areEqualRemoteParticipants(p, participant)
        );
        if (index != -1) {
          state.remoteParticipants.splice(index, 1);
        }
      }
      state.remoteParticipants.push(participant);
    },
    removeRemoteParticipant(state, participant) {
      let index = state.remoteParticipants.findIndex((p) =>
        areEqualRemoteParticipants(p, participant)
      );
      if (index != -1) {
        state.remoteParticipants.splice(index, 1);
      }
    },
    addCamera(state, camera) {
      if (state.cameras.find((c) => c.id == camera.id)) {
        this.removeCamera(state, camera);
      }

      state.cameras.push(camera);
    },
    removeCamera(state, camera) {
      let index = state.cameras.findIndex((c) => c.id == camera.id);
      state.cameras.splice(index, 1);
    },
    setSelectedCamera(state, camera) {
      state.selectedCamera = camera;
    },
    addMicrophone(state, microphone) {
      state.microphones.push(microphone);
    },
    removeMicrophone(state, microphone) {
      let index = state.microphones.findIndex((m) => m.id == microphone.id);
      state.microphones.splice(index, 1);
    },
    setSelectedMicrophone(state, microphone) {
      state.selectedMicrophone = microphone;
    },
    addSpeaker(state, speaker) {
      state.speakers.push(speaker);
    },
    removeSpeaker(state, speaker) {
      let index = state.speakers.findIndex((s) => s.id == speaker.id);
      state.speakers.splice(index, 1);
    },
    setSelectedSpeaker(state, speaker) {
      state.selectedSpeaker = speaker;
    },
    clearStore(state) {
      resetState(initialState, state);
    },
    clearParticipants(state) {
      state.remoteParticipants = [];
      state.localParticipantUsername = null;
    },
    shareWindow(state, isSharingWindow) {
      state.isSharingWindow = isSharingWindow;
    },
    loadShareWindow(state, loading) {
      state.isLoadingShareWindow = loading;
    },
  },
  actions: {
    setLoading({ commit }, loading) {
      commit("setLoading", loading);
    },
    setLibStatus({ commit }, libStatus) {
      commit("setLibStatus", libStatus);
    },
    setVersion({ commit }, version) {
      commit("setVersion", version);
    },
    setCameraState({ commit }, cameraState) {
      commit("setCameraState", cameraState);
    },
    setSpeakerState({ commit }, speakerState) {
      commit("setSpeakerState", speakerState);
    },
    setMicrophoneState({ commit }, microphoneState) {
      commit("setMicrophoneState", microphoneState);
    },
    setConnectionState({ commit }, connectionState, connectionMessage) {
      commit("setConnectionState", connectionState, connectionMessage);
    },
    addRemoteParticipant({ commit }, participant) {
      commit("addRemoteParticipant", participant);
    },
    removeRemoteParticipant({ commit }, participant) {
      commit("removeRemoteParticipant", participant);
    },
    addCamera({ commit }, camera) {
      commit("addCamera", camera);
    },
    removeCamera({ commit }, camera) {
      commit("removeCamera", camera);
    },
    setSelectedCamera({ commit }, camera) {
      commit("setSelectedCamera", camera);
    },
    addMicrophone({ commit }, microphone) {
      commit("addMicrophone", microphone);
    },
    removeMicrophone({ commit }, microphone) {
      commit("removeMicrophone", microphone);
    },
    setSelectedMicrophone({ commit }, microphone) {
      commit("setSelectedMicrophone", microphone);
    },
    addSpeaker({ commit }, speaker) {
      commit("addSpeaker", speaker);
    },
    removeSpeaker({ commit }, speaker) {
      commit("removeSpeaker", speaker);
    },
    setSelectedSpeaker({ commit }, speaker) {
      commit("setSelectedSpeaker", speaker);
    },
    clearParticipants({ commit }) {
      commit("clearParticipants");
    },
    clearStore({ commit }) {
      commit("clearStore");
    },
    shareWindow({ commit }, isSharingWindow) {
      commit("shareWindow", isSharingWindow);
    },
    loadShareWindow({ commit }, loading) {
      commit("loadShareWindow", loading);
    },
  },
};
