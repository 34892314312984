import { formatToDDMMMYYYYHmm } from "@/utils/date";
import { getYear, parseISO } from "date-fns";
import { tr } from "@/utils/translation";
import { buildNameFromFirstAndLastName } from "@/utils/profile";

// The structure of an empty soap text object
const emptySoapText = {
  username: "",
  firstname: "",
  lastname: "",
  text: "",
};

// All soap text names for the session report
const soapTextNames = ["subjective", "assessment", "plan"];

/**
 * This class is like an array for soaptexts.
 * It has the same structure as the soapdata in the backend
 * A soapData object has some SoapText objects these text objects represents the real data.
 */
export default class SoapData {
  /**
   * Generate the objects if they are not existing otherwise add them to the class
   * @param {object} soapData The soapdata object from the backend
   */
  constructor(soapData) {
    // If soapdata is null generate all necessary soaptext objects
    if (!soapData) {
      this.generateAll();
      return;
    }

    // Loop through all soaptext names
    soapTextNames.forEach((soapTextName) => {
      // If the soaptext object is not null add it to this soapdata instance otherwise generate the empty object and add it
      if (soapData[soapTextName]) {
        this[soapTextName] = soapData[soapTextName];
        // Handle empty date from backend (It is 01.01.01 instead of null)
        if (getYear(parseISO(this[soapTextName].lastEditDate)) > 1970) {
          this[soapTextName].lastEditDate = this[soapTextName].lastEditDate;
        } else {
          this[soapTextName].lastEditDate = undefined;
        }
      } else {
        this.generate(soapTextName);
      }

      // Add convenience getters
      this[soapTextName].translatedLatestEdit = () => {
        return this.translatedLatestEdit(soapTextName);
      };
    });
  }

  /**
   * Generate empty soaptext object for all soaptextnames
   */
  generateAll() {
    soapTextNames.forEach((soapTextName) => this.generate(soapTextName));
  }

  /**
   * Generate empty soaptext object for the given soaptextname
   * @param {string} name The soaptextname
   */
  generate(name) {
    this[name] = Object.assign({}, emptySoapText);
  }

  /**
   * Returns the formatted latest edit information string when the information is available.
   * @param soapName
   * @return {string}
   */
  translatedLatestEdit(soapName) {
    let author = this.textAuthor(soapName);
    if (this[soapName].lastEditDate && author.trim() !== "") {
      return tr("TherapistPatientManagementPage.ReviewPage.lastedited", {
        date: formatToDDMMMYYYYHmm(this[soapName].lastEditDate),
        name: author,
      });
    }
    return "";
  }

  /**
   * Returns the soap text author name.
   * @param soapName
   * @return {string}
   */
  textAuthor(soapName) {
    return buildNameFromFirstAndLastName(
      this[soapName].firstname,
      this[soapName].lastname
    );
  }
}
