import { resetState } from "@/store/utils";
import { defaultLocale } from "@/utils/locales";

const initialState = () => {
  return {
    email: null,
    username: null,
    language: defaultLocale,
  };
};

/**
 * Store used in the add patient process
 */
export default {
  namespaced: true,

  state: initialState,
  mutations: {
    /**
     * Set email
     * @param {object} state Vuex state
     * @param {string} token Email
     */
    setEmail(state, email) {
      state.email = email;
    },
    /**
     * Set username
     * @param {object} state Vuex state
     * @param {string} username Username
     */
    setUsername(state, username) {
      state.username = username;
    },
    /**
     * Set username
     * @param {object} state Vuex state
     * @param {string} username Username
     */
    setLanguage(state, language) {
      state.language = language;
    },
    /**
     * Clear all state variables
     * @param {object} state Vuex state
     */
    clearStore(state) {
      resetState(initialState, state);
    },
  },
  actions: {
    setEmail({ commit }, newState) {
      commit("setEmail", newState);
    },
    setUsername({ commit }, newState) {
      commit("setUsername", newState);
    },
    setLanguage({ commit }, newState) {
      commit("setLanguage", newState);
    },
    clearStore({ commit }) {
      commit("clearStore");
    },
  },
};
