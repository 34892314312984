// N.B.:  This method is neither placed into translation nor in language services to avoid a circular dependency.

import Store from "@/store/index";
import { getLanguageCodeFrom, defaultLanguage } from "@/utils/locales";

/**
 * Extract the localized text from a language based dictionary of the following type:
 * text[lang] = "localized text"
 * @param text
 * @return {string|*}
 */
export function extractLocale(text) {
  if (!text) return "";
  const langCode = getLanguageCodeFrom(Store.getters["Language/language"]);
  return text[langCode] || text[defaultLanguage];
}
