import { Model } from "@vuex-orm/core";

export default class PasswordOptions extends Model {
  static entity = "password-options";

  static fields() {
    return {
      requiredLength: this.attr(0),
      requiredUpperCase: this.attr(false),
      requiredLowerCase: this.attr(false),
      requiredDigits: this.attr(0),
    };
  }

  hasMinimalLength(password) {
    const lengthRegExp = new RegExp(`^.{${this.requiredLength},}$`);
    return !!password.match(lengthRegExp);
  }

  hasNumberOfUpperCase(password) {
    const alphaCapRegExp = new RegExp(
      `[A-Z\u0621-\u064A]{${this.requiredUpperCase}}` //Regex for A-Z and arabic characters
    );
    return !!password.match(alphaCapRegExp);
  }

  hasNumberOfLowerCase(password) {
    const alphaLowRegExp = new RegExp(
      `[a-z\u0621-\u064A]{${this.requiredLowerCase}}` //Regex for a-z and arabic characters
    );
    return !!password.match(alphaLowRegExp);
  }

  hasNumberOfDigits(password) {
    const numericRegExp = new RegExp(
      `[0-9\u0660-\u0669\u06F0-\u06F9]{${this.requiredDigits}}` //Regex for latin and arabic numbers
    );
    return !!password.match(numericRegExp);
  }

  isPasswordValid(password) {
    return (
      this.hasMinimalLength(password) &&
      this.hasNumberOfUpperCase(password) &&
      this.hasNumberOfLowerCase(password) &&
      this.hasNumberOfDigits(password)
    );
  }

  /**
   * Returns the password options
   * @returns {Promise<PasswordOptions>}
   */
  static async getCurrentPasswordOptions() {
    let passwordOptions = PasswordOptions.query().first();

    if (!passwordOptions) {
      await PasswordOptions.fetchPasswordOptions().then(() => {
        passwordOptions = PasswordOptions.query().first();
      });
    }

    return passwordOptions;
  }

  /**
   * Refreshes the password options data from back-end
   */
  static async fetchPasswordOptions() {
    return await this.api().get("/account/password-options", {
      dataKey: "result",
      persistBy: "create",
    });
  }
}
