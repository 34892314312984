import { Model } from "@vuex-orm/core";
import { extractLocale } from "@/utils/extractLocale";
import { parseISO } from "date-fns";
import { isEntityContainsAllTerms, tidySearchTerm } from "@/utils/filters";

export default class OptionalFeatureDefinition extends Model {
  static entity = "optionalFeatureDefinitions";

  static fields() {
    return {
      id: this.attr(null),
      addedAtUtc: this.attr(null),
      version: this.attr(null),
      featureKeyName: this.attr(null),
      featureVersion: this.attr(null),
      name: this.attr(null),
      description: this.attr(null),
    };
  }

  get AddedAtUtc() {
    return this.addedAtUtc ? parseISO(this.addedAtUtc) : null;
  }

  get Name() {
    let translatedName = extractLocale(this.name);
    return translatedName || this.featureKeyName;
  }

  get Description() {
    return extractLocale(this.description);
  }

  /**
   * Returns how many times a given feature definition is used.
   * @param featureActivationList
   */
  static getDefinitionTimesUsed(definition, featureActivationList) {
    return featureActivationList.reduce((previousValue, currentActivation) => {
      if (
        currentActivation.featureKeyName === definition.featureKeyName &&
        currentActivation.featureVersion === definition.featureVersion
      ) {
        return ++previousValue;
      }
      return previousValue;
    }, 0);
  }

  // Network methods

  // Returns the list of all optional feature definition
  static get() {
    return this.api().get("/optional-feature-definition", {
      dataKey: "result",
    });
  }

  static async post(newFeatureDefinition) {
    // Post the items as a list of one.
    return this.api().post(
      "/optional-feature-definition",
      [newFeatureDefinition],
      { dataKey: "result" }
    );
  }

  static async put(updatedFeature) {
    return this.api().put(
      `/optional-feature-definition/${updatedFeature.id}`,
      updatedFeature,
      { dataKey: "result" }
    );
  }

  static async remove(feature) {
    return this.api().delete(`/optional-feature-definition/${feature.id}`, {
      delete: feature.id,
      dataKey: "result",
    });
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    return [
      this.id,
      this.featureKeyName,
      this.featureVersion,
      this.Name,
      this.Description,
    ];
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }
}
