const storeName = "_vidyoStore";
const vidyoScript = "../../../static/VidyoClient.js";

const videoMaxes = {
  width: 768,
  height: 432,
};

const connectionStatus = {
  disconnected: "DISCONNECTED",
  connecting: "CONNECTING",
  disconnecting: "DISCONNECTING",
  connected: "CONNECTED",
  failed: "FAILED",
};

const libraryStates = {
  notLoaded: "",
  ready: "READY",
  retrying: "RETRYING",
  failed: "FAILED",
  failedVersion: "FAILEDVERSION",
  notAvailable: "NOTAVAILABLE",
  timedout: "TIMEDOUT",
};

export { storeName, vidyoScript, connectionStatus, libraryStates, videoMaxes };
