export default [
  {
    path: "dpoadmin",
    component: () =>
      import("@/pages/EntityManagementPages/DPOManagementPages/DPOListPage"),
  },
  {
    path: "dpoadmin/new",
    component: () =>
      import("@/pages/EntityManagementPages/DPOManagementPages/DPOCreatePage"),
  },
  {
    path: "dpoadmin/:username",
    component: () =>
      import("@/pages/EntityManagementPages/DPOManagementPages/DPODetailsPage"),
    props: (route) => ({ username: route.params.username }),
  },
];
