import { Model } from "@vuex-orm/core";

/**
 * This model represents a patient group
 */
export default class Group extends Model {
  static entity = "groups";

  static fields() {
    return {
      id: this.attr(null),
      siteId: this.attr(null),
      patientGroupName: this.attr(""),
      patientsInGroup: this.attr([]),
      addedAt: this.attr(null),
      isNew: this.attr(false),
      isEditing: this.attr(false),
    };
  }

  /**
   * Fetch all groups
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async fetchAllGroups(signal) {
    return await this.api().get("group/patientgroups", {
      dataKey: "result",
      signal,
    });
  }

  /**
   * Add group
   * @param {object} group The group
   */
  static async post(group) {
    return await this.api().post("group/patientgroup", group, {
      dataKey: "result",
    });
  }

  /**
   * Update group
   * @param {object} group The group
   */
  static async put(group) {
    return await this.api().put("group/patientgroup", group, {
      dataKey: "result",
    });
  }

  /**
   * Delete group
   * @param {string} groupId The groupid
   */
  static async remove(groupId) {
    return await this.api().delete(`group/patientgroup/${groupId}`, {
      delete: groupId,
      dataKey: "result",
    });
  }
}
