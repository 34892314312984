/**
 *  This script is in charge of loading and unloading the external library.
 *
 */

import Vue from "vue";
import { vidyoScript } from "@/plugins/vidyo/constants";

/**
 * Function to inject the external library for vidyo
 * @returns {Promise<T>}
 */
const injectVidyoLib = async function () {
  return Vue.loadScript(`${vidyoScript}`);
};

const unloadVidyoLib = async () => {
  return Vue.unloadScript(`${vidyoScript}`)
    .then(() => {
      console.log("Unloaded vidyo external script");
    })
    .catch((error) => {
      console.log("Failed to unload vidyo conf");
    })
    .finally(() => {
      return;
    });
};

export { injectVidyoLib, unloadVidyoLib };
