import Store from "@/store";

export default [
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
      import("@/pages/CommonPages/LegalPages/PrivacyPolicyViewPage.vue"),
  },
];
