import { THERAPIST_ROLE, SITEMANAGER_ROLE } from "@/services/auth.js";

export default [
  {
    path: "site-units",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteUnitManagementPages/SiteUnitListPage"
      ),
  },
  {
    path: "site-units/new",
    component: () => import("@/pages/SiteManagerPages/SiteUnit/AddSiteUnit"),
    props: (route) => ({ siteId: route.params.siteId }),
  },
  {
    path: "site-units/assigned/:siteUnitId/:tab",
    component: () => import("@/pages/SiteManagerPages/SiteUnit/EditSiteUnit"),
    props: (route) => ({
      siteUnitId: route.params.siteUnitId,
      tab: route.params.tab,
    }),
  },
  {
    path: "site-units/assigned/:siteUnitId/:tab/new",
    component: () =>
      import("@/pages/SiteManagerPages/SiteManagerHomePage/AddUser"),
    props: (route) => ({
      siteUnitId: route.params.siteUnitId,
      tab: route.params.tab,
      "user-type":
        route.params.tab === "therapists" ? THERAPIST_ROLE : SITEMANAGER_ROLE,
    }),
  },
  {
    path: "site-units/assigned/:siteUnitId/:tab/:username",
    component: () =>
      import("@/pages/SiteManagerPages/SiteManagerHomePage/EditUser"),
    props: (route) => ({
      siteUnitId: route.params.siteUnitId,
      tab: route.params.tab,
      username: route.params.username,
    }),
  },
  {
    path: "site-units/unassigned/:tab/:username",
    component: () =>
      import("@/pages/SiteManagerPages/SiteManagerHomePage/EditUser"),
    props: (route) => ({
      tab: route.params.tab,
      username: route.params.username,
    }),
  },
  {
    path: "site-units/unassigned/:tab",
    component: () =>
      import("@/pages/SiteManagerPages/SiteUnit/UnassignedUsersPage"),
    props: (route) => ({
      tab: route.params.tab,
      unassignedUserIds: route.params.unassignedUserIds,
    }),
  },
];
