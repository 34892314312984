import { parseISO } from "date-fns";

export default class ProgramNote {
  constructor(note) {
    if (note) {
      this.text = note.text;
      this.lastEditDate = note.lastEditDate;
      this.firstname = note.firstname;
      this.lastname = note.lastname;
    } else {
      this.text = "";
      this.lastEditDate = null;
      this.firstname = null;
      this.lastname = null;
    }
  }

  get LastEditDate() {
    return parseISO(this.lastEditDate);
  }
}
