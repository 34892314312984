export default [
  {
    path: "site-manager",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteManagerManagementPages/SiteManagerListPage"
      ),
  },
  {
    path: "site-manager/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteManagerManagementPages/SiteManagerCreationPage"
      ),
    props: (route) => ({
      siteId: route.query.siteId,
    }),
  },
  {
    path: "site-manager/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteManagerManagementPages/SiteManagerDetailsPage"
      ),
    props: true,
  },
];
