import { tr } from "@/utils/translation";
import { clamp } from "@/utils/math";

/**
 * Get minutes from seconds
 * @param {number} seconds The seconds
 */
function getMinutesFromSeconds(seconds) {
  return Math.round(seconds / 60);
}

function getMinutesFromSecondsRoundUpper(seconds) {
  return Math.ceil(seconds / 60);
}

function getDaysAndHoursFromHours(hours) {
  let displayHours = hours % 24;
  let displayDays = Math.round(hours - displayHours) / 24;
  return [displayDays, displayHours];
}

/**
 * Get hours and minutes string from minutes
 * @param {number} minutes The minutes
 */
function getHoursAndMinutesFromMinutes(minutes) {
  let displayMinutes = minutes % 60;
  let displayHours = Math.round(minutes - displayMinutes) / 60;
  return [displayHours, displayMinutes];
}

/**
 * Get minutes and seconds array of values from seconds
 * @param {number} seconds The seconds
 */
function getMinutesAndSecondsFromSeconds(seconds) {
  let roundedSeconds = Math.round(seconds);
  let displayedSeconds = roundedSeconds % 60;
  let displayedMinutes = (roundedSeconds - displayedSeconds) / 60;
  return [displayedMinutes, displayedSeconds];
}

function getAdaptiveTranslatedTimeFromSeconds(
  seconds,
  withoutZeroUnit = false,
  withoutSeconds = false
) {
  // Returns '<1s' when between 0 and 1s
  if (seconds > 0 && seconds < 1) {
    return tr("Common.DurationWithValues.lessThanOneSecondShort");
  }
  // Returns two biggest units
  seconds = Math.round(seconds); // Avoid 59.55+ issues.
  if (seconds >= 60) {
    let minutesSeconds = getMinutesAndSecondsFromSeconds(seconds);
    // If the time is bigger than 60 minutes, we swap to hour display
    if (minutesSeconds[0] >= 60) {
      let hoursMinutes = getHoursAndMinutesFromMinutes(minutesSeconds[0]);
      if (hoursMinutes[1] == 0 && withoutZeroUnit) {
        return tr("Common.DurationWithValues.hoursDisplayWithLegends", {
          hours: hoursMinutes[0],
        });
      }
      return tr("Common.DurationWithValues.hoursMinutesDisplayWithLegends", {
        hours: hoursMinutes[0].toString(),
        minutes: convertToTwoDigitValue(hoursMinutes[1]),
      });
    }
    if ((minutesSeconds[1] == 0 && withoutZeroUnit) || withoutSeconds) {
      return tr("Common.DurationWithValues.minutesDisplayWithLegends", {
        minutes: minutesSeconds[0],
      });
    }
    return tr("Common.DurationWithValues.minutesSecondsDisplayWithLegends", {
      minutes: minutesSeconds[0].toString(),
      seconds: convertToTwoDigitValue(minutesSeconds[1]),
    });
  }

  // Returns single digit if the duration is less than 10 seconds
  return tr("Common.DurationWithValues.secondsDisplayWithLegends", {
    seconds: seconds < 10 ? seconds : convertToTwoDigitValue(seconds),
  });
}

/**
 * Indicates if the "seconds" unit is obsolete between several durations in the case
 * where all durations are perfectly equal to a multiple of minutes or hours
 * @param values
 * @return true if all values are perfectly equal to a multiple of minutes or
 * hours, false if one or several values contains seconds
 */
function isSmallestUnitObsoleteBetweenSeveralDuration(values) {
  if (values.some((val) => val >= 3600)) {
    let res = true;
    values.forEach((val) => {
      res = res && (val / 3600) % 60 === 0;
    });
    return res;
  }
  let res = true;
  values.forEach((val) => {
    res = res && val % 60 === 0;
  });
  return res;
}

function getBiggestUnitBetweenSeveralDuration(values) {
  let biggestValue = Math.max(...values);

  if (Math.round(biggestValue) >= 3600) {
    return 0; // hour
  } else if (Math.round(biggestValue) >= 60) {
    return 1; // minute
  } else {
    return 2; // second
  }
}

function getAlignedDurationDataWithBiggestUnit(value, biggestUnit, nUnits = 2) {
  let durationData = getAlignedDurationData(value);

  // Ensure that the number of unit is correct
  nUnits = clamp(nUnits, 1, durationData.length);

  // Remove the values above the biggest unit
  for (let i = 0; i < biggestUnit; i++) {
    durationData[i].value = "";
    durationData[i].unit = "";
  }

  // If we display only 1 unit, we should handle the edge case where the duration is zero or smaller than biggest unit
  if (nUnits == 1) {
    if (value == 0) {
      durationData[biggestUnit].value = "0";
      return durationData.slice(biggestUnit, biggestUnit + nUnits);
    }
    if (
      (biggestUnit == 0 && Math.round(value) < 3600) ||
      (biggestUnit == 1 && Math.round(value) < 60) ||
      (biggestUnit == 2 && value < 1)
    ) {
      durationData[biggestUnit].value = tr(
        "Common.DurationWithValues.lessThanOne"
      );
      return durationData.slice(biggestUnit, biggestUnit + nUnits);
    }
  }

  // If we display only 2 units, we should handle the edge cases where the duration is zero or too low compared to the biggest unit
  if (nUnits == 2) {
    // If biggest unit is [hours]
    if (biggestUnit == 0) {
      // If the duration is 0, we should display 0 min (instead of 0 sec)
      if (value == 0) {
        durationData[biggestUnit].value = "";
        durationData[biggestUnit].unit = "";
        durationData[biggestUnit + 1].value = "0";
        return durationData.slice(biggestUnit, biggestUnit + nUnits);
      }
      // If the duration is less than 60, we should display <1 min
      if (Math.round(value) < 60) {
        durationData[biggestUnit].value = "";
        durationData[biggestUnit].unit = "";
        durationData[biggestUnit + 1].value = tr(
          "Common.DurationWithValues.lessThanOne"
        );
        return durationData.slice(biggestUnit, biggestUnit + nUnits);
      }
    }
  }

  // If the biggest unit is bigger than [seconds] and contains double zeros, we remove them
  if (
    biggestUnit != durationData.length - 1 &&
    durationData[biggestUnit].value == "00"
  ) {
    durationData[biggestUnit].value = "";
    durationData[biggestUnit].unit = "";

    // If the second biggest unit contains a trailing zero, we remove it
    if (durationData[biggestUnit + 1].value.charAt(0) == "0") {
      durationData[biggestUnit + 1].value =
        durationData[biggestUnit + 1].value.charAt(1);
    }
    return durationData.slice(biggestUnit, biggestUnit + nUnits);
  }
  // If the biggest unit contains a trailing zero, we remove it
  if (durationData[biggestUnit].value.charAt(0) == "0") {
    durationData[biggestUnit].value = durationData[biggestUnit].value.charAt(1);
  }
  return durationData.slice(biggestUnit, biggestUnit + nUnits);
}

/**
 * Returns a fixed formatted duration used for stylized labels with h/m/s
 * @param value
 * @return array of objects with unit and values properties for h/m/s
 */
function getAlignedDurationData(value) {
  value = Math.round(value); // Avoid 59.55+ issues.
  let durations = [];
  // Create duration data table
  for (let i = 0; i < 3; i++) {
    durations.push({
      value: convertToTwoDigitValue(0),
      unit:
        i == 0
          ? tr("Common.Duration.hoursShort")
          : i == 1
          ? tr("Common.Duration.minutesShort")
          : tr("Common.Duration.secondsShort"),
    });
  }
  // Fill duration data table
  while (value >= 60) {
    if (value >= 3600) {
      let hours = Math.floor(value / 3600);
      durations[0].value = convertToTwoDigitValue(hours);
      value = value % 3600;
    } else if (value >= 60) {
      let minutes = Math.floor(value / 60);
      durations[1].value = convertToTwoDigitValue(minutes);
      value = value % 60;
    }
  }
  if (value > 0) {
    let seconds = Math.round(value);
    durations[2].value = convertToTwoDigitValue(seconds);
  }

  return durations;
}

/**
 * Get minutes and seconds string from seconds
 * @param {number} seconds The seconds
 */
function getMinutesAndSecondsFromSecondsAsFormattedString(seconds) {
  let minutesSeconds = getMinutesAndSecondsFromSeconds(seconds);
  return tr("Common.DurationWithValues.minutesSecondsDisplayWithLegends", {
    minutes: convertToTwoDigitValue(minutesSeconds[0]),
    seconds: convertToTwoDigitValue(minutesSeconds[1]),
  });
}

function convertToTwoDigitValue(value) {
  return value.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
}

export {
  getMinutesAndSecondsFromSeconds,
  getMinutesFromSeconds,
  getHoursAndMinutesFromMinutes,
  getMinutesFromSecondsRoundUpper,
  getMinutesAndSecondsFromSecondsAsFormattedString,
  getAdaptiveTranslatedTimeFromSeconds,
  getAlignedDurationData,
  getAlignedDurationDataWithBiggestUnit,
  getBiggestUnitBetweenSeveralDuration,
  isSmallestUnitObsoleteBetweenSeveralDuration,
  getDaysAndHoursFromHours,
};
