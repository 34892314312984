<template>
  <div>
    <p v-for="error in errors" class="py-1 text-red-500" :key="error">
      {{ error }}
    </p>
  </div>
</template>
<script>
// @vuese
// component to display errors around an input
// @group 3. UI/Base
export default {
  props: {
    errors: {
      type: Array,
      required: true,
    },
  },
};
</script>
