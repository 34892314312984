import { startOfDay, endOfDay } from "date-fns";
import { clamp } from "@/utils/math";

const aggregate = (results, aggregateFcn) => {
  const aggregatedValues = [];

  for (let date in results) {
    let value = results[date].reduce(aggregateFcn, 0.0) / results[date].length;
    aggregatedValues.push([new Date(date).getTime(), value]);
  }

  return aggregatedValues;
};

const sumAggregate = (results, aggregateFcn) => {
  const aggregatedValues = [];

  for (let date in results) {
    let value = results[date].reduce(aggregateFcn, 0.0);
    aggregatedValues.push([new Date(date).getTime(), value]);
  }

  return aggregatedValues;
};

/**
 * N.B.: This expects series as an array of the following format:
 * [[date1, value1], [date2, value2], ... ]
 * @param values
 * @return {number}
 */
const average = (values) => {
  if (values.length === 0) {
    return 0;
  }
  return (
    values.reduce((accumulator, v) => accumulator + v[1], 0.0) / values.length
  );
};

/**
 * N.B.: This expects series as an array of the following format:
 * [[date1, value1], [date2, value2], ... ]
 * @param values
 * @return {number}
 */
const sum = (values) => {
  return values.reduce((accumulator, v) => accumulator + v[1], 0.0);
};

const getStandardizedMovementUnitSeries = (
  results,
  movementId,
  movementSide
) => {
  const aggregateFcn = (accumulator, v) =>
    accumulator + v.getStandardizedMovementUnits(movementId, movementSide);
  return sumAggregate(results, aggregateFcn);
};

const getMovementIntensitySeries = (results, movementId, movementSide) => {
  let aggregatedValues = [];

  for (const [date, datedItems] of Object.entries(results)) {
    let totalActiveTime = 0;
    let totalSMU = 0;

    for (const activity of datedItems) {
      totalActiveTime += activity.activeTrainingTime;
      totalSMU += activity.getStandardizedMovementUnits(
        movementId,
        movementSide
      );
    }
    let intensity =
      Math.round(totalActiveTime) > 0
        ? (60 * totalSMU) / Math.round(totalActiveTime)
        : 0.0;
    aggregatedValues.push([new Date(date).getTime(), intensity]);
  }
  return aggregatedValues;
};

const getActiveTrainingTimesSeries = (results) => {
  const aggregateFcn = (accumulator, v) => accumulator + v.activeTrainingTime;
  return sumAggregate(results, aggregateFcn);
};

const getCompensationTimesSeries = (results) => {
  const aggregateFcn = (accumulator, v) => accumulator + v.compensationDuration;
  return sumAggregate(results, aggregateFcn);
};

const getAdherencesSeries = (results) => {
  let aggregatedValues = [];

  for (const [date, datedItems] of Object.entries(results)) {
    let totalAdherencePerDay = 0;
    let totalActiveTime = 0;
    let totalPlanningTime = 0;

    for (const activity of datedItems) {
      totalPlanningTime += activity.plannedSeconds;
      totalActiveTime += activity.trainingTimeAccordingToPlan;
    }
    // If nothing was planned, this day should not appear in the adherence serie
    if (totalPlanningTime == 0) {
      continue;
    }
    totalAdherencePerDay = computeAdherence(totalActiveTime, totalPlanningTime);
    aggregatedValues.push([new Date(date).getTime(), totalAdherencePerDay]);
  }
  return aggregatedValues;
};

const getQualityOfMovementsSeries = (results) => {
  let aggregatedValues = [];

  for (const [date, datedItems] of Object.entries(results)) {
    let totalQualityPerDay = 0;
    let totalActiveTime = 0;
    let totalCompensationTime = 0;

    for (const activity of datedItems) {
      totalCompensationTime += activity.compensationDuration;
      totalActiveTime += activity.activeTrainingTime;
    }
    totalQualityPerDay = computeQualityPercentage(
      totalActiveTime,
      totalCompensationTime
    );
    aggregatedValues.push([new Date(date).getTime(), totalQualityPerDay]);
  }
  return aggregatedValues;
};

const getTotalNormalizedQuantityOfMovement = (
  results,
  movementId,
  movementSide
) => {
  return sum(
    getStandardizedMovementUnitSeries(results, movementId, movementSide)
  );
};

const getTotalActiveTrainingTime = (results) => {
  return sum(getActiveTrainingTimesSeries(results));
};

const getTotalCompensationTime = (results) => {
  return sum(getCompensationTimesSeries(results));
};

const getTotalQualityOfMovement = (results) => {
  let totalActiveTime = getTotalActiveTrainingTime(results);
  let totalCompensationTime = getTotalCompensationTime(results);
  return computeQualityPercentage(totalActiveTime, totalCompensationTime);
};

const getTotalAdherence = (result) => {
  let totalActiveTime = 0;
  let totalPlanningTime = 0;
  for (const [date, datedItems] of Object.entries(result)) {
    for (const activity of datedItems) {
      totalActiveTime += activity.trainingTimeAccordingToPlan;
      totalPlanningTime += activity.plannedSeconds;
    }
  }
  return computeAdherence(totalActiveTime, totalPlanningTime);
};

const computeQualityPercentage = (
  totalActiveTime,
  totalCompensationDuration
) => {
  let totalTimeWithoutCompensation =
    totalActiveTime - totalCompensationDuration;
  return totalActiveTime > 0
    ? clamp(totalTimeWithoutCompensation / totalActiveTime, 0.0, 1.0) * 100
    : 0;
};

const computeCompensationPercentage = (
  totalActiveTime,
  totalCompensationDuration
) => {
  return totalActiveTime > 0
    ? clamp(totalCompensationDuration / totalActiveTime, 0.0, 1.0) * 100
    : 0;
};

const computeAdherence = (totalActiveTime, totalPlanningTime) => {
  return totalPlanningTime > 0
    ? Math.round(clamp(totalActiveTime / totalPlanningTime, 0.0, 1.0) * 100)
    : 0;
};

const filterByDate = (result, startDate, endDate) => {
  return (
    result.Date !== null &&
    (startDate === null ||
      startOfDay(startDate).getTime() <= result.Date.getTime()) &&
    (endDate === null || result.Date.getTime() <= endOfDay(endDate).getTime())
  );
};

const filterBySessionType = (result, sessionTypes) => {
  return sessionTypes.has(result.SessionType);
};

const filterByTrainedFunction = (result, trainedFunction) => {
  return (
    trainedFunction === "All" ||
    result.trainedFunctions.includes(trainedFunction)
  );
};

const filterByMovement = (result, movementId) => {
  return (
    movementId === "All" ||
    result.DetailedMovementIds.includes(movementId) ||
    movementId === result.GlobalMovementId
  );
};

const filterByMovementAndSide = (result, movementId, movementSide) => {
  if (movementSide === "All") {
    return result.getMovementSides(movementId).length > 0;
  }
  return result.getMovementSides(movementId).includes(movementSide);
};

const filterByActiveSide = (result, activeSide) => {
  return activeSide === "All" || activeSide === result.ActiveSide;
};

/**
 * Session supervision supported types.
 * Also used for categories and for translation purposes.
 */
const SessionSupervisionTypeKeys = {
  SupervisedBySelf: "SupervisedBySelf",
  Supervised: "Supervised",
  Unsupervised: "Unsupervised",
};

export {
  getStandardizedMovementUnitSeries,
  getMovementIntensitySeries,
  getActiveTrainingTimesSeries,
  getQualityOfMovementsSeries,
  getCompensationTimesSeries,
  getTotalNormalizedQuantityOfMovement,
  getAdherencesSeries,
  getTotalCompensationTime,
  getTotalAdherence,
  getTotalActiveTrainingTime,
  getTotalQualityOfMovement,
  computeQualityPercentage,
  computeCompensationPercentage,
  computeAdherence,
  filterByDate,
  filterBySessionType,
  filterByTrainedFunction,
  filterByMovement,
  filterByMovementAndSide,
  filterByActiveSide,
  SessionSupervisionTypeKeys,
};
