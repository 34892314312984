export default [
  {
    path: "/onboarding/privacy-policy",
    name: "onboarding/privacy-policy",
    component: () =>
      import("@/pages/CommonPages/OnboardingPages/PrivacyAcceptPage"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/onboarding/set-password",
    name: "onboarding/set-password",
    component: () =>
      import("@/pages/CommonPages/OnboardingPages/SetPasswordPage"),
    props: (route) => ({
      userId: route.query.id,
      token: route.query.token,
      emailLanguage: route.query.culture,
    }),
  },
  {
    path: "/onboarding/verify-email",
    name: "onboarding/verify-email",
    component: () =>
      import("@/pages/CommonPages/OnboardingPages/VerifyEmailPage"),
    props: (route) => ({
      userId: route.query.id,
      token: route.query.token,
      emailLanguage: route.query.culture,
    }),
  },
  {
    path: "/onboarding/change-email",
    name: "onboarding/change-email",
    component: () =>
      import("@/pages/CommonPages/OnboardingPages/ChangeEmailPage"),
    props: (route) => ({
      userId: route.query.id,
      newEmail: route.query.newEmail,
      token: route.query.token,
      emailLanguage: route.query.culture,
    }),
  },
  {
    path: "/onboarding/consent-form",
    name: "onboarding/consent-form",
    component: () => import("@/pages/CommonPages/OnboardingPages/ConsentPage"),
    props: (route) => ({ userId: route.query.id, token: route.query.token }),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/onboarding/token-expired",
    name: "onboarding/token-expired",
    component: () =>
      import("@/pages/CommonPages/OnboardingPages/TokenExpiredPage"),
  },
  {
    path: "/onboarding/change-email-token-expired",
    name: "onboarding/change-email-token-expired",
    component: () =>
      import("@/pages/CommonPages/OnboardingPages/ChangeEmailTokenExpiredPage"),
  },
  {
    path: "/onboarding/requestlink",
    name: "requestLink",
    component: () =>
      import("@/pages/CommonPages/OnboardingPages/RequestActivationLinkPage"),
  },
];
