export default [
  /**
   * Patients management routes (DPO)
   */
  {
    path: "site-management/:siteId/patients/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientManagementPages/PatientDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      username: route.params.username,
    }),
  },
  /**
   * Therapists management routes
   */
  {
    path: "site-management/:siteId/therapists/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/TherapistManagementPages/TherapistCreationPage"
      ),
    props: (route) => ({ siteId: route.params.siteId }),
  },
  {
    path: "site-management/:siteId/therapists/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/TherapistManagementPages/TherapistDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      username: route.params.username,
    }),
  },
  /**
   * Site managers management routes
   */
  {
    path: "site-management/:siteId/site-managers/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteManagerManagementPages/SiteManagerCreationPage"
      ),
    props: (route) => ({ siteId: route.params.siteId }),
  },
  {
    path: "site-management/:siteId/site-managers/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteManagerManagementPages/SiteManagerDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      username: route.params.username,
    }),
  },
  /**
   * Customers management routes
   */
  {
    path: "site-management/:siteId/customers/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/CustomerManagementPages/CustomerCreatePage"
      ),
    props: (route) => ({
      entityIdentifier: route.query.entityIdentifier,
      siteId: route.params.siteId,
    }),
  },
  {
    path: "site-management/:siteId/customers/:customerId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/CustomerManagementPages/CustomerDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      customerId: route.params.customerId,
    }),
  },
  /**
   * Licenses management routes
   */
  {
    path: "site-management/:siteId/licences/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/LicenseManagementPages/LicenseCreatePage"
      ),
    props: (route) => ({ siteId: route.params.siteId }),
  },
  {
    path: "site-management/:siteId/licences/:licenseId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/LicenseManagementPages/LicenseDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      licenseId: route.params.licenseId,
    }),
  },
  /**
   * Patient licenses management routes
   */
  {
    path: "site-management/:siteId/licences/patient-license/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientLicenseManagementPages/PatientLicenseCreatePage"
      ),
    props: (route) => ({ siteId: route.params.siteId }),
  },
  {
    path: "site-management/:siteId/licences/patient-license/:licenseId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientLicenseManagementPages/PatientLicenseDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      licenseId: route.params.licenseId,
    }),
  },
  /**
   * Assets management routes
   */
  {
    path: "site-management/:siteId/assets/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/AssetManagementPages/AssetCreatePage"
      ),
    props: (route) => ({ siteId: route.params.siteId }),
  },
  {
    path: "site-management/:siteId/assets/:assetId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/AssetManagementPages/AssetDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      assetId: route.params.assetId,
    }),
  },
  /**
   * Site units management routes
   */
  {
    path: "site-management/:siteId/site-units/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteUnitManagementPages/SiteUnitCreatePage"
      ),
    props: (route) => ({ siteId: route.params.siteId }),
  },
  {
    path: "site-management/:siteId/site-units/assigned/:siteUnitId/:tab",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteUnitManagementPages/SiteUnitDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      siteUnitId: route.params.siteUnitId,
      tab: route.params.tab,
    }),
  },
  {
    path: "site-management/:siteId/site-units/assigned/:siteUnitId/site-managers/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteManagerManagementPages/SiteManagerCreationPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      siteUnitId: route.params.siteUnitId,
    }),
  },
  {
    path: "site-management/:siteId/site-units/assigned/:siteUnitId/site-managers/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteManagerManagementPages/SiteManagerDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      siteUnitId: route.params.siteUnitId,
      username: route.params.username,
    }),
  },
  {
    path: "site-management/:siteId/site-units/assigned/:siteUnitId/therapists/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/TherapistManagementPages/TherapistCreationPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      siteUnitId: route.params.siteUnitId,
    }),
  },
  {
    path: "site-management/:siteId/site-units/assigned/:siteUnitId/therapists/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/TherapistManagementPages/TherapistDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      siteUnitId: route.params.siteUnitId,
      username: route.params.username,
    }),
  },
  {
    path: "site-management/:siteId/site-units/assigned/:siteUnitId/patients/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientManagementPages/PatientDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      siteUnitId: route.params.siteUnitId,
      username: route.params.username,
    }),
  },
  {
    path: "site-management/:siteId/site-units/unassigned/:tab",
    component: () =>
      import("@/pages/SiteManagerPages/SiteUnit/UnassignedUsersPage"),
    props: (route) => ({
      tab: route.params.tab,
      unassignedUserIds: route.params.unassignedUserIds,
      siteId: route.params.siteId,
    }),
  },
  {
    path: "site-management/:siteId/site-units/unassigned/therapists/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/TherapistManagementPages/TherapistDetailsPage"
      ),
    props: (route) => ({
      username: route.params.username,
      siteId: route.params.siteId,
    }),
  },
  {
    path: "site-management/:siteId/site-units/unassigned/site-managers/:username",
    component: () =>
      import(
        "@/pages/EntityManagementPages/SiteManagerManagementPages/SiteManagerDetailsPage"
      ),
    props: (route) => ({
      username: route.params.username,
      siteId: route.params.siteId,
    }),
  },
  // For DPO
  {
    path: "site-management/:siteId/site-units/unassigned/patients/:username/profile",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientManagementPages/PatientDetailsPage"
      ),
    props: (route) => ({
      siteId: route.params.siteId,
      username: route.params.username,
      unassignedUser: true,
    }),
  },
];
