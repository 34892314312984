import { Model } from "@vuex-orm/core";
import { extractLocale } from "@/utils/extractLocale";
import ActivityPatientUsage from "@/models/ActivityPatientUsage.model";
import {
  isEntityContainsAllTerms,
  isEntityContainsOneTerm,
  tidySearchTerm,
} from "@/utils/filters";
import { tr } from "@/utils/translation";
import { lowerFirst } from "lodash";

export default class ActivityDescription extends Model {
  static entity = "activities";

  static fields() {
    return {
      id: this.attr(null), // Primary key
      name: this.attr(null),
      description: this.attr(null),
      icon: this.attr(null),
      globalMovementId: this.attr(""),
      detailedMovements: this.attr([]),
      tutorialVideoUrl: this.attr(null),
      movementName: this.attr(null),
      movementIcon: this.attr(null),
      movementDescription: this.attr(null),
      therapeuticDescription: this.attr(null),
      isBodyTrackerNeeded: this.attr(null),
      isHandsTrackerNeeded: this.attr(null),
      isIzarTrackerNeeded: this.attr(null),
      isActivityMovementSetupNeeded: this.attr(null),
      tags: this.attr([]),
      trainedFunctions: this.attr([]),
      compensationMovements: this.attr([]),
      gestureProviders: this.attr([]),
      activityOptions: this.attr([]),
      compensatoryDetectionDescription: this.attr({}),
      levelProgressionDescription: this.attr({}),
      isLicensedForUser: this.attr(false),
      // Foreign data
      patientUsage: this.hasOne(ActivityPatientUsage, "id"),
    };
  }

  //
  // Metadata getters

  get Name() {
    return extractLocale(this.name);
  }

  get MovementName() {
    return extractLocale(this.movementName);
  }

  get Description() {
    return extractLocale(this.description);
  }

  get MovementDescription() {
    return this.getTranslatedMultilineText(this.movementDescription);
  }

  /**
   * Entity fields to search into
   */
  get entityFieldsToSearchInto() {
    let fields = [];
    if (this.Name) {
      fields.push(tidySearchTerm(this.Name));
    }
    if (this.MovementName) {
      fields.push(tidySearchTerm(this.MovementName));
    }
    fields.push(
      this.trainedFunctions
        .map((trainedFunction) =>
          tr("Common.Activity.TrainedFunctions." + lowerFirst(trainedFunction))
        )
        .join("")
    );
    return fields;
  }

  /**
   * Return true iff all terms correspond to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingToAll(searchTerms) {
    return isEntityContainsAllTerms(this.entityFieldsToSearchInto, searchTerms);
  }

  /**
   * Return true iff one term corresponds to the entity
   * @param {string} searchTerms terms are space separated
   */
  isEntityCorrespondingTo(searchTerms) {
    return isEntityContainsOneTerm(this.entityFieldsToSearchInto, searchTerms);
  }

  get CompensatoryDetectionDescription() {
    return this.getTranslatedMultilineText(
      this.compensatoryDetectionDescription
    );
  }

  get TherapeuticDescription() {
    return this.getTranslatedMultilineText(this.therapeuticDescription);
  }

  get LevelDescription() {
    return this.getTranslatedMultilineText(this.levelProgressionDescription);
  }

  get Icon() {
    try {
      const image = require(`@/assets/games_icons/${this.icon}.png`);
      return image;
    } catch {
      const image = require(`@/assets/games_icons/Default_GameIcon.png`);
      return image;
    }
  }

  /**
   * Activity ids that should have the "NEW" badge
   */
  get IsNew() {
    return false;
  }

  /**
   * Returns a specific text, replacing new lines by '<br />' for later html parsing.
   * @param textArray
   * @return {string}
   */
  getTranslatedMultilineText(textArray) {
    const value = extractLocale(textArray);
    if (!value) {
      return "";
    }
    // N.B.: Using regex, as the standard expression only replaces the first occurrence.
    return value.replace(/\n/g, "<br />");
  }

  //
  // Network methods

  // Returns the list of all activities WITHOUT information
  // on whether the activity is licensed for the given user.
  static fetchAllActivityInformation() {
    return this.api().get("/activity", { dataKey: "result" });
  }

  /**
   * Returns the list of all activities but with information
   * on whether the activity is licensed for the given user.
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static fetchLicensedActivityInformation(signal) {
    return this.api().get("/activity/licensed", {
      dataKey: "result",
      signal,
    });
  }
}
