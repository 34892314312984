export default [
  {
    path: "/reset-password/request",
    name: "forgotPassword",
    component: () => import("@/pages/CommonPages/ForgotPasswordPage"),
  },
  {
    path: "/reset-password/reset",
    name: "resetPassword",
    component: () => import("@/pages/CommonPages/ResetPasswordPage"),
    props: (route) => ({
      userId: route.query.id,
      token: route.query.token,
      title: route.params.title,
      userEmail: route.params.email,
    }),
  },
  {
    path: "/reset-password/expired",
    name: "ResetPasswordExpired",
    component: () =>
      import("@/pages/CommonPages/PasswordResetTokenExpiredPage"),
    props: (route) => ({ autoCloseWindow: route.params.autoCloseWindow }),
  },
  {
    path: "/reset-password/confirmation",
    name: "confirmation",
    component: () =>
      import("@/pages/CommonPages/ResetPasswordPage/ConfirmationPage"),
    props: (route) => ({ autoCloseWindow: route.params.autoCloseWindow }),
  },
];
