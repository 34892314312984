export default [
  {
    path: "patient-license",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientLicenseManagementPages/PatientLicenseListPage"
      ),
  },
  {
    path: "patient-license/new",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientLicenseManagementPages/PatientLicenseCreatePage"
      ),
  },
  {
    path: "patient-license/:licenseId",
    component: () =>
      import(
        "@/pages/EntityManagementPages/PatientLicenseManagementPages/PatientLicenseDetailsPage"
      ),
    props: (route) => ({ licenseId: route.params.licenseId }),
  },
];
