import Vue from "vue";
import App from "./App.vue";

import router from "@/router/";
import store from "@/store/index";

import "@/assets/css/main.postcss";
import "@/components";
import "@/plugins/index";

import { useI18Next } from "./i18n";

Vue.config.productionTip = false;

useI18Next(
  new Vue({
    router,
    store,
    render: (h) => h(App),
  })
).$mount("#app");
