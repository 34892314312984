import { Model } from "@vuex-orm/core";

export const therapeuticGoalMaxLength = 1000;

export default class TherapeuticGoal extends Model {
  static entity = "therapeuticGoal";
  static primaryKey = "patientUsername";

  static fields() {
    return {
      therapistUsername: this.attr(null),
      patientUsername: this.attr(null),
      goal: this.attr(null),
      addedAtUtc: this.attr(null),
    };
  }

  /**
   * Fetch therapeutic goal from companion for a given patient.
   * @param {string} username The username
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async fetchTherapeuticGoalForPatient(username, signal) {
    return await this.api()
      .get(`therapeutic-goal/patient/${username}`, {
        dataTransformer: (response) => {
          return response?.data?.result || {};
        },
        signal,
      })
      .then(() => {
        return TherapeuticGoal.query().find(username) ?? null;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  /**
   * Returns the therapeutic goal for the given patient from the store or fetch it from companion.
   * @param {string} username The username
   * @param {AbortSignal} signal Permits request cancellation support
   */
  static async getTherapeuticGoalForPatient(username, signal) {
    const tg = TherapeuticGoal.query().find(username);
    if (tg) {
      return tg;
    } else {
      return await this.fetchTherapeuticGoalForPatient(username, signal);
    }
  }

  /**
   * Post a new therapeutic goal for a patient.
   * @param {TherapeuticGoal} newTherapeuticGoal The therapeutic goal object to add
   */
  static async postTherapeuticGoalForPatient(newTherapeuticGoal) {
    return this.api()
      .post("therapeutic-goal", newTherapeuticGoal, {
        dataKey: "result",
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
