import Vue from "vue";

import VTooltip from "v-tooltip";

/**
 * To make the tooltip visible outside of a container using "overflow:auto",
 * we need to set the following option
 * See https://github.com/Akryum/v-tooltip/issues/192#issuecomment-514328339
 */
Vue.use(VTooltip, {
  defaultBoundariesElement: "window",
});
